<template>
	<div class="c-modal-terms">
		<vue-final-modal
			:name="options.name"
			:value="options.active"
			:ssr="true"
			:lock-scroll="true"
			:hide-overlay="false"
			:click-to-close="true"
			:esc-to-close="false"
			:prevent-click="false"
			:attach="false"
			transition="vfm"
			overlay-transition="vfm"
			:z-index-auto="true"
			:z-index-base="1000"
			:z-index="false"
			:focus-retain="true"
			:focus-trap="false"
			:fit-parent="true"
			:drag="false"
			drag-selector=""
			:keep-changed-style="false"
			:resize="false"
			:resize-directions="['t', 'tr', 'r', 'br', 'b', 'bl', 'l', 'tl']"
			:min-width="0"
			:min-height="0"
			:max-width="Infinity"
			:max-height="Infinity"
			@closed="onClose"
		>
			<div>
				<h1 class="h2">Welcome to the e-COPE Directory.</h1>

				<p>
					To begin your search, simply indicate your <strong>Postcode</strong>,
					current <strong>Perinatal Status</strong> and the main topic you are
					<strong>Seeking Help for</strong>.
				</p>

				<p>
					You can then click on the Show Results button to reveal broad results,
					OR you can further Refine Search to include other specific
					requirements (eg. type of service, cost structure, language
					requirements).
				</p>

				<p>
					You can change your preferences at any time to clear, modify or refine
					your search.
				</p>
				<a class="btn btn-lg" href="#" @click="onClick">
					Begin Search
				</a>
			</div>
		</vue-final-modal>
	</div>
</template>

<script>
import { $vfm, VueFinalModal, ModalsContainer } from "vue-final-modal";

export default {
	components: {
		VueFinalModal,
		ModalsContainer,
	},

	data: function() {
		return {
			options: {
				name: "modal-terms",
				active: false,
			},
		};
	},

	watch: {
		"options.active": {
			handler: function() {
				if (!this.options.active) {
					this.onClose();
				}
			},
		},
	},

	methods: {
		onClick: function() {
			this.options.active = false;
		},
		onClose: function() {
			this.$emit("close");
		},
	},
};
</script>

<style lang="scss">
.c-modal-terms {
	.vfm__overlay {
		background-color: transparent;
		backdrop-filter: blur(10px);
	}
	.vfm__container {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: var(--spacer);
	}
	.vfm__content {
		max-width: 600px;
		padding: var(--spacer);
		background-color: var(--g1);
		border-radius: var(--border-radius);
		text-align: center;
	}

	@media screen and (max-width: 428px) {
		.vfm__container {
			padding: var(--spacer-sm);
		}
		.vfm__content {
			padding: var(--spacer-sm);
		}
	}
}
</style>
