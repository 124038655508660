<template>
  <div :class="['c-map-popup__wrapper', { active: currentMarker }]" :style="markerWrapperStyle">
    <transition name="pop" tag="div">
      <div class="c-map-popup" v-if="currentMarker">
        <div class="c-map-popup__header">
          <h3>{{ currentMarker.full_name }}</h3>
          <x-icon size="1.5x" @click="onClosePopup"></x-icon>
        </div>
        <div class="c-map-popup__scroll">
          <div class="c-map-popup__body">
            <article>
              <img :src="pinImage" />
              <div>
                <h3>{{ currentMarker.site_name }}</h3>
                <address>
                  {{ currentMarker.address_full }}
                </address>
                <button class="btn btn-primary" @click="saveLocation">
                  Save
                </button>
                <a :href="googleMapUrl" target="_blank" v-if="googleMapUrl"
                  >View on Google Maps <external-link-icon size="1x"></external-link-icon
                ></a>
              </div>
            </article>
          </div>
          <div class="c-map-popup__footer">
            <div class="c-map-popup__footer__inner">
              <CopyWebsite
                v-if="currentMarker.website"
                @click="onInteraction"
                :website="currentMarker.website"
              ></CopyWebsite>
              <CopyWebsite
                title="Online Network:"
                v-if="currentMarker.contact_network"
                @click="onInteraction"
                :website="currentMarker.contact_network"
              ></CopyWebsite>
              <div>
                <CopyWebsite
                  v-if="currentMarker.website"
                  @click="onInteraction"
                  :website="currentMarker.website"
                ></CopyWebsite>
              </div>
              <div v-if="deliveriesString">
                <strong>Modes of delivery:</strong>
                <p>
                  {{ deliveriesString }}
                </p>
              </div>

              <div v-if="debug">
                Marker ID: {{ currentMarker.id }} RADIUS: {{ user.inRadius }} | PARAM:
                {{ user.inParam }} | TELEHEALTH: {{ user.telehealth }} | SHOW: {{ user.show }}
              </div>

              <div v-if="costsString">
                <CopyGeneric
                  :forceInline="true"
                  title="Cost Structure:"
                  :value="costsString"
                ></CopyGeneric>
              </div>
              <div v-if="currentMarker.other_details">
                <CopyGeneric
                  :forceInline="true"
                  title="Additional details:"
                  :value="currentMarker.other_details"
                ></CopyGeneric>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CopyGeneric from '@/components/global/copy-generic';
import CopyEmail from '@/components/global/copy-email';
import CopyPhone from '@/components/global/copy-phone';
import CopyWebsite from '@/components/global/copy-website';

import { XIcon, ExternalLinkIcon } from 'vue-feather-icons';

export default {
  components: {
    CopyGeneric,
    CopyEmail,
    CopyPhone,
    CopyWebsite,

    XIcon,
    ExternalLinkIcon
  },

  data: function() {
    return {
      offset: {
        x: 6, // dunno why but i have to manually offset x by a bit so the popup arrow is precisely underneath the marker
        y: 12
      },
      cachePosition: {
        x: 0,
        y: 0
      },
      cacheRenderedMarker: null
    };
  },

  props: {
    map: {
      type: Object,
      default: function() {
        return null;
      }
    }
  },

  watch: {
    currentMarker: function() {
      if (this.currentMarker) {
        let data = {
          user: this.$store.getters['users/user'](this.currentMarker.user),
          event: 'map-pin'
        };
        // this.$store.dispatch('analytics/onUserInteraction', data);
        this.cacheRenderedMarker = this.currentMarker;
      } else {
        this.cacheRenderedMarker = null;
      }
    }
  },

  computed: {
    debug: function() {
      return this.$store.getters['debug'];
    },
    user: function() {
      if (!this.currentMarker) {
        return null;
      }

      let users = this.$store.getters['users/filteredUsers'];
      let user = null;
      for (let i = 0; i < users.length; i++) {
        if (users[i].id === this.currentMarker.user) {
          user = users[i];
        }
      }

      return user;
      //return this.$store.getters['users/user'](this.currentMarker.user);
    },
    deliveriesString: function() {
      let marker = this.currentMarker;

      if (!marker) {
        return null;
      }

      let deliveries = marker.deliveries.concat(marker.deliveries_other);

      return deliveries.join(', ');
    },
    costsString: function() {
      let marker = this.currentMarker;

      if (!marker) {
        return null;
      }

      return marker.costs_other.join(', ');
    },
    google: function() {
      return this.$store.getters['map/google'];
    },
    googleMapUrl: function() {
      let marker = this.currentMarker;
      if (!marker) {
        return null;
      }
      let str = `https://maps.google.com/?q=${marker.position.lat},${marker.position.lng}`;
      return str;
    },
    pinImage: function() {
      let marker = this.currentMarker;
      let url =
        'https://www.cope.org.au/wp-content/themes/cope/img/ecope-directory-marker-ip-v2.svg';

      if (marker.type === 'ppos') {
        url =
          'https://www.cope.org.au/wp-content/themes/cope/img/ecope-directory-marker-ppos-v2.svg';
      }

      if (marker.type === 'cso') {
        url =
          'https://www.cope.org.au/wp-content/themes/cope/img/ecope-directory-marker-cso-v2.svg';
      }

      return url;
    },
    markerWrapperStyle: function() {
      let style = `transform:translate(${this.cachePosition.x}px,${this.cachePosition.y}px);`;

      let cacheRenderedMarker = this.cacheRenderedMarker;

      if (!cacheRenderedMarker) {
        return style;
      }

      //let anchorPoint = cacheRenderedMarker.mapMarker.anchorPoint;
      let gMapMarker = this.gMapMarker;
      let position = this.getPositionOfMarker(this.map, cacheRenderedMarker);
      console.log(position);
      let x = position.x + this.offset.x;
      let y = position.y + this.offset.y;

      const mapViewEl = document.querySelector('.c-map-view');

      const boundingBox = mapViewEl.getBoundingClientRect();

      console.log(boundingBox);

      this.cachePosition.x = x;
      this.cachePosition.y = y;

      x = x + boundingBox.left;
      y = y + boundingBox.top;

      style = `transform:translate(${x}px,${y}px);`;

      return style;
    },
    currentMarker: function() {
      return this.$store.getters['markers/currentMarker'];
    },
    gMapMarkers: function() {
      return this.$store.getters['markers/gMapMarkers'];
    },
    currentGMapMarker: function() {
      let gMapMarkers = this.gMapMarkers;
      let gMapMarker = null;
      let id = null;
      let currentMarker = this.currentMarker;

      if (!currentMarker) {
        return null;
      }

      for (let i = 0; i < gMapMarkers.length; i++) {
        id = gMapMarkers[i].get('id');

        if (id === currentMarker.id) {
          gMapMarker = gMapMarkers[i];
          break;
        }
      }

      return gMapMarker;
    },
    google: function() {
      return this.$store.getters['map/google'];
    }
  },

  methods: {
    saveLocation: function() {
      let marker = this.currentMarker;
      this.$store.dispatch('setSaveProfileId', `marker:${marker.full_name}`);
    },
    getPositionOfMarker: function(map, marker) {
      let position = new this.google.maps.LatLng(marker.position);
      let google = this.google;
      let scale = Math.pow(2, map.getZoom());
      let nw = new google.maps.LatLng(
        map
          .getBounds()
          .getNorthEast()
          .lat(),
        map
          .getBounds()
          .getSouthWest()
          .lng()
      );
      let worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
      let worldCoordinate = map.getProjection().fromLatLngToPoint(position);
      let pixelOffset = new google.maps.Point(
        Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
        Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
      );

      return pixelOffset;
    },
    onClosePopup: function() {
      this.$store.dispatch('markers/setMarker', null);
    },
    onInteraction: function(eventName) {
      // this.$store.dispatch('analytics/onUserInteraction', {
      //   user: this.user,
      //   event: eventName
      // });
    }
  }
};
</script>

<style lang="scss">
.c-map-popup {
  width: 100%;
  padding: 12px 24px;
  background-color: var(--g5);

  @include modalStyles();
  box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);

  &__wrapper {
    position: absolute;
    bottom: 100%;
    left: -220px;
    width: 440px;
    transition: transform 60ms linear;
    pointer-events: none;
    border-radius: var(--border-radius);

    &.active {
      pointer-events: all;
    }

    .pop-enter-active,
    .pop-leave-active {
      transition: opacity 250ms linear, transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    .pop-enter, .pop-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
      transform: translateY(4px);
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -18px;
    left: calc(50% - 6px);
    height: 24px;
    width: 12px;
    background-color: var(--g2);
    transform: rotate(-90deg);
    mask-image: url('/arrow.svg');
  }

  &__header {
    position: relative;
    border-bottom: var(--g3) solid 1px;
    padding: var(--margin-lg) 0;
    padding-right: var(--spacer-sm);

    svg {
      position: absolute;
      top: calc(50% - 13px);
      right: 0;
      cursor: pointer;
    }
  }

  &__scroll {
    max-height: 350px;
    overflow: auto;
  }

  p {
    max-width: 100%;

    a {
      word-break: break-all;
    }
  }

  &__body {
    border-bottom: var(--g3) solid 1px;
    padding: 18px 0;

    .btn {
      margin-top: var(--margin-lg);
    }

    article {
      display: flex;
      align-items: center;
      font-size: var(--font-size-sm);
    }

    h3 {
      color: var(--font-color);
    }

    img {
      align-self: flex-start;
      width: 18px;
      height: auto;
      margin-right: var(--spacer-sm);
    }

    address {
      margin-bottom: var(--margin);
      font-size: var(--font-size);
    }

    .btn {
      margin-right: var(--margin-lg);
    }
  }

  @media screen and (max-width: 428px) {
    height: 220px;
    overflow-y: auto;

    &__wrapper {
      left: -150px;
      width: 300px;
    }

    &__body {
      .btn {
        margin-right: 0;
      }

      a {
        display: block;
        width: 100%;
        margin-top: var(--margin-lg);
      }
    }
  }
}
</style>
