<template>
  <div class="c-checkbox-group">
    <div class="c-checkbox-group__list" v-for="item in items" :key="item.value">
      <!-- Sub list -->
      <div v-if="item.items" class="c-checkbox__sublist">
        <div class="form-checkbox">
          <input
            type="checkbox"
            name="deliveries"
            @change="onIntermediateCheckboxChange($event, item)"
            :ref="slugify(item.value)"
            :id="slugify(item.value)"
          />
          <label :for="slugify(item.value)">
            {{ item.key }} <span class="small" v-if="debug">{{ item.value }}</span>
          </label>
        </div>
        <!-- <Checkbox :item="item"></Checkbox> -->
        <div class="c-checkbox-group__list c-checkbox-group__list--sublist">
          <div class="form-checkbox" v-for="subItem in item.items" :key="subItem.value">
            <input
              type="checkbox"
              name="deliveries"
              v-model="currentValue"
              :id="slugify(subItem.value)"
              @change="onCheckboxChange($event, item)"
            />
            <label :for="slugify(subItem.value)">
              {{ subItem.key }} <span class="small" v-if="debug">{{ subItem.value }}</span>
            </label>
          </div>
        </div>
      </div>
      <!-- Sub list END -->

      <!-- Root list -->
      <div class="form-checkbox" v-else>
        <input
          type="checkbox"
          name="deliveries"
          v-model="currentValue"
          :value="item.value"
          :id="slugify(item.value)"
        />
        <label :for="slugify(item.value)">
          {{ item.key }} <span class="small" v-if="debug">{{ item.value }}</span>
        </label>
      </div>
      <!-- Root list END -->
    </div>
  </div>
</template>

<script>
import slugify from '@/lib/slugify.js';
import Checkbox from '@/components/global/checkbox';

export default {
  components: {
    Checkbox,
  },

  data: function () {
    return {
      content: [],
      currentValue: null,
    };
  },

  /**
   * We use currentValue cuz we don't want to mutate the value prop. Instead, we listen to currentValue and pass the update back inside onChange
   */
  watch: {
    value: {
      handler: function () {
        this.currentValue = this.value;
      },
      immediate: true,
    },
    currentValue: function (item) {
      this.onChange();
    },
  },

  props: {
    value: {
      type: Array,
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  computed: {
    debug: function () {
      return this.$store.getters['debug'];
    },
  },

  methods: {
    onIntermediateCheckboxChange: function (e, item) {
      let items = item.items;
      if (e.target.checked) {
        let values = [];
        let temp = [];
        for (let i = 0; i < items.length; i++) {
          values.push(items[i].value);
        }

        for (let i = 0; i < values.length; i++) {
          if (!this.currentValue.includes(values[i])) {
            temp.push(values[i]);
          }
        }

        this.currentValue = this.currentValue.concat(temp);
      } else {
        this.currentValue = [];
      }
    },
    onCheckboxChange: function (e, parentItem) {
      let values = [];

      let temp = [];

      if (e.target.checked) {
      }
    },
    slugify: function (str) {
      return slugify(str);
    },
    onChange: function () {
      this.$emit('input', this.currentValue);
    },
  },
};
</script>

<style lang="scss">
.c-checkbox-group {
  .small {
    font-size: 10px;
  }

  &__list {
    margin-bottom: var(--margin-lg);

    &--sublist {
      padding-left: var(--spacer-sm);
      border-left: var(--g4) solid 4px;
    }
  }

  @media screen and (max-width: 428px) {
    &__list {
      margin-bottom: var(--margin);
    }
  }
}
</style>
