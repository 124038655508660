<template>
  <div class="c-site-footer">
    <div class="c-site-footer__body">
      <div class="container">
        <h3 class="center">Our Company Members</h3>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="c-site-footer__logos">
              <img src="images/logos/01-ranzcp-logo.png" style="width:18.68%" />
              <img src="images/logos/02-mcfhna-logo.png" style="width:20.16%" />
              <img src="images/logos/03-racgp-logo.png" style="width:25.56%" />
              <img src="images/logos/04-acm-logo.png" style="width:7.54%" />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="c-site-footer__logos">
              <img src="images/logos/05-ranzcog-logo.png" style="width:18.68%" />
              <img src="images/logos/06-aps-logo.png" style="width:20.16%" />
              <img src="images/logos/07-acmhn-logo.png" style="width:25.56%" />
              <img src="images/logos/08-panda-logo.png" style="width:7.54%" />
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="c-site-footer__body__divider"></div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-3">
            <h3>About this site</h3>
            <p>
              All information on this site is based on the latest research and National Clinical
              Practice Guidelines.
            </p>
            <p>
              Many of the photographs on this site were generously provided by Katrina Christ
              Photographer.
            </p>
          </div>

          <div class="col-12 col-md-6 col-lg-3">
            <h3>Registered Charity</h3>
            <img class="logo-charity" src="/images/charity-logo.png" />
          </div>

          <div class="col-12 col-md-6 col-lg-3">
            <h3>Contact Us</h3>
            <ul class="list">
              <li class="list__item">
                <address>
                  PO Box 122<br />
                  Flemington Victoria 3031<br />
                  Australia
                </address>
              </li>
              <li class="list__item">
                <span class="prefix">
                  <abbr title="Telephone">T</abbr>
                </span>
                <span>1300 740 398</span>
              </li>
              <li class="list__item">
                <span class="prefix">
                  <abbr title="Email">E</abbr>
                </span>
                <a href="mailto: info@cope.org.au"> info@cope.org.au</a>
              </li>
              <li class="list__item media-enquiries">
                Media Enquiries:<br />
                Dr Nicole Highet, 0438 810 235
              </li>
            </ul>
          </div>

          <div class="col-12 col-md-6 col-lg-3">
            <h3>Connect with us</h3>
            <ul class="social list list--inline">
              <li class="list__item">
                <a
                  href="https://www.facebook.com/COPEorg"
                  class="button button--icon icon-facebook icon-white"
                >
                  <span class="button__text">Like us on Facebook</span>
                </a>
              </li>
              <li class="list__item">
                <a
                  href="https://www.twitter.com/COPEorg"
                  class="button button--icon icon-twitter icon-white"
                >
                  <span class="button__text">Follow us on Twitter</span>
                </a>
              </li>
              <li class="list__item">
                <a
                  href="https://www.youtube.com/channel/UC8Zuq2JgsbHKiUBo5n3vDnw"
                  class="button button--icon icon-youtube icon-white"
                >
                  <span class="button__text">Subscribe to us on YouTube</span>
                </a>
              </li>
              <li class="list__item">
                <a
                  href="https://www.instagram.com/cope.org.au"
                  class="button button--icon icon-instagram icon-white"
                >
                  <span class="button__text">Follow us on Instagram</span>
                </a>
              </li>
              <li class="list__item">
                <a
                  href="https://www.linkedin.com/company/20297565/"
                  class="button button--icon icon-linkedin icon-white"
                >
                  <span class="button__text">Add us on LinkedIn</span>
                </a>
              </li>
            </ul>

            <form class="form site-footer__search" action="https://www.cope.org.au">
              <div class="form__control form__control--overlaid">
                <input
                  class="form__input"
                  id="site-search--footer"
                  name="s"
                  placeholder="Search keywords"
                />
                <button class="btn icon-primary">
                  <span class="button__text">Search</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="c-site-footer__footer">
      <a target="_blank" href="https://www.cope.org.au/e-cope-directory/glossary-of-terms/">Directory Glossary of Terms</a>
      <span class="divider"></span>
      <a target="_blank" href="https://www.cope.org.au/terms-conditions/e-cope-directory/disclaimer">Disclaimer</a>
      <span class="divider"></span>
      <a target="_blank" href="https://www.cope.org.au/terms-conditions/">Terms and conditions</a>
      <span class="divider"></span>
      <a target="_blank" href="https://www.cope.org.au/about/governance/privacy-policy/"
        >Privacy Policy</a
      >
      <span class="divider"></span>
      <span>Copyright © 2021</span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.c-site-footer {
  font-size: 13px;


  &__logos {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacer-sm);

    img {
      max-width: 100%;
      filter: grayscale(1);
      object-fit: contain;
    }
  }

  .logo-charity {
    height: auto;
    max-width: 72px;
  }

  &__body {
    background-color: var(--g2);
    padding-top: var(--spacer-sm);
    padding-bottom: var(--spacer);

    .center {
      text-align: center;
    }

    h3 {
      margin-top: var(--spacer-sm);
      margin-bottom: var(--spacer-sm);
      font-size: var(--font-size);
    }

    &__divider {
      width: 100%;
      height: 1px;
      margin-top: var(--margin-lg);
      margin-bottom: var(--spacer-sm);
      background-color: var(--g5);
    }

    .list {
      list-style: none;
      margin: 0;
      padding: 0;

      &__item {
        margin-top: 3px;
        &:first-child,
        &:last-child {
          margin-top: 12px;
        }
      }

      &--inline {
        margin-top: 0;
        display: flex;
        flex-wrap: nowrap;

        .list__item {
          margin-top: 0;
          margin-right: 3px;
        }

        a {
          display: block;
          height: var(--spacer);
          width: var(--spacer);
          opacity: 0.5;
          appearance: none;
          border-radius: 50%;
          //background-image: url(../img/icons/white/icon-facebook.svg);
          background-size: auto 62.5%;
          background-color: var(--p4);
          background-position: 50%;
          background-repeat: no-repeat;

          > span {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1 px;
            margin: -1 px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1 px;
          }

          &.icon-facebook {
            background-image: url('/images/icons/icon-facebook.svg');
          }
          &.icon-twitter {
            background-image: url('/images/icons/icon-twitter.svg');
          }
          &.icon-youtube {
            background-image: url('/images/icons/icon-youtube.svg');
          }
          &.icon-instagram {
            background-image: url('/images/icons/icon-instagram.svg');
          }
          &.icon-linkedin {
            background-image: url('/images/icons/icon-linkedin.svg');
          }

          &:hover,
          &:active,
          &:focus {
            opacity: 1;
          }
        }
      }
    }

    .form__control {
      margin-top: 18px;
      -ms-flex-align: center;
      align-items: center;
      background-color: #fff;
      border-radius: 24px;
      font-size: var(--font-size-sm);
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      position: relative;
    }
    .form__input {
      padding: 6px 18px;
      border: 0;
      width: calc(100% - var(--spacer));
      height: 100%;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .icon-primary {
      position: absolute;
      display: inline-block;
      right: 0;
      width: var(--spacer);
      height: var(--spacer);
      background-color: transparent;
      font-size: 1px;
      color: transparent;
      appearance: none;
      background-image: url('/images/icons/icon-search.svg');
      background-size: 50%;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
  &__footer {
    padding: var(--spacer-sm) 0;
    background-color: var(--p4);
    color: var(--g3);
    font-size: var(--font-size-sm);
    text-align: center;

    a {
      color: var(--g3);
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        color: var(--g1);
        text-decoration: underline;
      }
    }

    .divider {
      margin: 0 var(--margin-lg);
      width: 1px;
      height: var(--line-height);
      background-color: var(--g3);
      display: inline-block;
    }
  }
}
</style>
