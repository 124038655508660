<template>
  <div class="c-copy-email">
    <strong>
      {{ title }}
    </strong>
    <p>
      <a :href="`mailto:${email}`" @click="$emit('click', 'email')">{{ email }}</a>
    </p>
  </div>
</template>

<script>
import { CopyIcon } from 'vue-feather-icons';

export default {
  components: {
    CopyIcon
  },

  props: {
    email: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Email:'
    }
  },

  methods: {
    onClick: function() {}
  }
};
</script>

<style lang="scss">
.c-copy-email {
  padding: var(--margin-sm) 0;

  .svg {
    stroke-width: 3px;
  }

  p {
    margin: 0;
    display: inline-block;
  }

  a:last-child {
    display: inline-block;
    margin-left: var(--margin);
  }
}
</style>
