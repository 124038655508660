<template>
  <div id="app" v-if="init" data-theme="default">
    <router-view />
    <ModalIntro @close="onIntroModalClose"></ModalIntro>
    <ModalSaveSearch></ModalSaveSearch>
    <portal-target name="root"> </portal-target>
  </div>
</template>

<script>
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueScrollTo from 'vue-scrollto';
import router from './router';
import store from './store';

import ModalIntro from '@/components/map/modal-intro';
import ModalSaveSearch from '@/components/map/modal-save-search';
import VTooltip from 'v-tooltip';
import PortalVue from 'portal-vue';

VTooltip.enabled = window.innerWidth > 768;

Vue.use(PortalVue);
Vue.use(VTooltip);
Vue.use(VueScrollTo);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCjcZnhmHl_FAQe3XcAYR7VmHgl0FM_gqo',
    libraries: 'geometry' // This is required if you use the Autocomplete plugin
  }
});

export default {
  router,
  store,

  components: {
    ModalIntro,
    ModalSaveSearch
  },

  data: function() {
    return {};
  },

  watch: {
    filterTrigger: function() {
      if (this.loaded) {
        this.$router.replace({ name: 'Home', query: this.filter }).catch(err => {});
      }
    },
    init: function() {
      if (this.init) {
        this.setupEventListeners();
      }
    },
    async loaded() {
      if (this.loaded) {
        /**
         * Check for query params here
         * And show the modal based on the query params
         */
        if (this.$route.query.postcode && this.$route.query.radius) {
          this.$store.dispatch('map/setFilter', this.$route.query);

          await this.$store.dispatch('map/filter');

          /**
           * Flicks ready flag to true
           */
          this.$store.dispatch('hasReady');
          return;
        }

        /**
         * If no filter postcode or filter radius are set via query parameters
         * then show the intro modal - but only if debug mode is off
         *
         * We also gotta run filter one time before we can do this
         */
        if (!this.debug) {
          this.$store.dispatch('toggleIntro', true);
        } else {
          await this.$store.dispatch('map/filter');
          this.$store.dispatch('hasReady');
        }
      }
    }
  },

  computed: {
    init: function() {
      return this.$store.getters['init'];
    },
    ready: function() {
      return this.$store.getters['ready'];
    },
    loaded: function() {
      return this.$store.getters['loaded'];
    },
    debug: function() {
      return this.$store.getters['debug'];
    },
    filter: function() {
      return this.$store.getters['map/filter'];
    },
    filterTrigger: function() {
      return this.$store.getters['map/filterTrigger'];
    }
  },

  methods: {
    onIntroModalClose: function() {
      this.$store.dispatch('map/filter');
    },
    onResize: function() {
      this.$store.dispatch('setWindowWidth', window.innerWidth);
    },
    setupEventListeners: function() {
      window.addEventListener('resize', this.onResize);
    },
    destroyEventListeners: function() {
      window.removeEventListener('resize', this.onResize);
    }
  },

  created: function() {
    this.$store.dispatch('init');
  },

  beforeDestroy: function() {
    this.destroyEventListeners();
  }
};
</script>

<style lang="scss">
@import 'assets/styles/main.scss';
</style>
