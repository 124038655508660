<template>
  <div :class="['c-filter-panel', { active: showFilterForm === true }]">
    <PopupHeader @close="onClose"></PopupHeader>
    <div class="c-filter-panel__body">
      <FormFilter @submit="onSubmit" @back="onClose"></FormFilter>
    </div>
  </div>
</template>

<script>
import { XIcon } from 'vue-feather-icons';

import FormFilter from './forms/form-filter';

import PopupHeader from '@/components/global/popup-header';

import hotkeys from 'hotkeys-js';

export default {
  components: {
    XIcon,
    FormFilter,
    PopupHeader
  },

  data: function() {
    return {};
  },

  computed: {
    showFilterForm: function() {
      return this.$store.getters['map/showFilterForm'];
    }
  },

  methods: {
    onClose: function() {
      this.$store.dispatch('map/hideFilterForm');
    },
    onSubmit: function() {
      this.$store.dispatch('map/hideFilterForm');
    }
  },

  mounted : function() {
    hotkeys('esc', (e, handler) => {
      // Prevent the default refresh event under WINDOWS system
      e.preventDefault();

      this.onClose();
    });

  }
};
</script>

<style lang="scss">
.c-filter-panel {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--g1);

  display: flex;
  flex-direction: column;

  opacity: 0;
  transform: translateX(-100%);
  transition: transform var(--transition-time-lg) cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity var(--transition-time) linear;

  &.active {
    opacity: 1;
    transform: translateX(0);
  }

  &__header {
    position: relative;
    padding: var(--margin-lg) var(--spacer);
    background-color: var(--p1);

    h3 {
      margin: 0;
    }

    svg {
      position: absolute;
      top: var(--margin-lg);
      right: var(--spacer);
      cursor: pointer;
    }
  }

  &__body {
    flex-grow: 1;

    overflow-y: auto;
  }
}
</style>
