import api from '../lib/api';

import normalizeMarkers from '../lib/normalize-markers.js';

import { MarkerClusterer } from '@googlemaps/markerclusterer';

let store = {
  namespaced: true,
  state: {
    markers: [], // basic raw markers
    gMapMarkers: [], // Google map markers
    filteredMarkers: [], // filtered raw markers based on map's filter. Is an array of ids
    currentMarker: null,
    markerClusterer: null
  },

  getters: {
    markers: function(state) {
      return state.markers;
    },
    marker: function(state) {
      let markers = state.markers;
      return id => {
        let marker = null;
        for (let i = 0; i < markers.length; i++) {
          if (markers[i].id === id) {
            marker = markers[i];
            break;
          }
        }
        return marker;
      };
    },
    markerByFullName: function(state) {
      let markers = state.markers;
      return id => {
        let marker = null;
        for (let i = 0; i < markers.length; i++) {
          if (markers[i].full_name === id) {
            marker = markers[i];
            break;
          }
        }
        return marker;
      };
    },
    gMapMarkers: function(state) {
      return state.gMapMarkers;
    },
    filteredMarkers: function(state) {
      return state.filteredMarkers;
    },
    markersByUserId: function(state) {
      return function(userId) {
        let markers = state.markers;
        let newMarkers = [];
        for (let i = 0; i < markers.length; i++) {
          if (markers[i].user === userId) {
            newMarkers.push(markers[i]);
          }
        }
        return newMarkers;
      };
    },
    currentMarker: function(state) {
      if (!state.currentMarker) {
        return null;
      }

      let marker = null;
      for (let i = 0; i < state.markers.length; i++) {
        if (state.markers[i].id === state.currentMarker) {
          marker = state.markers[i];
          break;
        }
      }

      return marker;
    },

    mapMarkers: function(state) {
      return state.mapMarkers;
    }
  },

  mutations: {
    SET: function(state, markers) {
      state.markers = markers;
    },
    SET_MARKER: function(state, markerId) {
      state.currentMarker = markerId;
    },
    SET_MAP_MARKERS: function(state, mapMarkers) {
      state.mapMarkers = mapMarkers;
    },
    SET_FILTERED_MARKERS: function(state, filteredMarkers) {
      state.filteredMarkers = filteredMarkers;
    },
    SET_GOOGLE_MAP_MARKERS: function(state, markers) {
      if (state.gMapMarkers) {
        for (let i = 0; i < state.gMapMarkers.length; i++) {
          state.gMapMarkers[i].setMap(null);
        }
      }
      state.gMapMarkers = [];
      state.gMapMarkers = markers;
    },
    SET_MARKERCLUSTERER: function(state, markerClusterer) {
      state.markerClusterer = markerClusterer;
    }
  },
  actions: {
    async init(store) {
      //await store.dispatch('get');
    },

    async get(store) {
      let markers = await api.markers.get();

      markers = normalizeMarkers(markers);

      store.commit('SET', markers);
    },

    async set(store, markers) {
      store.commit('SET', markers);
    },

    async setMarker(store, markerId) {
      if (markerId) {
        let marker = store.getters['marker'](markerId);
        let userId = marker.user;
        let user = store.rootGetters['users/user'](userId);
        store.dispatch('users/setActiveUser', user, { root: true });
      } else {
        store.dispatch('users/setActiveUser', null, { root: true });
      }

      store.commit('SET_MARKER', markerId);
    },

    setFilteredMarkers: function(store, filteredMarkerIds) {
      let markers = store.state.markers;
      let filteredMarkers = [];
      for (let i = 0; i < markers.length; i++) {
        if (filteredMarkerIds.includes(markers[i].id)) {
          filteredMarkers.push(markers[i]);
        }
      }
      store.commit('SET_FILTERED_MARKERS', filteredMarkers);
    },

    /**
     *
     * This action is supposed to be run once once
     * Run it again only if the initial marker and user payload changes
     */
    async setMapMarkers(store) {
      /**
       * Gathering initial variables
       */
      let markers = store.state.markers;
      let pins = store.rootGetters['map/pins'];
      let google = store.rootGetters['map/google'];
      let map = store.rootGetters['map/map'];
      let markerClusterer = store.state.markerClusterer;

      let mapMarker = null;
      let position = {};
      let realPosition = {};
      let gMapMarkers = [];
      let lat = 0;
      let lng = 0;

      /**
       * This loop:
       * - normalized marker data
       * - generates a google map marker based on our marker data along with pins, click handler and other stuff
       * - attaches that google marker object to our marker object
       */
      for (let i = 0; i < markers.length; i++) {
        // Some markers may not have a lat/lng nor a address. Mark them as such and continue the loop without creating gmapmarkers for it
        if (!markers[i].lat) {
          markers[i].isService = true;
          markers[i].position = null;
          markers[i].realPosition = null;
          continue;
        }
        let icon = pins.pinIcon_ip;
        if (markers[i].type === 'ppos') {
          icon = pins.pinIcon_ppos;
        }
        if (markers[i].type === 'cso') {
          icon = pins.pinIcon_cso;
        }

        lat = parseFloat(markers[i].lat);
        lng = parseFloat(markers[i].lng);

        realPosition = {
          lat: lat,
          lng: lng
        };

        lat = getRandomArbitrary(lat, 0.000025);
        lng = getRandomArbitrary(lng, 0.000025);

        position = {
          lat: lat,
          lng: lng
        };

        mapMarker = {
          icon: icon,
          optimized: false,
          position: position,
          title: markers[i].site_name
        };

        mapMarker = new google.maps.Marker(mapMarker);
        mapMarker.set('id', markers[i].id);
        mapMarker.addListener('click', function() {
          let id = this.get('id');
          store.dispatch('setMarker', id);
        });
        mapMarker.setMap(map);
        mapMarker.setVisible(false);

        markers[i].position = realPosition;

        gMapMarkers.push(mapMarker);
      }

      /**
       * Finally, updates store with the new markers object array
       */
      store.commit('SET', markers);

      store.commit('SET_GOOGLE_MAP_MARKERS', gMapMarkers);

      markers = gMapMarkers;

      /**
       * Also calls setPopulatedusers on users store since we've updated the og markers array
       */
      await store.dispatch('users/populateUsers', null, { root: true });

      /**
       * Manage markerclusterer
       */
      if (markerClusterer) {
        markerClusterer.clearMarkers();
        markerClusterer.addMarkers(markers);
      } else {
        markerClusterer = createCluster(map, markers);
      }
      store.commit('SET_MARKERCLUSTERER', markerClusterer);
    },

    async renderMarkers(store) {
      let gMapMarkers = store.state.gMapMarkers;
      let users = store.rootGetters['users/filteredUsers'];
      let markerClusterer = store.state.markerClusterer;
      let map = store.rootGetters['map/map'];
      let filter = store.rootGetters['map/filter'];

      let type = filter.type;

      if (markerClusterer) {
        markerClusterer.clearMarkers();
      }

      for (let i = 0; i < gMapMarkers.length; i++) {
        gMapMarkers[i].setMap(null);
      }

      let renderedMarkers = [];

      for (let i = 0; i < users.length; i++) {
        if (users[i].show) {
          renderedMarkers = renderedMarkers.concat(users[i].markers);
        } else {
          if (users[i].inParam) {
            for (let j = 0; j < users[i].markers.length; j++) {
              if (users[i].markers[j].telehealth) {
                renderedMarkers = renderedMarkers.concat(users[i].markers);
                break;
              }
            }
          }
        }
      }

      // If marker doesn't have lat/lng nor address, ignore it
      // Also don't show markers who don't fit the correct type if type filter is set
      renderedMarkers = renderedMarkers.filter(marker => {
        if (marker.isService) {
          return false;
        }

        if (type && marker.type !== type) {
          return false;
        }
        return true;
      });

      renderedMarkers = await store.dispatch('showMarkers', renderedMarkers);

      store.commit('SET_GOOGLE_MAP_MARKERS', renderedMarkers);

      /**
       * Also, update markerClusterer
       */
      if (markerClusterer) {
        markerClusterer.addMarkers(renderedMarkers);
      } else {
        markerClusterer = createCluster(map, renderedMarkers);
      }
      store.commit('SET_MARKERCLUSTERER', markerClusterer);
    },

    async showMarkers(store, markers) {
      let pins = store.rootGetters['map/pins'];
      let google = store.rootGetters['map/google'];
      let map = store.rootGetters['map/map'];

      let mapMarkers = [];

      let callback = function() {
        let id = this.get('id');
        store.dispatch('map/showServiceList', null, { root: true });
        store.dispatch('setMarker', id);
      };

      for (let i = 0; i < markers.length; i++) {
        let icon = pins.pinIcon_ip;
        if (markers[i].type === 'ppos') {
          icon = pins.pinIcon_ppos;
        }
        if (markers[i].type === 'cso') {
          icon = pins.pinIcon_cso;
        }
        mapMarkers.push(
          createMapMarker(markers[i].id, markers[i].position, icon, callback, google, map)
        );
      }

      return mapMarkers;
    }
  }
};

function createMapMarker(id, position, icon, callback, google, map) {
  let mapMarker = {
    icon: icon,
    optimized: false,
    position: position
  };

  if (!position) {
    return;
  }

  mapMarker = new google.maps.Marker(mapMarker);
  mapMarker.set('id', id);
  mapMarker.addListener('click', callback);
  mapMarker.setMap(map);
  mapMarker.setVisible(true);

  return mapMarker;
}

function getRandomArbitrary(value, distance) {
  let min = value - distance;
  let max = value + distance;
  return Math.random() * (max - min) + min;
}

function createCluster(map, markers) {
  const getScaledClusterIcon = (count, minCount, maxCount) => {
    const scale = (count - minCount) / (maxCount - minCount);
    const scaledSize = Math.floor(40 + (25 * scale)); // min 40px, max 65px
    const iconNumber = scaledSize <= 56 ? 1 : 2;
    return {
      url: `https://staging-cope-directory.netlify.app/images/cluster/m${iconNumber}.png`,
      scaledSize: new google.maps.Size(scaledSize, scaledSize),
      anchor: new google.maps.Point(scaledSize/2, scaledSize/2),
    };
  }

  return new MarkerClusterer({
    algorithmOptions: {
      maxZoom: 13,
      radius: 90,
    },
    map,
    markers,
    renderer: {
      render: function({ count, position }, stats) {
        return new google.maps.Marker({
          position,
          icon: getScaledClusterIcon(count, stats.clusters.markers.min, stats.clusters.markers.max),
          label: {
            text: String(count),
            color: '#fff',
            fontSize: '11px',
            fontWeight: "bold"
          },
          // adjust zIndex to be above other markers
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count
        });
      }
    }
  });
}

export default store;
