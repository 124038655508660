<template>
  <div :class="['c-status', status]" v-tooltip="tooltipContent"></div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: 'active'
    }
  },

  computed: {
    tooltipContent: function() {
      let status = this.status;
      let str = `This service is active. Check the timings by calling the service or emailing them.`;

      if (status === 'inactive') {
        str = `This service has not indicated their availability recently.`;
      }

      if(status === 'unavailable') {
        str = `This service is unavailable for new bookings and/or may not be taking new appointments.`;
      }

      if(status === 'long-wait-times') {
        str = `This service is experiencing long wait times.`;
      }

      if(status === 'available') {
        str = `This service is available and/or accepting bookings.`;
      }

      return str;
    }
  }
};
</script>

<style lang="scss">
.c-status {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: linear-gradient(hsl(129, 80%, 70%), hsl(129, 80%, 65%));
  box-shadow: 0 0 0 3px var(--g1);

  &.available {
    background: linear-gradient(hsl(129, 80%, 70%), hsl(129, 80%, 65%));
  }

  &.long-wait-times {
    background: linear-gradient(hsl(54, 91%, 55%), hsl(59, 80%, 70%));
  }
  
  &.unavailable {
    background: linear-gradient(hsl(5, 76%, 53%), hsl(5, 82%, 48%));
  }

  &.inactive {
    background: linear-gradient(hsl(129, 5%, 60%), hsl(129, 5%, 55%));
  }
}
</style>
