<template>
  <div :class="['c-filter-view', { expand: !!filterOverlayForm }, { hidden: hidden === true }]">
    <div class="c-filter-view__inner">
      <div class="c-filter-view__toggle" tabindex="0" @click="onToggleClick">
        <span>{{ toggleTitle }}</span> <chevron-down-icon size="1x"></chevron-down-icon>
      </div>
      <div class="c-filter-view__header">
        <a class="btn btn-primary btn-lg" @click="onRefineClick"
          ><IconFilter></IconFilter> Refine Further</a
        >

        <a class="btn btn-outline btn-lg toggle-map-btn" @click="onToggleList">
          <map-pin-icon size="1x" v-if="showList"></map-pin-icon>
          <list-icon size="1.x" v-else></list-icon>
          {{ listToggleTitle }}
        </a>
        <a class="btn btn-lg restart-search-btn" @click="onRestartSearch"
          ><IconRefresh></IconRefresh><span>Restart Search</span></a
        >
        <a
          :class="['btn btn-lg body-toggle-btn', { collapse: collapse === true }]"
          @click="onToggleBody"
          ><Chevron-down-icon size="1.5x"></Chevron-down-icon
        ></a>
      </div>
      <!-- <div ref="body" :class="['c-filter-view__body', { collapse: collapse === true }]">
        <div class="c-filter-view__body__inner">
          <p>
            Showing results within a
            <Link
              v-if="filter && filter.radius"
              icon="Edit2Icon"
              :text="`${filter.radius} km radius`"
              @onClick="onEditClick('FormRadius')"
            ></Link>
            of
            <Link
              v-if="filter && filter.postcode"
              icon="Edit2Icon"
              :text="filter.postcode"
              @onClick="onEditClick('FormPostcode')"
            ></Link>
            for a current (perinatal) status of
            <Link
              v-if="helpForTitle"
              icon="Edit2Icon"
              :text="helpForTitle"
              @onClick="onEditClick('FormProfession')"
            ></Link>
            and providing help for
            <Link
              v-if="expertiseTitle"
              icon="Edit2Icon"
              :text="expertiseTitle"
              @onClick="onEditClick('FormSubProfession')"
            ></Link>
            offering
            <Link
              v-if="deliveriesTitle"
              icon="Edit2Icon"
              :text="deliveriesTitle"
              @onClick="onEditClick('FormDeliveries')"
            ></Link>
            modes of delivery.
          </p>
        </div>
      </div> -->
      <div ref="footer" :class="['c-filter-view__footer', { collapse: showFooter }]">
        <div class="c-filter-view__footer__inner">
          <div class="c-filter-view__division">
            <strong>Service Types</strong>
            <a href="#" class="btn btn-tertiary" @click="setFilter('')"
              ><img
                class="full"
                src="https://staging-cope-directory.netlify.app/images/pins/all.png"
              />All ({{ getCount('') }})</a
            >
          </div>
          <div class="c-filter-view__division">
            <a
              href="#"
              class="btn"
              @click="setFilter('ip')"
              v-tooltip="{
                content:
                  'Diverse range of health practitioners, skilled in Perinatal Mental Health assessment and treatment',
                show: false,
                placement: 'right'
              }"
              ><img src="https://staging-cope-directory.netlify.app/images/pins/ip.svg" />Individual
              Practitioners ({{ getCount('ip') }})</a
            >
            <a
              href="#"
              class="btn"
              @click="setFilter('ppos')"
              v-tooltip="{
                content:
                  'Services offering a range of qualified health professionals, skilled in Perinatal Mental Health assessment and treatment',
                show: false,
                placement: 'right'
              }"
              ><img src="https://staging-cope-directory.netlify.app/images/pins/ppos.svg" />
              Perinatal Mental Health Services ({{ getCount('ppos') }})</a
            >
            <a
              href="#"
              class="btn"
              @click="setFilter('cso')"
              v-tooltip="{
                content:
                  'Services or organisations that provide Perinatal-related support (not mental health treatment)',
                show: false,
                placement: 'right'
              }"
              ><img src="https://staging-cope-directory.netlify.app/images/pins/cso.svg" />Community
              Support Services ({{ getCount('cso') }})</a
            >
          </div>
        </div>
      </div>
      <FilterViewOverlay></FilterViewOverlay>
    </div>
  </div>
</template>

<script>
import smoothReflow from 'vue-smooth-reflow';

import IconFilter from '@/components/global/icon-filter';
import IconRefresh from '@/components/global/icon-refresh';
import { Edit3Icon, RefreshCwIcon, ChevronDownIcon, MapPinIcon, ListIcon } from 'vue-feather-icons';

import FilterViewOverlay from './filter-view-overlay';

import Link from '@/components/global/link';

export default {
  mixins: [smoothReflow],

  components: {
    Edit3Icon,
    RefreshCwIcon,
    ChevronDownIcon,
    MapPinIcon,
    ListIcon,
    IconFilter,
    IconRefresh,
    FilterViewOverlay,
    Link
  },

  data: function() {
    return {
      hidden: false
    };
  },

  watch: {
    currentMarker: function() {
      if (this.currentMarker) {
        this.$store.dispatch('setFilterViewCollapse', true);
      }
    },
    isMobile: {
      handler: function() {
        if (this.isMobile) {
          this.$store.dispatch('setFilterViewCollapse', true);
        }
      },
      immediate: true
    }
  },

  computed: {
    isMobile: function() {
      return this.$store.getters['isMobile'];
    },
    collapse: function() {
      return this.$store.getters['filterViewCollapse'];
    },
    showFooter: function() {
      // if (!this.showList && !this.currentMarker) {
      //   return false;
      // }

      if (!this.collapse) {
        return false;
      }

      return true;
    },
    toggleTitle: function() {
      if (this.hidden) {
        return 'Show search summary';
      } else {
        return 'Hide search summary';
      }
    },
    listToggleTitle: function() {
      if (this.showList) {
        return 'Show Map';
      } else {
        return 'Show List';
      }
    },
    currentMarker: function() {
      return this.$store.getters['markers/currentMarker'];
    },
    filter: function() {
      return this.$store.getters['map/filter'];
    },
    filterOverlayForm: function() {
      return this.$store.getters['filterOverlayForm'];
    },
    usersTypeCount: function() {
      return this.$store.getters['map/searchReport'];
    },

    helpForTitle: function() {
      if (!this.filter.help_for) {
        return null;
      }
      let str = this.filter.help_for;
      str = str.replaceAll('_', ' ');

      str = str.replace(/\b\w/g, l => l.toUpperCase());

      return str;
    },
    expertiseTitle: function() {
      if (!this.filter.expertise) {
        return null;
      }
      return this.filter.expertise.join(', ');
    },
    deliveriesTitle: function() {
      if (!this.filter.deliveries) {
        return 'all';
      }
      if (this.filter.deliveries.length === 0) {
        return 'all';
      }
      return this.filter.deliveries.join(',');
    },
    showList: function() {
      return this.$store.getters['showList'];
    }
  },

  methods: {
    getCount: function(type) {
      let types = this.usersTypeCount;
      if (!types) {
        return {};
      }

      if (!type) {
        let total = 0;
        for (const prop in types) {
          if (types.hasOwnProperty(prop)) {
            total += types[prop];
          }
        }
        return total;
      }

      return types[type] || 0;
    },
    onToggleClick: function() {
      this.hidden = !this.hidden;
    },
    onRefineClick: function() {
      this.$store.dispatch('map/showFilterForm');
    },
    onRestartSearch: function() {
      this.$store.dispatch('toggleIntro', true);
    },
    onToggleBody: function() {
      this.$store.dispatch('setFilterViewCollapse', !this.collapse);
    },
    onToggleList: function() {
      this.$store.dispatch('toggleList');
    },
    setFilter: function(type) {
      let filter = this.filter;

      filter.type = type;

      this.$store.dispatch('map/updateFilter', filter);
    },
    onEditClick: function(formName) {
      this.$store.dispatch('setFilterOverlayForm', formName);
    }
  },

  mounted: function() {
    this.$smoothReflow({
      el: this.$refs.body,
      transition:
        'height 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000), width 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    });
    this.$smoothReflow({
      el: this.$refs.footer,
      transition:
        'height 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000), width 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000)'
    });
  }
};
</script>

<style lang="scss">
.c-filter-view {
  position: relative;

  border-bottom: var(--p1) solid 6px;
  box-shadow: 0px 0.7px 1.6px rgba(0, 0, 0, 0.04), 0px 2.2px 5.4px rgba(0, 0, 0, 0.06),
    0px 10px 24px rgba(0, 0, 0, 0.1);
  transition: all var(--transition-time) ease-out;

  &.expand {
    padding-bottom: 44px;
  }

  &__inner {
    padding: var(--margin-lg) var(--spacer);
  }

  &__toggle {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    top: 100%;
    left: calc(50% - 90px);
    width: 180px;
    padding: var(--margin-sm);
    background-color: var(--p1);
    font-size: var(--font-size-sm);
    color: var(--p4);
    text-align: center;
    cursor: pointer;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    box-shadow: 0px 0.7px 1.6px rgba(0, 0, 0, 0.016), 0px 2.2px 5.4px rgba(0, 0, 0, 0.024),
      0px 10px 24px rgba(0, 0, 0, 0.04);

    svg {
      margin-left: var(--margin);
      transform: rotate(180deg);
      transition: transform var(--transition-time) ease-out;
      display: inline-block;
    }

    &:focus {
      outline: none;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: var(--margin-lg) 0;

    .btn {
      display: inline-flex;
      align-items: center;

      svg {
        width: 12px;
        height: 12px;
        margin-right: var(--margin);
      }
    }

    .body-toggle-btn {
      display: none;

      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      padding: 0;
      background-color: transparent;
      border-radius: 50%;
      opacity: 1;
      transition: transform var(--transition-time) cubic-bezier(0.215, 0.61, 0.355, 1),
        opacity var(--transition-time) linear;
      color: var(--p4);
      transform: rotate(180deg) !important;

      svg {
        width: var(--spacer);
        height: var(--spacer);
        margin-right: 0;
      }

      &.collapse {
        transform: rotate(0deg) !important;
      }
    }

    .toggle-map-btn {
      display: none;
    }
  }

  // &__body {
  //   height: auto;
  //   overflow: hidden;

  //   &__inner {
  //     padding: var(--margin-lg) 0;
  //     line-height: 1.7;
  //   }
  // }

  &__footer {
    height: auto;
    overflow: hidden;
    padding: var(--margin-lg) 0;

    &__inner {
      display: flex;
      border-top: var(--g3) solid 1px;
      padding-top: calc(var(--margin-lg) * 2);
    }
  }

  &__division {
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &:first-child {
      &:after {
        content: 'or';
        position: absolute;
        top: calc(50% - 12px);
        right: 0;
        width: 12px;
        height: 12px;
        text-align: center;
        z-index: 1;
      }
    }

    &:last-child {
      padding-left: var(--spacer-sm);
    }

    .btn {
      display: inline-flex;
      align-self: baseline;
      align-items: center;
      margin-bottom: var(--margin);
      padding: 6px 12px 6px 6px;
      font-size: 13px;
      font-weight: 600;
      font-family: var(--font-family-sans-serif);

      img {
        display: inline-block;
        width: 9px;
        height: auto;
        margin: 0 var(--margin);

        &.full {
          width: 18px;
          //height: 19px;
        }
      }
    }

    .btn-tertiary {
      margin-top: var(--margin-lg);
    }
  }

  &.hidden {
    height: 1px;
    .c-filter-view__header,
    .c-filter-view__body,
    .c-filter-view__footer {
      display: none;
    }

    .c-filter-view__toggle {
      svg {
        transform: rotate(0deg);
      }
    }
  }

  @media screen and (max-width: 428px) {
    border-bottom: none;

    &__inner {
      padding: 10px var(--spacer-sm);
    }

    &__toggle {
      display: none;
    }

    &__header {
      padding-top: 0;
      //padding-bottom: 0;

      .btn {
        height: 34px;

        svg {
          width: 14px;
          height: 14px;
        }
      }

      .restart-search-btn {
        width: 34px;
        padding: 0;
        justify-content: center;
        align-items: center;

        > span {
          display: none;
        }

        > svg {
          margin-right: 0;
        }
      }
      .body-toggle-btn {
        width: 34px;
        display: inline-flex;

        box-shadow: none !important;

        > svg {
          width: var(--spacer);
          height: var(--spacer);
        }
      }
      .toggle-map-btn {
        display: inline-flex;
      }
    }

    &__body {
      &.collapse {
        height: 0;
      }
    }

    &__footer {
      &.collapse {
        height: 0;
      }
    }

    &__division {
      &:first-child {
        margin-top: var(--margin);

        &:after {
          top: calc(50% - 14px);
          right: -7px;
        }
      }

      .btn {
        font-size: 11px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
