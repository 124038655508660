<template>
  <div :class="['c-map-footer', { active: ready === true }]">
    <div class="c-map-footer__sidebar">
      <a
        v-if="!showFilterForm"
        class="btn btn-secondary btn-lg"
        @click="onShowSearch"
      >
        Save Your Search Results
      </a>
    </div>
    <!-- <div class="c-map-footer__links">
      <a
        href="https://ext.cope-staging.aws.conads.com.au/e-cope-directory/glossary-of-terms/"
        target="_blank"
      >
        Glossary of Terms
      </a>
      <span class="divider">&nbsp;|&nbsp;</span>
      <a
        href="https://ext.cope-staging.aws.conads.com.au/e-cope-directory/disclaimer"
        target="_blank"
      >
        Disclaimer
      </a>
    </div> -->
  </div>
</template>

<script>
export default {
  components: {
  },

  computed: {
    ready: function () {
      return this.$store.getters['ready'];
    },
    showFilterForm: function () {
      return this.$store.getters['map/showFilterForm'];
    },
  },

  methods : {
    onShowSearch : function() {
      this.$store.dispatch('setSaveProfileId', 1);
    }
  }
};
</script>

<style lang="scss">
.c-map-footer {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--footer-height);
  padding: 0 var(--spacer);
  box-shadow: 0 -0.5rem 0.5rem rgb(0 0 0 / 13%);

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: var(--g1);

  transform: translateY(100%);
  opacity: 0;
  pointer-events: none;

  transition: all var(--transition-time) ease-out;

  &__sidebar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--sidebar-width);
  }

  &.active {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }

  &__links {
    margin-left: auto;
  }

  @media screen and (max-width: 428px) {
    padding: 0 var(--spacer-sm);

    &__sidebar {
      position: static;
      width: 100%;
      justify-content: left;
    }

    &__links {
      display: flex;
      flex-direction: column;
      text-align: right;

      .divider {
        display: none;
      }

      a {
        white-space: nowrap;
      }
    }
  }
}
</style>
