import objectId from './objectid.js';

// 'id': "{{ hp_user.id }}",
//                         "type": "{{ hp_user.type }}",
//                         'full_name': "{{ hp_user.full_name }}",
// {% if hp_user.service_summary != '' %}
//                         'service_summary': "{% spaceless %}{{ hp_user.service_summary|nl2br|replace({ '\r\n': '', '\n': '', '\r': '' }) }}{% endspaceless %}",
// {% endif %}
//                         'site_name': "{{ hp_user.location_6_site_name }}",
//                         'address_full': "{{ hp_user.location_6_address_full }}",
//                         'address_lat': "{{ hp_user.location_6_address_lat }}",
//                         'address_lng': "{{ hp_user.location_6_address_lng }}",
//                         "modes_of_delivery": [{% for mode in hp_user.location_6_service_modes_of_delivery %}{% if mode == 'Not Listed...' %}"Other","{{ hp_user.location_6_service_modes_of_delivery_other }}"{% else %}"{{ mode }}"{% endif %}{% if loop.index != loop.length %},{% endif %}{% endfor %}],
//                         "cost_structure": [{% for cost_type in hp_user.location_6_services_applicable_new %}{% if cost_type == 'Not Listed...' %}"{{ hp_user.location_6_services_applicable_other }}"{% else %}"{{ cost_type }}"{% endif %}{% if loop.index != loop.length %},{% endif %}{% endfor %}{% if hp_user.location_6_medicare_registered %}, "Private with Medicare rebate"{% endif %}],
//                         "other_languages": [{% for language in hp_user.location_6_other_languages %}"{{ language }}"{% if loop.index != loop.length %},{% endif %}{% endfor %}],
//                         'other_details': "{% spaceless %}{{ hp_user.location_6_other_service_information|nl2br|replace({ '\r\n': '', '\n': '', '\r': '' }) }}{% endspaceless %}",
//                         'different_contact_phone': "{{ hp_user.location_6_different_contact_details_phone }}",
//                         'different_contact_email': "{{ hp_user.location_6_different_contact_details_email }}",
//                         'different_contact_website': "{{ hp_user.location_6_different_contact_details_website }}",
//                         'different_contact_network': "{{ hp_user.location_6_different_contact_details_network }}",

export default function(data) {
  if (!data.user) {
    console.error('no user id');
    return null;
  }

  if (!data.full_name) {
    console.error('no full_name');
  }

  let lat = parseFloat(data.lat);
  let lng = parseFloat(data.lng);

  let marker = {
    /**
     * Unique id
     */
    id: objectId(),

    /**
     * Int
     * Unique id linking to a user object
     */
    user: data.user,

    /**
     * String
     * Similar to user's type field.
     * This should be preset to its connected user's type field
     *
     */
    type: data.type,

    /**
     * Full name of the service, eg: Center for Clinical Psychology
     */
    full_name: data.full_name,

    /**
     * Boolean
     *
     * Does the marker have interpreter_service available?
     */
    interpreter_service: data.interpreter_service || false,

    /**
     * String
     *
     * Free text description of what services are offered by this marker
     */
    service_summary: data.service_summary || '',

    /**
     * String
     *
     * Name of the physical location, eg: Western Health
     */
    site_name: data.site_name || '',

    /**
     * String
     *
     * human readable address, eg: 176 Furlong Rd, St Albans VIC 3021, Australia
     */
    address_full: data.address_full || '',

    /**
     * Float
     *
     * lat
     */
    lat: lat || null,

    /**
     * Float
     *
     * lng
     */
    lng: lng || null,

    /**
     * Array
     *
     * Array of delivery options, eg: ['Telehealth', 'SMS', 'Other']
     */
    deliveries: data.deliveries || [],

    /**
     * Array,
     * Array of other deliveries that are not meant to be filterable.
     */
    deliveries_other: data.deliveries_other || [],

    /**
     * Array
     * Array of payment types offered by the marker, eg: ['Bulk-bill healthcare cardholder', 'Free Service']
     */
    costs: data.costs || [],

    /**
     * Array
     * Array of other costs that are not meant to be filterable.
     */
    costs_other: data.costs_other || [],

    /**
     * String
     * Description of other services offered by the marker
     */
    costs_other_description: data.costs_other_description || '',

    /**
     * Boolean
     *
     * Indicates that this marker has remote telehealth available
     * If true, this marker will appear in the map even if the filter rejects it
     */
    telehealth: data.telehealth || false,

    /**
     * Boolean
     *
     * TODO: add check for address_full
     */
    onlineOnly: data.onlineOnly || false,

    /**
     * Array
     *
     * Languages, eg: ['Sinhalese', 'Afrikaans']
     * English is assumed to be included even if its not in the list
     */
    languages: data.languages || [],

    /**
     * String
     * Long text about the service this marker is offering
     */
    other_details: data.other_details || '',

    /**
     * String
     * I reckon this is specific to the current marker's location rather than the dr's personal phone number
     *
     * The rest of these contact detail items seem to be specific to this marker rathen than the user
     */
    phone: data.phone || '',

    email: data.email || '',

    website: data.website || '',

    // Contact network will be a website I believe
    contact_network: data.contact_network || ''
  };

  /**
   * If this marker doesnt' have an address nor a lat/lng, turn its telehealth on. This way, it will show up in service list and stays true to the
   * first selection inside locations "None/not applicable(online listing only)" inside cope's user area.
   */
  if(!marker.address_full && !marker.lat) {
    marker.telehealth = true;
  }

  return marker;
}
