<template>
  <div class="c-popup-header">
    <h3>
      {{ title }}
    </h3>
    <x-icon size="1.5x" @click="onClose"></x-icon>
  </div>
</template>

<script>
import { XIcon } from 'vue-feather-icons';

export default {
  components: {
    XIcon
  },

  props: {
    title: {
      type: String,
      default: 'Refine Further...'
    }
  },

  methods: {
    onClose: function() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">
.c-popup-header {
  position: relative;
  padding: 12px var(--spacer);
  background-color: var(--p1);

  h3 {
    margin: 0;
    font-size: 20px;
  }

  svg {
    position: absolute;
    top: var(--spacer-sm);
    right: var(--spacer);
    cursor: pointer;
  }

  @media screen and (max-width: 428px) {
    padding: var(--margin-lg) var(--spacer-sm);

    svg {
      right: var(--spacer-sm);
    }
  }
}
</style>
