/**
 * This is a very specific function that compares array2 with array1 and checks whether the entiretly of array2 is present within array1
 *
 * Since this function will run multiple times inside filters, very specific steps have been taken to ensure this function runs deterministically and quickly
 *
 * @param {array} array1
 * @param {array} array2
 *
 * Returns true if all array elements from array2 are present in array1, otherwise false
 */
export default function(array1, array2, debug = false) {
  /**
   * Setting up initial variables
   */
  let big = array1;
  let small = array2;
  let condition = false;

  /**
   * If array2 is bigger than array1, flip them around
   */
  if (small.length >= big.length) {
    small = array1;
    big = array2;
  }

  /**
   * Loop through the bigger array and check if the bigger array has every element from the smaller array
   * If not, return false
   */

  for (let i = 0; i < small.length; i++) {
    if (big.includes(small[i])) {
      condition = true;
      break;
    }
  }

  return condition;
}
