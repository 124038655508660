<template>
  <div :class="['c-avatar', { large: large === true }, { padding: padding === true }]">
    <img v-if="src" :src="src" />
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    padding: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.c-avatar {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: var(--avatar-size);
  width: var(--avatar-size);
  height: var(--avatar-size);
  flex-grow: 0;
  border-radius: 50%;
  background-color: var(--g1);
  border: transparent solid 1px;

  .btn {
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 0 3px #fff;

    &:hover,
    &:active {
      transform: translateX(-50%) scale(1.05);
    }
  }

  .c-status {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    border-radius: 50%;
  }

  &.padding {
    border-color: var(--g4);
    padding: calc(var(--avatar-size) / 10);

    img {
      object-fit: contain;
      object-position: center center;
    }
  }

  &.large {
    min-width: 100px;
    width: 100px;
    height: 100px;
  }

  @media screen and (max-width: 428px) {
    min-width: 50px;
    width: 50px;
    height: 50px;

    &.large {
      min-width: 75px;
      width: 75px;
      height: 75px;
    }
  }
}
</style>
