<template>
  <div class="c-copy-phone">
    <strong>
      {{ title }}
    </strong>
    <p>
      <a :href="`tel:${phone}`" @click="$emit('click', 'phone')">{{ phone }}</a>
    </p>
  </div>
</template>

<script>
import { CopyIcon } from 'vue-feather-icons';

export default {
  components: {
    CopyIcon,
  },

  props: {
    phone: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'Phone:',
    },
  },

  methods: {
    onClick: function () {},
  },
};
</script>

<style lang="scss">
.c-copy-phone {
  padding: var(--margin-sm) 0;

  .svg {
    stroke-width: 3px;
  }

  p {
    margin: 0;
    display: inline-block;
  }

  a {
    color: var(--font-color);
    text-decoration: none !important;
  }

  a:last-child {
    display: inline-block;
    margin-left: var(--margin);
  }
}
</style>
