<template>
  <div class="c-modal-terms">
    <vue-final-modal
      :name="options.name"
      v-model="options.active"
      :ssr="true"
      :lock-scroll="true"
      :hide-overlay="false"
      :click-to-close="true"
      :esc-to-close="true"
      :prevent-click="false"
      :attach="false"
      transition="vfm"
      overlay-transition="vfm"
      :z-index-auto="true"
      :z-index-base="1000"
      :z-index="false"
      :focus-retain="true"
      :focus-trap="false"
      :fit-parent="true"
      :drag="false"
      drag-selector=""
      :keep-changed-style="false"
      :resize="false"
      :resize-directions="['t', 'tr', 'r', 'br', 'b', 'bl', 'l', 'tl']"
      :min-width="0"
      :min-height="0"
      :max-width="Infinity"
      :max-height="Infinity"
    >
      <div>
        <h1 class="h2">{{ title }}</h1>
        <div class="c-modal-terms__copy">
          <InputCopy :id="saveProfileId" />
          <small>Save the direct link to your search results</small>
          <hr />
        </div>
        <p>
          Alternatively enter your details below to receive an email<br />
          containing a link directly to your current search results
        </p>
        <FormSaveSearch @back="onClose" :id="saveProfileId"></FormSaveSearch>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal';
import FormSaveSearch from './forms/form-save-search';
import InputCopy from './forms/input-copy';

export default {
  components: {
    VueFinalModal,
    ModalsContainer,
    FormSaveSearch,
    InputCopy
  },

  data: function() {
    return {
      options: {
        name: 'modal-save-search',
        active: false
      }
    };
  },

  watch: {
    saveProfileId: {
      handler: function() {
        if (this.saveProfileId) {
          this.options.active = true;
        } else {
          this.options.active = false;
        }
      },
      immediate: false
    }
  },

  computed: {
    copyText: function() {
      return window.location.href;
    },
    title: function() {
      let str = `Save Your Search Results`;

      if (this.saveProfileId === 2) {
        str = `Save your profile search results`;
      }

      return str;
    },
    saveProfileId: function() {
      return this.$store.getters['saveProfileId'];
    }
  },

  methods: {
    onClose: function() {
      this.$store.dispatch('setSaveProfileId', null);
    },
    show: function() {
      this.$store.dispatch('setSaveProfileId', 1);
    }
  }
};
</script>

<style lang="scss">
.c-modal-terms {
  &__copy {
    display: flex;
    flex-direction: column;

    hr {
      width: 100%;
    }
  }

  .vfm__overlay {
    background-color: transparent !important;
    backdrop-filter: blur(5px);
  }
  .vfm__container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--spacer);
  }
  .vfm__content {
    max-width: 880px;
    padding: var(--spacer-lg);
    background-color: var(--g1);
    @include modalStyles();
    border-radius: var(--border-radius-lg);
    text-align: center;
  }

  @media screen and (max-width: 428px) {
    .vfm__container {
      padding: var(--spacer-sm);
    }
    .vfm__content {
      padding: var(--spacer-sm);
    }
  }
}
</style>
