<template>
  <div class="c-form-footer">
    <div class="c-form-footer__buttons">
      <button class="btn btn-secondary btn-lg" @click="$emit('onSubmit')">Update Results</button>

      <button type="button" class="btn btn-lg" @click="$emit('onBack')">Cancel and Go Back</button>
    </div>

    <div class="c-form-footer__disclaimer" v-if="disclaimer">
      <span
        >Search summary and results will be revealed after your refinements have been updated.</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disclaimer: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.c-form-footer {
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: var(--spacer-sm) var(--spacer);
  }
  &__disclaimer {
    margin-bottom: var(--spacer-sm);
    font-size: 13px;
    text-align: center;
    padding: 12px 60px;
    background-color: var(--warning-color);

    > span {
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
