<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" fill="currentColor" data-name="Layer 1" viewBox="0 0 96.33 130.98">
    <path
      d="M34 56.31A15.71 15.71 0 1 0 49.71 72 15.71 15.71 0 0 0 34 56.31zM42.72 74H36v6.72a2 2 0 0 1-4 0V74h-6.72a2 2 0 0 1 0-4H32v-6.7a2 2 0 0 1 4 0V70h6.72a2 2 0 0 1 0 4zm7.78 45.28h-33a1 1 0 0 1 0-2h33a1 1 0 0 1 0 2z"
    />
    <path
      d="M69.75 0C59.68 0 50.9 4.44 46.38 11H11.47A11.48 11.48 0 0 0 0 22.45v97.06A11.48 11.48 0 0 0 11.47 131h45.07A11.47 11.47 0 0 0 68 119.51V42.57l1-.57h.76c14.66 0 26.58-9.42 26.58-21S84.41 0 69.75 0zm0 39h-1.51l-.24.14-6 3.41-5.38 3.06 1.78-8.77-1.06-.5c-6.8-3.23-10.94-8.71-11.17-14.77v-.54a3.85 3.85 0 0 1 0-.48 9.63 9.63 0 0 1 .07-1.08 12.9 12.9 0 0 1 .52-2.47A14.64 14.64 0 0 1 48 14a16.56 16.56 0 0 1 2.13-3c4.28-4.83 11.47-8 19.62-8 13 0 23.58 8.08 23.58 18S82.75 39 69.75 39zM62 119.51a5.47 5.47 0 0 1-5.46 5.49H11.47A5.48 5.48 0 0 1 6 119.51V22.45A5.48 5.48 0 0 1 11.47 17h6.46l.19 1A4.77 4.77 0 0 0 23 21.68h20.17c.27 6.86 4.62 13 11.83 16.82l-2.63 13L62 46z"
    />
    <path
      d="M61.2 19.6a2.58 2.58 0 0 0-1.61-.6A2.61 2.61 0 1 0 62 22.63a2.45 2.45 0 0 0 .2-1 2.6 2.6 0 0 0-1-2.03zm8.92-.6a2.59 2.59 0 0 0-2.3 1.39 2.62 2.62 0 0 0-.3 1.22 2.6 2.6 0 1 0 2.6-2.61zm10.52 0a2.61 2.61 0 1 0 2.62 2.61A2.62 2.62 0 0 0 80.64 19z"
    />
  </svg>
</template>

<script>
export default {};
</script>
