<template>
  <div :class="['c-sidebar', { active: ready === true }]">
    <!-- <Header></Header> -->
    <FilterView></FilterView>
    <List ref="list" :users="users"></List>
    <FilterPanel></FilterPanel>
    <MapFooter></MapFooter>
  </div>
</template>

<script>
import Logo from '@/components/global/logo';
import FilterView from './filter-view';
import List from './list';
import ListItem from './list-item';
import FilterPanel from './filter-panel';
import Header from './header';
import MapFooter from './map-footer';

export default {
  components: {
    Header,
    MapFooter,
    Logo,
    FilterView,
    List,
    ListItem,
    FilterPanel
  },

  data: function() {
    return {
      showForm: false
    };
  },

  computed: {
    ready: function() {
      return this.$store.getters['ready'];
    },
    currentMarker: function() {
      return this.$store.getters['markers/currentMarker'];
    },
    currentUser: function() {
      if (!this.currentMarker) {
        return false;
      }

      return this.$store.getters['users/user'](this.currentMarker.user);
    }
  },

  props: {
    users: {
      type: Array,
      default: function() {
        return [];
      }
    }
  }
};
</script>

<style lang="scss">
.c-sidebar {
  position: absolute;
  z-index: 1;
  top: var(--header-height);
  left: 0;
  width: var(--sidebar-width);
  height: 100%;
  height: calc(100% - var(--header-height));

  display: flex;
  flex-direction: column;

  transform: translateX(-100%);
  opacity: 0;
  pointer-events: none;
  box-shadow: 0.5rem 0 8px rgb(0 0 0 / 20%);

  transition: all var(--transition-time) ease-out;

  &.active {
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
  }

  > .c-list-item {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none;
  }

  @media screen and (max-width: 428px) {
    z-index: 1;
    left: 0;
    width: 100%;
    top: var(--header-height);
    pointer-events: none;

    &.active {
      pointer-events: none;
    }

    .c-filter-view {
      pointer-events: all;
      background-color: #fff;
    }
    .c-filter-panel {
      pointer-events: all;
    }

    > .c-list-item {
      display: block;
    }
  }
}
</style>
