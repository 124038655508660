<template>
  <div class="c-form-profession">
    <form @submit.prevent="onSubmit">
      <div class="c-form-profession__body">
        <h4>Seeking Help for</h4>
        <Dropdown ref="dropdownHelpFor">
          <template v-slot:input>
            <span>{{ professionTitle }}</span>
          </template>
          <template>
            <span
              v-for="status in statuses"
              :key="status.value"
              :class="[{ active: status.value === form.help_for }]"
              @click="onStatusClick(status.value)"
            >
              {{ status.key }}
            </span>
          </template>
        </Dropdown>
        <Checkbox
          ref="professionCheckboxGroup"
          v-if="form.help_for"
          v-model="form.expertise"
          :items="stages[form.help_for]"
          :topLevelToggle="form.help_for !== 'grief_and_loss'"
        ></Checkbox>
      </div>
      <FormFooter @onBack="onBack"></FormFooter>
    </form>
  </div>
</template>

<script>
import Dropdown from '@/components/global/dropdown';
import Accordion from '@/components/global/accordion';
import FormFooter from '@/components/global/form-footer';

import Checkbox from '@/components/checkbox';

import {
  statuses,
  stages,
  deliveries,
  professions,
  costs,
  services,
  languages
} from '@/lib/form-data.js';

import slugify from '@/lib/slugify.js';

import VueSlider from 'vue-slider-component';

export default {
  components: {
    Dropdown,
    Accordion,
    VueSlider,
    FormFooter,
    Checkbox
  },

  data: function() {
    return {
      statuses: statuses,
      stages: stages,
      deliveries: deliveries,
      professions: professions,
      costs: costs,
      services: services,
      languages: languages,

      // gets populated by the watcher
      form: {}
    };
  },

  watch: {
    filter: {
      handler: function() {
        this.form = this.setupForm(this.filter);
      },
      immediate: true
    }
  },

  computed: {
    professionTitle: function() {
      if (!this.form.help_for) {
        return '';
      }

      let str = '';

      for (let i = 0; i < this.statuses.length; i++) {
        if (this.statuses[i].value === this.form.help_for) {
          str = this.statuses[i].key;
          break;
        }
      }

      return str;
    },
    debug: function() {
      return this.$store.getters['debug'];
    },
    filter: function() {
      return this.$store.getters['map/filter'];
    }
  },

  methods: {
    onStatusClick: function(value) {
      this.form.help_for = value;
      this.$refs.dropdownHelpFor.onToggle();

      // We do this cuz the perinatal ref won't be available without it
      this.$nextTick(() => {
        if (this.$refs.professionCheckboxGroup) {
          this.$refs.professionCheckboxGroup.checkAll();
        } else {
          console.log('ref not accessible');
          this.$nextTick(() => {
            if (this.$refs.professionCheckboxGroup) {
              this.$refs.professionCheckboxGroup.checkAll();
            } else {
              console.log('ref still not accessible');
            }
          });
        }
      });
    },
    setupForm: function(filter) {
      let form = JSON.parse(JSON.stringify(filter));

      return form;
    },
    slugify: function(str) {
      return slugify(str);
    },
    async onSubmit() {
      let form = this.form;
      await this.$store.dispatch('map/updateFilter', form);

      this.$emit('submit');
    },
    onBack: function() {
      this.$emit('back');
    }
  },

  mounted: function() {
    if (this.form.help_for) {
      this.$nextTick(() => {
        this.$refs.professionCheckboxGroup.checkAll();
      });
    }
  }
};
</script>

<style lang="scss">
.c-form-profession {
  flex-grow: 1;

  > form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .c-dropdown {
    margin-bottom: var(--margin);
  }

  &__body {
    padding: var(--margin-lg) var(--spacer);

    &__inner {
      display: flex;
      align-items: center;

      > p {
        margin-bottom: 0;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: var(--spacer-sm) var(--spacer);
  }

  &__disclaimer {
    padding: var(--margin-lg) var(--spacer);
    background-color: var(--warning-color);
  }

  .c-checkbox-group {
    display: none;
  }

  @media screen and (max-width: 428px) {
    &__body {
      padding: var(--margin-lg) var(--spacer-sm);
    }

    &__buttons {
      padding: var(--margin-lg) var(--spacer-sm);
    }
  }
}
</style>
