<template>
  <div
    :class="[
      'c-accordion',
      { active: actuallyActive === true },
      { 'min-height': minHeight === true }
    ]"
  >
    <div class="c-accordion__header" @click="onToggle">
      <h4>{{ title }}</h4>
      <Tooltip v-if="description" :description="description"></Tooltip>
      <chevron-down-icon size="1.5x"></chevron-down-icon>
    </div>
    <div class="c-accordion__body">
      <div class="c-accordion__body__inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon } from 'vue-feather-icons';
import smoothReflow from 'vue-smooth-reflow';
import Tooltip from './tooltip';

export default {
  components: {
    ChevronDownIcon,
    Tooltip
  },

  mixins: [smoothReflow],

  watch: {
    active: {
      handler: function() {
        if (this.active) {
          this.actuallyActive = true;
        }
      },
      immediate: true
    }
  },

  data: function() {
    return {
      actuallyActive: false
    };
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Accordion'
    },
    description: {
      type: String,
      default: null
    },
    minHeight: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onToggle: function() {
      this.actuallyActive = !this.actuallyActive;
    }
  },

  mounted() {
    //this.$smoothReflow();
  }
};
</script>

<style lang="scss">
.c-accordion {
  &__header {
    padding: 12px 0;
    position: relative;
    cursor: pointer;
    user-select: none;

    display: flex;
    align-items: center;

    border-bottom: var(--g5) solid 1px;

    > h4 {
      margin: 0;
    }

    svg {
      display: inline-block;
      position: absolute;
      top: calc(50% - 12px);
      right: 0;
      cursor: pointer;
      transform: rotate(0);
      transition: all var(--transition-time-lg) ease-out;
      opacity: 0.5;
    }
  }

  &__body {
    height: 0;
    overflow: hidden;
    border-bottom: transparent solid 1px;

    &__inner {
      padding: var(--margin-lg) 0;
    }
  }

  &.active {
    .c-accordion__header {
      svg {
        opacity: 1;
        transform: rotate(180deg);
      }
    }
    .c-accordion__body {
      height: auto;
      border-bottom-color: var(--g5);
    }
  }

  &.min-height {
    &.active {
      .c-accordion__body {
        min-height: 220px;
      }
    }
  }

  @media screen and (max-width: 428px) {
    &__header {
      padding: var(--margin-lg) 0;
    }
  }
}
</style>
