<template>
  <div :class="['c-list', { disable: showList === false }, { peek: isPeek === true }]">
    <ListItem v-for="user in currentUsers" :key="user.id" :user="user"></ListItem>
    <ListDivider
      v-if="telehealthUsers.length > 0"
      :count="telehealthUsers.length"
      :showServices="serviceList"
      @onShowServices="onShowServices"
    >
    </ListDivider>
    <div class="c-list__services" v-if="serviceList">
      <ListItem v-for="user in telehealthUsers" :key="user.id" :user="user"></ListItem>
    </div>
  </div>
</template>

<script>
import ListItem from './list-item';
import ListDivider from './list-divider';

import VueScrollTo from 'vue-scrollto';

export default {
  components: {
    ListItem,
    ListDivider
  },

  data: function() {
    return {};
  },

  props: {
    users: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  watch: {
    activeUser: {
      handler: function() {
        if (this.activeUser) {
          this.scrollTo(this.activeUser);
        }
      }
    },
    showServices: function() {
      if (this.showServices) {
        this.$nextTick(() => {
          this.scrollTo(document.querySelector('.c-list-divider'));
        });
      }
    },
    filterTrigger: function() {
      if (this.filter.marker_id) {
        let marker_id = this.filter.marker_id;
        let marker = this.$store.getters['markers/markerByFullName'](marker_id);
        if (marker) {
          this.$store.dispatch('markers/setMarker', marker.id);
        }
      } else if (this.filter.user_id) {
        let user_id = parseInt(this.filter.user_id);

        if (!isNaN(user_id)) {
          let user = this.$store.getters['users/user'](user_id);
          this.$store.dispatch('users/setActiveUser', user);
        }
      }
    }
  },

  computed: {
    filterTrigger: function() {
      return this.$store.getters['map/filterTrigger'];
    },
    serviceList: function() {
      return this.$store.getters['map/serviceList'];
    },
    filter: function() {
      return this.$store.getters['map/filter'];
    },
    currentUser: function() {
      let user = this.filter.user || null;

      if (!user) {
        return null;
      }

      user = parseInt(user);

      if (!user) {
        return null;
      }

      user = this.$store.getters['users/user'](user);

      return user;
    },
    currentUsers: function() {
      let users = this.users;
      let currentUser = this.currentUser;

      if (currentUser) {
        users = [currentUser];
      } else {
        users = users.filter(user => {
          if (user.show) {
            return true;
          } else {
            return false;
          }
        });

        users = this.sort(users);
      }

      return users;
    },
    telehealthUsers: function() {
      let users = this.users;

      let type = this.filter.type;

      users = users.filter(user => {
        // if (
        //   user.full_name.includes(
        //     'PANDA - Perinatal Anxiety & Depression Australia National Helpline'
        //   )
        // ) {
        //   console.log(user);
        // }
        if (user.inParam && user.telehealth) {
          return true;
        } else {
          return false;
        }
      });

      if (type) {
        users = users.filter(user => {
          if (user.type === type) {
            return true;
          } else {
            return false;
          }
        });
      }

      // Convert users into groups segregrated by their statuses
      let newUsers = [];
      let statusGroups = users.reduce(function(r, a) {
        r[a.status] = r[a.status] || [];
        r[a.status].push(a);
        return r;
      }, Object.create(null));

      // Then manually rearrange groups in the newUsers array
      let available = statusGroups['available'] || [];
      let longWaitTimes = statusGroups['long-wait-times'] || [];
      let unavailable = statusGroups['unavailable'] || [];
      let inactive = statusGroups['inactive'] || [];

      newUsers = newUsers.concat(available);
      newUsers = newUsers.concat(longWaitTimes);
      newUsers = newUsers.concat(unavailable);
      newUsers = newUsers.concat(inactive);

      return newUsers;
    },
    currentMarker: function() {
      return this.$store.getters['markers/currentMarker'];
    },
    activeUser: function() {
      return this.$store.getters['users/activeUser'];
    },
    isMobile: function() {
      return this.$store.getters['isMobile'];
    },
    showList: function() {
      let condition = this.$store.getters['showList'];
      return condition;
    },
    isPeek: function() {
      if (!this.showList && this.isMobile && this.activeUser) {
        return true;
      }
      return false;
    }
  },

  methods: {
    onShowServices: function() {
      this.$store.dispatch('map/showServiceList');
    },

    // check if deliveries array has f2f or f2fgroup. no other filter is allowed
    isF2f: function(deliveries) {
      let condition = false;
      if (deliveries.length === 1) {
        if (
          deliveries.includes('Face-to-face (individual)') ||
          deliveries.includes('Face-to-face (group)')
        ) {
          condition = true;
        }
      }
      if (deliveries.length === 2) {
        if (
          deliveries.includes('Face-to-face (individual)') &&
          deliveries.includes('Face-to-face (group)')
        ) {
          condition = true;
        }
      }

      return condition;
    },

    sort: function(users) {
      let newUsers = [];

      let filter = this.filter;

      // Updated - treat f2f / non-f2f as the same
      //let f2f = this.isF2f(filter.deliveries);
      let f2f = false;

      // if only f2f filters are selected, sort by distance -> availability and telehealth
      if (f2f) {
        users = users.sort(this.typeSort);

        // Convert users into groups segregrated by their statuses
        let statusGroups = users.reduce(function(r, a) {
          r[a.status] = r[a.status] || [];
          r[a.status].push(a);
          return r;
        }, Object.create(null));

        // Then manually rearrange groups in the newUsers array
        let available = statusGroups['available'] || [];
        let longWaitTimes = statusGroups['long-wait-times'] || [];
        let unavailable = statusGroups['unavailable'] || [];
        let inactive = statusGroups['inactive'] || [];

        inactive = inactive.sort(this.radiusSort);
        inactive.reverse();

        newUsers = newUsers.concat(available);
        newUsers = newUsers.concat(longWaitTimes);
        newUsers = newUsers.concat(unavailable);

        // Joins all availabilities except inactive and sort them by radius
        newUsers = newUsers.sort(this.radiusSort);
        newUsers.reverse();

        // then join inactive statuses at the bottom
        newUsers = newUsers.concat(inactive);
      } else {
        // Convert users into groups segregrated by their statuses
        let statusGroups = users.reduce(function(r, a) {
          r[a.status] = r[a.status] || [];
          r[a.status].push(a);
          return r;
        }, Object.create(null));

        // Then loop through groups and sort themb based on distance and type
        for (const p in statusGroups) {
          statusGroups[p] = statusGroups[p].sort(this.radiusSort);
        }

        //console.log(statusGroups);

        // Then manually rearrange groups in the newUsers array
        let available = statusGroups['available'] || [];
        let longWaitTimes = statusGroups['long-wait-times'] || [];
        let unavailable = statusGroups['unavailable'] || [];
        let inactive = statusGroups['inactive'] || [];

        newUsers = newUsers.concat(available);
        newUsers = newUsers.concat(longWaitTimes);
        newUsers = newUsers.concat(unavailable);
        newUsers = newUsers.concat(inactive);
      }

      return newUsers;
    },
    radiusSort: function(a, b) {
      if (a.type === 'cso') {
        return 1;
      }

      let aMin = Math.floor(a.minDistance);
      let bMin = Math.floor(b.minDistance);

      return aMin - bMin;
    },

    typeSort: function(a, b) {
      if (a.type === 'cso') {
        return 1;
      }
      if (a.type !== 'cso') {
        return -1;
      }
      return 0;
    },
    scrollTo: async function(user) {
      let element = null;
      if (!user.id) {
        // assume user is element
        element = user;
      }

      this.$nextTick(async () => {
        let container = document.querySelector('.c-list');
        if (!element) {
          let userId = user.id;

          let query = `.c-list-item[data-id="${userId}"]`;
          element = document.querySelector(query);
        }

        if (!element) {
          console.log('CANT SCROLL SINCE ELEMENT DOESNT EXIST');

          await new Promise(r => setTimeout(r, 1000));

          console.log('TRYING AGAIN');

          let userId = user.id;
          let query = `.c-list-item[data-id="${userId}"]`;
          element = document.querySelector(query);
        }

        let containerOffsetTop = container.offsetTop;

        if (this.isMobile) {
          containerOffsetTop = 0;
        }

        container.scroll(0, element.offsetTop - containerOffsetTop);
      });
    }
  },

  mounted: async function() {
    /**
     * If a user_id is set in the url params, and currently visible users are 0, then automatically expand the services list.
     */
    let url = new URL(window.location.href);

    let query = url.search;

    let user_id = url.searchParams.get('user_id');

    if (query.includes('user_id') && this.currentUsers.length === 0) {
      // expand the service list
      this.onShowServices();

      // scrollto will automatically happen, check the watcher above
    }
  }
};
</script>

<style lang="scss">
.c-list {
  height: 100%;
  margin-bottom: var(--footer-height);
  overflow-y: auto;
  background-color: var(--g1);
  pointer-events: all;
  transition: opacity var(--transition-time) ease-out;

  //https://stackoverflow.com/questions/40722882/css-native-variables-not-working-in-media-queries
  // Can't use vars in media queries :(
  @media screen and (max-width: 428px) {
    &.disable {
      transform: translateY(100%);
      opacity: 0;
      pointer-events: none;
    }

    &.peek {
      display: block;
      pointer-events: all;
      opacity: 1;
      overflow-y: hidden;
      transform: translateY(calc(100% - 92px));
    }
  }

  &__services {
    border-left: var(--p2) solid 2px;
    border-right: var(--p2) solid 2px;
  }
}
</style>
