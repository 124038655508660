import api from '../lib/api';

import { mapStyle, getPins } from '@/components/map/map-style.js';

import normalizeFilter from '../lib/normalize-filter.js';

import hasAllArrayInsideArray from '../lib/has-all-array-inside-array.js';

import mapRange from '../lib/map-range.js';

import filterRadius from '../lib/filter-radius.js';
import filterParams from '../lib/filter-params.js';
import filterTelehealth from '../lib/filter-telehealth.js';
import filterType from '../lib/filter-type.js';

let store = {
  namespaced: true,
  state: {
    showFilterForm: false,
    options: {
      zoom: 14
    },
    filter: {
      postcode: '',
      radius: 10,
      help_for: '',
      type: '',
      deliveries: [],
      profession: [],
      interpreter_service: false,
      atsi: false,
      costs: [],
      languages: [],
      expertise: [],
      user_id: null,
      marker_id: null
    },
    map: {
      google: null,
      map: null,
      circle: null,
      pins: {}
    },
    searchReport: null,
    physicalResultsCount: 1,
    filterTrigger: false,
    serviceList: false
  },

  getters: {
    serviceList: function(state) {
      return state.serviceList;
    },
    showFilterForm: function(state) {
      return state.showFilterForm;
    },
    filter: function(state) {
      return state.filter;
    },
    pins: function(state) {
      return state.map.pins;
    },
    google: function(state) {
      return state.map.google;
    },
    map: function(state) {
      return state.map.map;
    },
    searchReport: function(state) {
      return state.searchReport;
    },
    disableMap: function(state, getters, rootState, rootGetters) {
      if (rootGetters.filterOverlayForm) {
        return true;
      }
      if (getters.showFilterForm) {
        return true;
      }
      return false;
    },
    hasNoPhysicalResults: function(state) {
      if (state.physicalResultsCount === 0) {
        return true;
      } else {
        return false;
      }
    },
    filterTrigger: function(state) {
      return state.filterTrigger;
    }
  },

  mutations: {
    SET_SERVICELIST: function(state, condition) {
      state.serviceList = condition;
    },
    SET: function(state, data) {
      let key = data.key;
      let value = data.value;
      if (key === 'filter') {
        throw Error('you cant set filter directly like this. Use SET_FILTER mutation');
      }
      state[key] = value;
    },

    SET_FILTER: function(state, filter) {
      state.filter = filter;
    },
    UPDATE_MAP_VARIABLES: function(state, data) {
      if (data.google) {
        state.map.google = data.google;
      }
      if (data.map) {
        state.map.map = data.map;
      }
      if (data.google) {
        state.map.pins = getPins(state.map.google);
      }
    },

    SET_SEARCH_REPORT: function(state, report) {
      state.searchReport = report;
    },
    SET_PHYSICAL_RESULTS_COUNT: function(state, count) {
      state.physicalResultsCount = count;
    },
    SET_CIRCLE: function(state, circle) {
      state.map.circle = circle;
    },
    AFTER_FILTER_RUN: function(state) {
      state.filterTrigger = !state.filterTrigger;
    }
  },

  actions: {
    async init(store) {
      await store.dispatch('get');
    },

    async get(store) {},

    showServiceList: function(store) {
      store.commit('SET_SERVICELIST', true);
    },

    showFilterForm: function(store) {
      let data = {
        key: 'showFilterForm',
        value: true
      };
      store.commit('SET', data);
    },

    hideFilterForm: function(store) {
      let data = {
        key: 'showFilterForm',
        value: false
      };
      store.commit('SET', data);
    },

    setFilter: function(store, filter) {
      filter = normalizeFilter(filter);

      store.commit('SET_FILTER', filter);
    },

    async updateFilter(store, filter) {
      store.dispatch('setFilter', filter);

      return await store.dispatch('filter');
    },

    updateMapVariables: function(store, data) {
      store.commit('UPDATE_MAP_VARIABLES', data);
    },

    async getPositionFromPostcode(store, postcode) {
      let geocoder = new store.state.map.google.maps.Geocoder();
      let searchTerm = postcode + '';

      let isNumber = parseInt(searchTerm);

      if (!isNaN(isNumber)) {
        postcode = isNumber;
      } else {
        // Set a default to melbourne
        if (postcode.length === 0) {
          postcode = 3000;
        }
      }

      /**
       * If postcode seems valid, then append the state code after it.
       */
      if (typeof postcode === 'number' && postcode > 0) {
        /**
         * ACT's postcode range was derived from:
         * https://postcodes-australia.com/state-postcodes/act
         * 
         * There's a chance this won't hold up for too long as newer postcodes are introduced
         */
        if((postcode >= 2600 && postcode <= 2617) || (postcode >= 2900 && postcode <= 2914)) {
          searchTerm += ', ACT';
        } else if
         (
          (1000 <= postcode && postcode <= 1999) ||
          (2000 <= postcode && postcode <= 2599) ||
          (2619 <= postcode && postcode <= 2899) ||
          (2921 <= postcode && postcode <= 2999)
        ) {
          searchTerm += ', NSW';
        } else if (
          (200 <= postcode && postcode <= 299) ||
          (2600 <= postcode && postcode <= 2618) ||
          (2900 <= postcode && postcode <= 2920)
        ) {
          searchTerm += ', NSW';
        } else if (searchTerm.charAt(0) == '3' || searchTerm.charAt(0) == '8') {
          searchTerm += ', VIC';
        } else if (searchTerm.charAt(0) == '4' || searchTerm.charAt(0) == '9') {
          searchTerm += ', QLD';
        } else if (searchTerm.charAt(0) == '5') {
          searchTerm += ', SA';
        } else if (searchTerm.charAt(0) == '6') {
          searchTerm += ', WA';
        } else if (searchTerm.charAt(0) == '7') {
          searchTerm += ', TAS';
        }
        searchTerm = `${searchTerm} australia`;
      } else {
        searchTerm = postcode + ' australia';
      }

      console.log(searchTerm);

      // Specify a default
      if (!searchTerm) {
        searchTerm = '3000, VIC australia';
      }
      let details = await geocoder.geocode({ address: searchTerm }).catch(err => {
        console.log('GEOCODER ERROR');
        console.log(`QUERY: ${searchTerm}`);
        console.log(err);
        throw err;
      });
      return details;
    },

    async createCircle(store, data) {
      let center = data.center;
      let radius = data.radius;
      let map = store.state.map.map;
      let circle = new store.state.map.google.maps.Circle({
        strokeColor: '#6c3e58',
        strokeOpacity: 0.4,
        strokeWeight: 2,
        fillColor: '#6c3e58',
        fillOpacity: 0.15,
        map,
        center,
        radius
      });

      store.state.map.circle = circle;

      return circle;
    },
    async updateCircle(store, data) {
      let center = data.center;
      let radius = data.radius;
      let circle = data.circle;

      circle.setCenter(center);
      circle.setRadius(radius);

      return circle;
    },

    /**
     * Filters users by:
     * 1. params
     * 2. radius(via google maps)
     * 3. telehealth
     */
    async filter(store) {
      /**
       * Get all variables in one place first
       */
      let filter = store.state.filter;
      let google = store.state.map.google;
      let zoom = store.state.options.zoom;
      let distance = 0;
      let circle = store.state.map.circle;
      let users = store.rootGetters['users/users'];

      // store.dispatch('analytics/onSearchFilter', filter, { root: true });

      users = JSON.parse(JSON.stringify(users));

      users = users.map(user => {
        user.show = true;
        user.telehealth = false;

        user.inParam = false;
        user.inRadius = false;
        user.inService = false;
        user.inType = false;

        return user;
      });

      const trackedUserId = store.rootGetters['trackedUser'];

      let trackedUser = null;

      if (trackedUserId) {
        let user = null;
        for (let i = 0; i < users.length; i++) {
          const u = users[i];
          if (u.id === trackedUserId) {
            trackedUser = u;
            break;
          }
        }

        if (trackedUser) {
          const message = `Filter run`;
          console.log('%c' + message, 'color:Orange');
          console.log(JSON.parse(JSON.stringify(filter)));
        }
      }

      users = filterParams(users, filter, trackedUser);

      /**
       * Multiply radius by 1000 cuz google map search accepts distance in meters - but we use kilometers internally
       */
      let radius = filter.radius * 1000;

      /**
       * Get lat/lng from google's geocoder. This translates string based addresses in lat/lng
       */
      let details = await store.dispatch('getPositionFromPostcode', filter.postcode);

      if (!details.results[0]) {
        return;
      }

      let position = details.results[0].geometry.location;

      /**
       * Create a visible circle based on the lat/lng given by the geocoder
       */
      if (!circle) {
        circle = await store.dispatch('createCircle', {
          center: position,
          radius: radius
        });
      } else {
        circle = await store.dispatch('updateCircle', {
          circle: circle,
          center: position,
          radius: radius
        });
      }

      /**
       * Prepping variables for the big loop
       */
      let center = circle.getCenter();
      //let circleRadius = circle.getRadius();

      /**
       * This loop loops though all filtered users and toggles the user's markers based on if its within the circle we just rendered
       *
       * This loop and the loop within it are linear. So 500 users and 1000 markers results in 1500 total iterations
       */
      let temp = filterRadius(users, circle, google, trackedUser);
      let physicalResultsCount = temp.physicalResultsCount;
      users = temp.users;

      /**
       * Finally, filter users by telehealth
       */
      users = filterTelehealth(users, trackedUser);

      /**
       * Generate a report here and save it to store
       *
       * So we can show count next to user types inside filter panel component
       */
      store.commit('SET_SEARCH_REPORT', generateSearchReport(users));

      /**
       * Filter by user types last
       *
       * Very similary to filtering by params
       */
      users = filterType(users, filter, trackedUser);

      // users.map(user => {
      //   if (user.email === 'office@gymbaroo.com.au') {
      //     console.log(user);
      //   }
      // });

      //Debug tracked user here
      if (trackedUser) {
        const message = `after filter has run`;
        console.log('%c' + message, 'color:Orange');
        for (let i = 0; i < users.length; i++) {
          if (users[i].id === trackedUser.id) {
            console.log('%c' + `show: ${users[i].show}`, 'color:Orange');
            console.log('%c' + `inParam: ${users[i].inParam}`, 'color:Orange');
            console.log('%c' + `inRadius: ${users[i].inRadius}`, 'color:Orange');
            console.log('%c' + `inService: ${users[i].inService}`, 'color:Orange');
            console.log('%c' + `inType: ${users[i].inType}`, 'color:Orange');

            console.log(users[i]);
          }
        }
      }

      /**
       * Set the position of the current map to the center of the circle
       */
      store.dispatch('setMapPosition', {
        center: center,
        zoom: zoom,
        radius: filter.radius
      });

      store.commit('SET_CIRCLE', circle);

      store.commit('SET_PHYSICAL_RESULTS_COUNT', physicalResultsCount);

      /**
       * Lastly, remove currently selected marker
       */
      await store.dispatch('markers/setMarker', null, { root: true });

      /**
       * And set filtered users so the sidebar and map-view gets updated
       */
      await store.dispatch('users/setFilteredUsers', users, { root: true });

      /**
       * Also track search details
       */
      // store.dispatch('analytics/onAfterSearch', null, { root: true });

      store.commit('AFTER_FILTER_RUN');
    },

    setMapPosition: function(store, data) {
      let center = data.center;
      let zoom = data.zoom;
      let map = store.state.map.map;
      let radius = data.radius;

      // Returns zoom in float, change to int if necessary
      zoom = mapRange(radius, 1, 50, 13, 7);

      /**
       * Setting a upper boundary on smaller screens.
       * Zoom levels shouldn't go above 11
       */
      if (document.body && document.body.clientWidth < 420) {
        if (zoom >= 11) {
          zoom = 11;
        }
      }

      map.setCenter(center);
      map.setZoom(zoom);
    }
  },
  modules: {}
};

function generateSearchReport(users) {
  users = JSON.parse(JSON.stringify(users));
  let types = {};
  for (let i = 0; i < users.length; i++) {
    if (users[i].type in types) {
      if (users[i].show) {
        types[users[i].type] += 1;
      }
    } else {
      types[users[i].type] = 0;
    }
  }

  return types;
}

export default store;
