<template>
  <div class="form-checkbox">
    <input
      type="checkbox"
      ref="checkbox"
      v-model="currentValue"
      :value="item.value"
      :id="slugify(item.key)"
    />
    <label :for="slugify(item.key)">
      {{ item.key }} <span class="small">{{ item.value }}</span>
    </label>
  </div>
</template>

<script>
import slugify from '@/lib/slugify.js';

export default {
  data: function() {
    return {
      content: [],
      currentValue: null,
      isIndeterminate: true
    };
  },

  /**
   * We use currentValue cuz we don't want to mutate the value prop. Instead, we listen to currentValue and pass the update back inside onChange
   */
  watch: {
    value: {
      handler: function() {
        this.currentValue = this.value;
      },
      immediate: true
    },
    currentValue: function() {
      this.onChange();
    },
    indeterminate: {
      handler: function() {
        this.setIndeterminate();
      },
      immediate: false
    }
  },
  props: {
    value: {
      type: Boolean
    },
    item: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  methods: {
    setIndeterminate: function() {
      if (this.isIndeterminate) {
        this.$refs.checkbox.indeterminate = true;
      } else {
        this.$refs.checkbox.indeterminate = false;
      }
    },
    slugify: function(str) {
      return slugify(str);
    },
    onChange: function() {
      this.$emit('input', this.currentValue);
    },
    toggleIndeterminate: function() {
      this.isIndeterminate = !this.indeterminate;
    }
  },

  mounted: function() {
    this.setIndeterminate();
  }
};
</script>

<style lang="scss">
.c-checkbox-group {
  .small {
    font-size: 10px;
  }

  &__sublist {
  }
}
</style>
