<template>
  <div
    :data-id="user.id"
    :class="[
      'c-list-item',
      {
        selected: isActiveUser === true,
        minimized: minimized === true,
        disabled: user.show === false
      }
    ]"
    @click="onClick"
  >
    <div v-if="hasTelehealth" class="c-list-item__sticker">
      <IconTelehealth></IconTelehealth>
    </div>
    <div class="c-list-item__header">
      <Avatar
        :status="user.status"
        :large="isActiveUser === true"
        :src="avatar"
        :padding="user.type !== 'ip'"
      >
        <button v-if="isActiveUser" class="btn btn-primary" @click="saveProfile">
          Save
        </button>
      </Avatar>
      <article>
        <h3>{{ removeEscape(user.full_name) }}</h3>
        <span :class="['label', user.type]" v-if="labelProfessions.length > 0">
          {{ labelProfessions.join(', ') }}
        </span>
        <a class="show-more" href="#" v-if="minimized"
          ><chevron-up-icon size="1x"></chevron-up-icon>Show more details</a
        >
        <a class="show-more" href="#" v-if="!isMobile && !isActiveUser"
          ><maximize-2-icon size="1x" style="margin-right:4px;margin-bottom:-2px"></maximize-2-icon
          >Show more details</a
        >
      </article>
    </div>
    <div v-if="!minimized" class="c-list-item__body">
      <CopyStatus title="Availability:" :status="user.status"> </CopyStatus>
      <CopyGeneric
        v-if="!isServiceOnly"
        title="Company/Practice:"
        :value="removeEscape(user.company)"
      ></CopyGeneric>
      <CopyPhone
        v-if="user.phone"
        @click="onInteraction"
        :phone="user.phone"
        :title="phoneTitle"
      ></CopyPhone>
      <CopyEmail :email="user.email" @click="onInteraction"></CopyEmail>
      <CopyWebsite v-if="user.website" @click="onInteraction" :website="user.website"></CopyWebsite>
      <!-- Show this on selected only -->
      <CopyGeneric
        v-if="isActiveUser && !isServiceOnly"
        title="Company/Practice:"
        :value="removeEscape(user.company)"
      ></CopyGeneric>
      <CopyGeneric
        v-if="isActiveUser && user.service_summary"
        :value="user.service_summary"
        title="Service Summary:"
      ></CopyGeneric>
      <CopyGeneric
        v-if="isActiveUser && allUserExpertise"
        :value="allUserExpertise.join(', ')"
        title="Areas of Expertise:"
      ></CopyGeneric>
      <CopyGeneric
        v-if="isActiveUser && user.aphra"
        :value="user.aphra"
        title="AHPRA Number:"
      ></CopyGeneric>
    </div>
    <div
      v-if="!minimized && user.service_summary && otherModesOfDelivery"
      class="c-list-item__section"
    >
      <h3>Service Information</h3>
      <CopyGeneric
        v-if="otherModesOfDelivery"
        title="Modes of Delivery:"
        :value="otherModesOfDelivery"
      ></CopyGeneric>
      <CopyGeneric
        v-if="serviceInformation"
        title="Service Information:"
        :value="serviceInformation"
      ></CopyGeneric>
      <CopyGeneric v-if="details" :value="details" title="Additional details:"></CopyGeneric>
    </div>
    <div v-if="!minimized" class="c-list-item__footer">
      <p v-if="computedMarkers.length > 0">
        <strong>Locations</strong> <span class="muted">(click below to view on map)</span>
      </p>
      <template v-for="marker in computedMarkers">
        <div
          :key="marker.id"
          :class="['btn', { 'btn-tertiary': isMarkerSelected(marker) }]"
          tabindex="0"
          @click="onLabelClick(marker)"
          v-on:keyup.enter="onLabelClick(marker)"
        >
          <strong>{{ marker.site_name }}</strong>
          <span v-if="marker.distance">&nbsp;| {{ formatDistance(marker.distance) }}</span>
        </div>
      </template>
    </div>

    <!-- Debug stuff -->
    <div v-if="!minimized && debug" class="debug-list">
      <strong> Show : {{ user.show }} </strong>
      <br />
      <strong>ID : {{ user.id }}</strong
      ><br />
      <strong>Status(availability) : {{ user.status }}</strong
      ><br />
      <strong>f2f : {{ user._f2f }}</strong
      ><br />
      <strong>Max distance : {{ user.maxDistance }}</strong
      ><br />
      <strong>Min distance : {{ user.minDistance }}</strong
      ><br />
      <strong>ATSI : {{ user.atsi }}</strong>
      <h6>Expertise</h6>
      <pre>{{ user.expertise }}</pre>
      <h6>Professions</h6>
      <pre>{{ user.professions }}</pre>
      <template v-for="marker in user.markers">
        <article :key="marker.id" :class="[{ selected: isMarkerSelected(marker) }]">
          <div>
            <h6>Interpreter service</h6>
            <p>{{ marker.interpreter_service }}</p>
          </div>
          <div>
            <h6>Deliveries</h6>
            <p>{{ marker.deliveries }}</p>
          </div>
          <div>
            <h6>Costs</h6>
            <p>{{ marker.costs }}</p>
          </div>
          <div>
            <h6>Languages</h6>
            <p>{{ marker.languages }}</p>
          </div>
        </article>
      </template>
    </div>
  </div>
</template>

<script>
import CopyGeneric from '@/components/global/copy-generic';
import CopyEmail from '@/components/global/copy-email';
import CopyPhone from '@/components/global/copy-phone';
import CopyStatus from '@/components/global/copy-status';
import CopyWebsite from '@/components/global/copy-website';

import IconTelehealth from '@/components/global/icon-telehealth';

import Avatar from '@/components/global/avatar';

import { ChevronUpIcon, Maximize2Icon } from 'vue-feather-icons';

export default {
  components: {
    CopyGeneric,
    CopyEmail,
    CopyPhone,
    CopyStatus,
    CopyWebsite,
    IconTelehealth,
    Avatar,
    ChevronUpIcon,
    Maximize2Icon
  },

  data: function() {
    return {
      expanded: false,
      minimized: false
    };
  },

  props: {
    user: {
      type: Object,
      default: function() {
        return null;
      }
    }
    // minimized: {
    //   type: Boolean,
    //   default: false
    // }
  },

  watch: {
    isMinimized: {
      handler: function() {
        if (this.isMinimized) {
          this.minimized = true;
        } else {
          this.minimized = false;
        }
      },
      immediate: true
    },
    showList: {
      handler: function() {
        if (!this.showList) {
          this.minimized = false;
        }
      },
      immediate: true
    }
  },

  computed: {
    showList: function() {
      let condition = this.$store.getters['showList'];
      return condition;
    },
    isMinimized: function() {
      /**
       * This will flip the minimized flag to true ONLY if
       * - An active user is selected, eg: someone clicked on a user or a user's map marker
       * AND
       * - This webpage is being viewed on mobile
       * AND
       * - The user's list is hidden at the moment
       */
      if (this.isActiveUser && this.isMobile && !this.showList) {
        return true;
      }
      return false;
    },
    isMobile: function() {
      return this.$store.getters['isMobile'];
    },
    activeUser: function() {
      return this.$store.getters['users/activeUser'];
    },
    isActiveUser: function() {
      if (!this.activeUser) {
        return false;
      }

      if (this.activeUser && this.activeUser.id === this.user.id) {
        return true;
      }

      return false;
    },
    labelProfessions: function() {
      let user = this.user;
      let professions = [];

      if (user.type === 'ip') {
        professions = user.professions;
        if (professions.includes('Not Listed...')) {
          let i = professions.indexOf('Not Listed...');
          professions.splice(i, 1);
        }
        return professions;
      }
      if (user.type === 'ppos') {
        return ['Perinatal Mental Health Service'];
      }
      if (user.type === 'cso') {
        return ['Community Support Organisation'];
      }
    },
    allUserExpertise: function() {
      return this.user.expertise.concat(this.user.expertise_other);
    },
    debug: function() {
      return this.$store.getters['debug'];
    },
    selected: function() {
      let currentMarker = this.currentMarker;
      if (!currentMarker) {
        return false;
      }

      let markers = this.markersByUserId;
      let selected = false;

      for (let i = 0; i < markers.length; i++) {
        if (markers[i].id === currentMarker.id) {
          selected = true;
          break;
        }
      }

      return selected;
    },
    currentMarker: function() {
      return this.$store.getters['markers/currentMarker'];
    },
    markersByUserId: function() {
      return this.$store.getters['markers/markersByUserId'](this.user.id);
    },
    computedMarkers: function() {
      let markers = this.user.markers;

      markers = markers.filter(marker => {
        if (marker.isService) {
          return false;
        }
        return true;
      });

      markers = markers.sort((a, b) => {
        return a.distance - b.distance;
      });

      return markers;
    },
    avatar: function() {
      let url = this.user.avatar;
      if (!url) {
        url = this.user.image;
      }
      return `https://www.cope.org.au/${url}`;
    },
    phoneTitle: function() {
      if (this.user.type === 'cso') {
        return 'Point of Contact:';
      } else {
        return 'Phone:';
      }
    },
    hasTelehealth: function() {
      let user = this.user;
      let visibleCondition = false;

      for (let i = 0; i < user.markers.length; i++) {
        if (user.markers[i].telehealth) {
          visibleCondition = true;
          break;
        }
      }

      return visibleCondition;
    },
    labelSlug: function() {
      let user = this.user;

      if (user.professions.length > 1) {
        return 'WTF';
      } else {
        return '';
      }
    },
    otherModesOfDelivery: function() {
      if (!this.isServiceOnly) {
        return '';
      }

      if (!this.user.markers[0]) {
        return '';
      }

      let marker = this.user.markers[0];

      if (!marker) {
        return '';
      }

      let arr = marker.deliveries.concat(marker.deliveries_other);

      return arr.join(', ');
    },
    details: function() {
      if (!this.isServiceOnly) {
        return '';
      }

      if (!this.user.markers[0]) {
        return '';
      }

      let marker = this.user.markers[0];

      if (!marker) {
        return '';
      }

      return marker.other_details;
    },
    isServiceOnly: function() {
      let markers = this.user.markers;
      let isServiceOnly = false;
      for (let i = 0; i < markers.length; i++) {
        if (markers[i].isService) {
          isServiceOnly = true;
          break;
        }
      }
      return isServiceOnly;
    },
    serviceInformation: function() {
      if (!this.user.markers[0]) {
        return '';
      }

      let marker = this.user.markers[0];

      const serviceInfoItems = [...marker.costs];

      if (marker.costs_other_description) {
        console.log("MARKKK", JSON.stringify(marker));
        serviceInfoItems.push(marker.costs_other_description);
      }

      return serviceInfoItems.join(", ");
    }
    // otherModesOfDeliveryString: function() {
    //   if (this.otherModesOfDelivery) {
    //     return this.otherModesOfDelivery.join(', ');
    //   }
    //   return false;
    // }
  },

  methods: {
    removeEscape: function(str) {
      str = str.replaceAll(/\\/g, '');

      return str;
    },
    saveProfile: function() {
      this.$store.dispatch('setSaveProfileId', `user:${this.user.id}`);
    },
    onShowMore: function() {},
    onClick: function() {
      if (!this.minimized) {
        this.$store.dispatch('users/setActiveUser', this.user);
      } else {
        this.$store.dispatch('toggleList', true);
        //this.$store.dispatch('markers/setMarker', null);
      }
    },
    onInteraction: function(eventName) {
      // this.$store.dispatch('analytics/onUserInteraction', {
      //   user: this.user,
      //   event: eventName
      // });
    },
    isMarkerSelected: function(marker) {
      let currentMarker = this.currentMarker;

      if (!currentMarker) {
        return false;
      }

      if (currentMarker.id === marker.id) {
        return true;
      }

      return false;
    },
    formatDistance: function(distance) {
      if (!distance) {
        return false;
      }

      let km = distance / 1000;

      km = km.toFixed(2);

      km = km + ' km';

      return km;
    },
    onLabelClick: function(marker) {
      this.$store.dispatch('markers/setMarker', marker.id);
      if (this.isMobile) {
        this.$store.dispatch('toggleList', false);
      }
    }
  },

  mounted: function() {}
};
</script>

<style lang="scss">
.c-list-item {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  border-bottom: var(--g2) solid 1px;

  &.selected {
    background-color: #fefcee;
  }

  &__sticker {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 80px;
    //background-color: var(--p3);
    color: var(--p4);

    svg {
      position: absolute;
      top: 3px;
      right: 3px;
      z-index: 1;
      width: 25px;
      pointer-events: none;
    }

    &:before {
      content: 'Offers Virtual Services';
      position: absolute;
      z-index: 1;
      top: 10px;
      right: 70px;
      height: 80px;
      font-style: italic;
      font-size: 13px;
      font-weight: 600;
      color: #a1a1a1;
      white-space: nowrap;
      pointer-events: none;
      opacity: 0;
      transform: translateX(20%);
      transition: all 0.25s ease-in-out;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100px;
      height: 100px;
      pointer-events: none;
      background-color: var(--g3);
      transform-origin: top right;
      transform: translateX(75%) rotate(45deg);
    }

    &:hover {
      &:before {
        opacity: 1;
        transform: translate(0);
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    padding: var(--spacer-sm) var(--spacer);
    padding-top: var(--spacer);

    article {
      margin-left: var(--spacer-sm);
      padding-right: var(--margin-lg);
    }

    .label {
      display: inline-flex;
      margin-right: var(--margin);
      margin-bottom: var(--margin);
      padding: var(--margin) var(--margin-lg);
      font-size: var(--font-size);
      line-height: 21px;
      font-weight: 600;
      color: var(--g1);
      background-color: var(--p3);
      border-radius: var(--border-radius);

      &.ppos {
        background-color: var(--p4);
      }

      &.cso {
        background-color: var(--orange);
      }

      > span {
        font-weight: 400;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px var(--p3) !important;
      }
    }

    .show-more {
      display: block;
      font-size: var(--font-size-sm);
      margin-bottom: var(--margin-lg);
    }
  }

  &__body {
    padding: var(--spacer-sm) var(--spacer);
    padding-top: var(--margin-lg);
  }

  &__section {
    padding: var(--spacer-sm) var(--spacer);
    padding-top: var(--margin-lg);
  }

  &__footer {
    position: relative;
    padding: var(--spacer-sm) var(--spacer);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: calc(var(--spacer-lg) / 2);
      width: calc(100% - var(--spacer-lg));
      height: 1px;
      background-color: #e6e6e6;
    }

    .muted {
      font-size: var(--font-size-sm);
      opacity: 0.75;
    }

    .btn {
      display: inline-block;
      margin-bottom: var(--margin);
      margin-right: var(--margin);
      font-size: 13px;
      font-family: var(--font-family-sans-serif);
    }
  }

  .debug-list {
    font-family: var(--font-family-monospace);
    font-weight: 500;
    font-size: var(--font-size-xs);

    > article {
      padding: var(--margin) 0;
      margin-bottom: var(--margin);
      background-color: var(--g2);

      &.selected {
        background-color: var(--p1);
      }
    }
  }

  &.minimized {
    pointer-events: all;
    .c-list-item__header {
      padding-top: var(--margin-lg);
      padding-bottom: var(--margin-lg);
    }
  }

  @media screen and (max-width: 428px) {
    &__sticker {
      width: 60px;
      height: 40px;

      &:before {
      }

      &:after {
        width: 80px;
        height: 80px;
      }

      svg {
        top: 2px;
        right: 2px;
        width: 20px;
      }
    }

    &__header {
      padding: 0 var(--spacer-sm);
      padding-top: var(--spacer-sm);

      .label {
        padding: 2.5px 5px;
        font-size: 13px;
        line-height: 18px;
      }
    }

    &__body {
      padding: var(--margin-lg) var(--spacer-sm);
    }

    &__section {
      padding: var(--margin-lg) var(--spacer-sm);
    }

    &__footer {
      padding: var(--margin-lg) var(--spacer-sm);

      .btn {
        line-height: 1;
        padding: var(--margin) var(--margin-lg);
      }
    }
  }
}
</style>
