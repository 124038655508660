/**
 * Cleans marker object returned by the api and makes it ready for the schema to digest it
 */
import objectId from './objectid';

import { destructurer } from './debug-utils.js';

import { deliveries } from './form-data';

const deliveriesMap = deliveries.map(d => {
  return d.value;
});

function normalizeModesOfDelivery(modes) {
  for (let i = 0; i < modes.length; i++) {
    if (modes[i] === 'Face-to face (individual)') {
      modes[i] = 'Face-to-face (individual)';
    }
    if (modes[i] === 'Face-to face (group)') {
      modes[i] = 'Face-to-face (group)';
    }
    if (modes[i] === 'Telehealth (e.g. consulting via Skype)') {
      modes[i] = 'Telehealth';
    }
    if (modes[i] === 'Telehealth (e.g. consulting via Skype) (e.g. consulting via Skype)') {
      modes[i] = 'Telehealth (e.g. consulting via Skype)';
    }
  }
  return modes;
}

function normalizeCostStructure(costs) {
  for (let i = 0; i < costs.length; i++) {
    if (costs[i] === 'Bulk bill service') {
      costs[i] = 'Bulk-bill service';
    }
    if (costs[i] === 'Bulk-bill service available') {
      costs[i] = 'Bulk-bill service';
    }

    if (costs[i] === 'Bulk bill healthcare cardholder') {
      costs[i] = 'Bulk-bill healthcare cardholder';
    }
    if (costs[i] === 'Free Service') {
      costs[i] = 'Free service';
    }
    if (costs[i] === 'Interpreter service available') {
      costs[i] = 'Interpreter service';
    }
    if (costs[i] === 'Private Health fund rebate') {
      costs[i] = 'Private Health Insurance rebate';
    }
  }

  return costs;
}

function setFilterableCosts(cost_structure, debug = false) {
  let filterable = [
    'Free service',
    'Bulk-bill service',
    'Bulk-bill healthcare cardholder',
    'Private Health fund rebate available'
  ];
  let costs = [];

  for (let i = 0; i < cost_structure.length; i++) {
    if (!filterable.includes(cost_structure[i])) {
      if (debug) {
        console.log('BREAK', cost_structure[i]);
      }
      continue;
    }

    if (!costs.includes(cost_structure[i])) {
      costs.push(cost_structure[i]);
    }
  }

  return costs;
}

function setFilterableDeliveries(modes_of_delivery, flip = false) {
  let filterable = deliveriesMap;
  let deliveries = [];

  if (!flip) {
    for (let i = 0; i < modes_of_delivery.length; i++) {
      if (!filterable.includes(modes_of_delivery[i])) {
        continue;
      }

      if (!deliveries.includes(modes_of_delivery[i])) {
        deliveries.push(modes_of_delivery[i]);
      }
    }
  } else {
    for (let i = 0; i < modes_of_delivery.length; i++) {
      if (!filterable.includes(modes_of_delivery[i])) {
        deliveries.push(modes_of_delivery[i]);
      }
    }
  }
  return deliveries;
}

function hasTelehealth(deliveries) {
  if (deliveries.includes('Telehealth')) {
    return true;
  } else {
    return false;
  }
}

function normalizeLanguages(languages) {
  return languages;
}

export default function(marker, prefix = 'location_0_') {
  /**
   * Making sure the user key is always a int and setting a unique id on it
   */
  marker.user = parseInt(marker.id);
  marker.id = objectId();

  marker.site_name = marker[`${prefix}site_name`];

  marker.address_full = marker[`${prefix}address_full`] || '';

  marker.lat = marker[`${prefix}address_lat`];
  marker.lng = marker[`${prefix}address_lng`];

  marker.interpreter_service = false;

  marker.costs = [];
  marker.costs_other = [];
  marker.costs_other_description = '';
  marker.deliveries = [];
  marker.deliveries_other = [];
  marker.languages = [];

  marker.type = marker['type'] || '';

  marker.other_service_information = marker[`${prefix}other_service_information`] || '';

  marker.other_details = marker[`${prefix}other_service_information`] || '';

  marker.atsi = false;
  if (marker.atsi_specific_services === 'Yes') {
    marker.atsi = true;
  }

  /**
   * Normalize cost_structure and set a costs key on the marker
   * This costs key is used for the actual filtering
   */
  if (marker[`${prefix}services_applicable_new`]) {
    for (let i = 0; i < marker[`${prefix}services_applicable_new`].length; i++) {
      marker.costs_other.push(marker[`${prefix}services_applicable_new`][i]);
    }
  }

  if (marker[`${prefix}services_applicable_other`]) {
    marker.costs_other_description = marker[`${prefix}services_applicable_other`];
  }

  if (marker.costs_other.length > 0) {
    marker.costs = normalizeCostStructure(marker.costs_other);
  }
  marker.costs = setFilterableCosts(marker.costs);

  if (marker.costs_other.includes('Interpreter service')) {
    marker.interpreter_service = true;
  }

  /**
   * Normalize cost_structure and set a costs key on the marker
   * This costs key is used for the actual filtering
   */

  if (marker[`${prefix}service_modes_of_delivery`]) {
    for (let i = 0; i < marker[`${prefix}service_modes_of_delivery`].length; i++) {
      marker.deliveries_other.push(marker[`${prefix}service_modes_of_delivery`][i]);
    }
  }

  if (marker.deliveries_other.includes('Not Listed...')) {
    let i = marker.deliveries_other.indexOf('Not Listed...');
    marker.deliveries_other.splice(i, 1);
  }

  /**
   * Normalize deliveries
   */
  if (marker.deliveries_other.length > 0) {
    marker.deliveries = normalizeModesOfDelivery(marker.deliveries_other);
  }
  // Replace "Mother and baby (in-patient) unit" with "Mother and baby (in-patient)"
  if(marker.deliveries.includes('Mother and baby (in-patient) unit')) {
    let i = marker.deliveries.indexOf('Mother and baby (in-patient) unit');
    marker.deliveries[i] = "Mother and baby (in-patient)";
  }
  marker.deliveries_other = setFilterableDeliveries(marker.deliveries, true);
  marker.deliveries = setFilterableDeliveries(marker.deliveries);

  /**
   * Normalize languages
   */
  if (marker[`${prefix}other_languages`]) {
    for (let i = 0; i < marker[`${prefix}other_languages`].length; i++) {
      marker.languages.push(marker[`${prefix}other_languages`][i]);
    }
  }
  marker.languages = normalizeLanguages(marker.languages);

  /**
   * Sets telehealth flag based on whether telehealh is part of deliveries or not
   */
  marker.telehealth = hasTelehealth(marker.deliveries);

  marker.phone = marker[`${prefix}different_contact_details_phone`] || '';
  marker.email = marker[`${prefix}different_contact_details_email`] || '';
  marker.website = marker[`${prefix}different_contact_details_website`] || '';
  marker.contact_network = marker[`${prefix}different_contact_details_network`] || '';

  return marker;
}
