import api from '../lib/api';

import payloadToData from '../lib/payload-to-data.js';

let store = {
  namespaced: true,
  state: {
    users: [],
    filteredUsers: [],
    activeUser: null
  },

  getters: {
    users: function(state) {
      return state.users;
    },

    user: function(state) {
      let users = state.users;
      return id => {
        let user = null;
        for (let i = 0; i < users.length; i++) {
          if (users[i].id === id) {
            user = users[i];
            break;
          }
        }
        return user;
      };
    },

    usersTypeCount: function(state) {
      let users = state.filteredUsers;
      let types = {};
      for (let i = 0; i < users.length; i++) {
        if (users[i].type in types) {
          types[users[i].type] += 1;
        } else {
          types[users[i].type] = 0;
        }
      }
      return types;
    },

    /**
     * Filtered users are both populated with markers and filtered too
     */
    filteredUsers: function(state) {
      return state.filteredUsers;
    },

    activeUser: function(state) {
      return state.activeUser;
    }
  },

  mutations: {
    SET: function(state, users) {
      state.users = users;
    },
    SET_FILTERED_USERS: function(state, filteredUsers) {
      state.filteredUsers = filteredUsers;
    },
    SET_ACTIVE_USER: function(state, condition) {
      state.activeUser = condition;
    }
  },

  actions: {
    async init(store) {
      await store.dispatch('get');
    },

    async get(store) {
      let rawUsers = await api.users.getRaw();

      let data = payloadToData(rawUsers);

      const trackedUser = store.rootGetters['trackedUser'];

      if (trackedUser) {
        let user = null;
        for (let i = 0; i < data.users.length; i++) {
          const u = data.users[i];
          if (u.id === trackedUser) {
            user = u;
            break;
          }
        }

        if (user) {
          const message = `Tracking user ${user.id} ${user.email}`;
          console.log('%c' + message, 'color:Orange');
          console.log(user);
        }
      }

      //console.log(`EXTRACTED PAYLOAD : users ${data.users.length}, markers ${data.markers.length}`);

      await store.dispatch('markers/set', data.markers, { root: true });

      store.commit('SET', data.users);

      await store.dispatch('populateUsers');
    },

    /**
     * Populates user array with the markers array
     * Run this whenever the original markers array inside the markers store updates
     */
    async populateUsers(store) {
      let users = store.state.users;
      let markers = store.rootGetters['markers/markers'];
      for (let i = 0; i < users.length; i++) {
        users[i].markers = [];

        for (let j = 0; j < markers.length; j++) {
          if (users[i].id === markers[j].user) {
            users[i].markers.push(markers[j]);
          }
        }
      }

      store.commit('SET', users);
    },

    async setFilteredUsers(store, filteredUsers) {
      store.commit('SET_FILTERED_USERS', filteredUsers);

      await store.dispatch('markers/renderMarkers', null, { root: true });
    },

    setActiveUser: function(store, user) {
      store.commit('SET_ACTIVE_USER', user);
    }
  },
  modules: {}
};

export default store;
