<template>
  <div class="c-radiobox-group" :id="cssId">
    <Radiobox v-model="currentValue" :items="currentItems"></Radiobox>
  </div>
</template>

<script>
import slugify from '@/lib/slugify.js';
import Radiobox from './radiobox.vue';

function getValues(currentValues, items) {
  for (let i = 0; i < items.length; i++) {
    if (!items[i].items) {
      currentValues.push(items[i].value);
    } else {
      currentValues = currentValues.concat(getValues(currentValues, items[i].items));
    }
  }

  return currentValues;
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default {
  components: {
    Radiobox,
  },

  data: function() {
    return {
      currentValue : null
    };
  },

  watch: {
    currentValue: {
      handler: function(val) {
        this.update();
      },
      deep: true
    }
  },

  props: {
    value: {
      type: Array
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    cssId: {
      type: String,
      default: ''
    }
  },

  computed: {
    topLevelItem: function() {
      return {
        key: 'All Topics',
        value: 'All Topics',
        items: this.items
      };
    },
    currentItems: function() {
      return this.items;
    },
    topLevelTitle: function() {
      return 'All Topics';
    }
  },

  methods: {
    slugify: function(str) {
      return slugify(str);
    },
    update: function() {
      this.$emit('input', this.currentValue);
    },
    onChange: function(e) {
      let checked = e.target.checked;
      let allValues = getValues([], this.items);
      allValues = allValues.filter(onlyUnique);
      if (checked) {
        this.currentValues = allValues;
      } else {
        this.currentValues = [];
      }
    },
    onIndeterminateCheckboxChange: function(values, checked) {
      let allValues = getValues([], this.items);
      if (checked) {
        this.currentValues = allValues;
      } else {
        this.currentValues = [];
      }
    },
    /**
     * Not used directly by this component but can be used externally
     */
    checkAll: function() {
      let allValues = getValues([], this.items);
      allValues = allValues.filter(onlyUnique);
      this.currentValues = allValues;
    },
    checkNone: function() {
      this.currentValues = [];
    }
  }
};
</script>

<style lang="scss">
.c-checkbox-group {
  .small {
    font-size: 10px;
  }

  .form-checkbox {
    margin-bottom: 9px;
  }

  .c-checkbox {
    &__list {
      margin-bottom: 9px;
    }

    .c-checkbox {
      padding-left: 12px;
      border-left: var(--g4) solid 4px;
    }
  }

  label {
    cursor: pointer;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 428px) {
    .c-checkbox__list {
      margin-bottom: var(--margin);
    }
  }
}
</style>
