<template>
  <div class="c-map">
    <Header></Header>
    <Sidebar :users="users"> </Sidebar>
    <MapView :markers="markers"> </MapView>
    <MapPopup v-if="map" :map="map"></MapPopup>
    <DebugBar v-if="debug"></DebugBar>
  </div>
</template>

<script>
import Header from './header';
import Sidebar from './sidebar';
import MapView from './map-view';
import MapPopup from './map-popup';
import DebugBar from './debug-bar';

export default {
  components: {
    Header,
    Sidebar,
    MapView,
    MapPopup,
    DebugBar
  },

  computed: {
    map: function() {
      return this.$store.getters['map/map'];
    },
    debug: function() {
      return this.$store.getters['debug'];
    },
    markers: function() {
      return this.$store.getters['markers/markers'];
    },
    users: function() {
      let users = this.$store.getters['users/filteredUsers'];

      users = users.map(user => {
        // Strongly assumes availability is a string or undefined
        let a = user.availability;

        if (a) {
          user.status = user.availability;
        } else {
          user.status = 'inactive'; // if undefined, assumes status is active
        }
        return user;
      });

      users = this.addComputedProperties(users);

      users = this.sortDistance(users);

      return users;
    }
  },

  methods: {
    sortF2f: function(users) {},
    sortDistance: function(users) {
      users = users.sort((a, b) => {
        if (a._f2f && b._f2f) {
          return a.minDistance - b.minDistance;
        } else {
          return 0;
        }
      });

      return users;
    },

    // This property is not being used right now
    addComputedProperties: function(users) {
      for (let i = 0; i < users.length; i++) {
        users[i].f2f = false;
        for (let j = 0; j < users[i].markers.length; j++) {
          if (users[i].markers[j].deliveries.includes('Face-to-face (individual)')) {
            users[i]._f2f = true;
            break;
          }
        }
      }

      return users;
    }
  }
};
</script>

<style lang="scss">
.c-map {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @media screen and (max-width: 428px) {
    height: calc(100vh - 80px);
  }
}
</style>
