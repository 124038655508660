<template>
  <div :class="['c-dropdown', id, { active: actuallyActive === true }, id]">
    <div class="c-dropdown__input" @click="onToggle">
      <slot name="input"></slot>
      <div class="c-dropdown__button">
        <span>▾</span>
        <!-- <chevron-down-icon size="1.5x"></chevron-down-icon> -->
      </div>
    </div>
    <div :class="['c-dropdown__inner', { active: actuallyActive === true }]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon } from 'vue-feather-icons';
export default {
  components: {
    ChevronDownIcon
  },

  data: function() {
    return {
      id: null,
      actuallyActive: false
    };
  },

  watch: {
    active: {
      handler: function() {
        if (this.active) {
          this.actuallyActive = true;
        } else {
          this.actuallyActive = false;
        }
      },
      immediate: true
    }
  },

  props: {
    active: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onToggle: function() {
      this.actuallyActive = !this.actuallyActive;
    },
    onClick: function(e) {
      let id = this.id;
      if (e.target.closest(`.c-dropdown.${id}`)) {
      } else {
        if (this.actuallyActive) {
          this.actuallyActive = false;
        }
      }
    },
    addEventListeners: function() {
      window.addEventListener('click', this.onClick);
    },
    removeEventListeners: function() {
      window.removeEventListener('click', this.onClick);
    }
  },

  mounted: function() {
    this.id = `vue-component-${this._uid}`;
    this.addEventListeners();
  },

  beforeDestroy: function() {
    this.removeEventListeners();
  }
};
</script>

<style lang="scss">
.c-dropdown {
  position: relative;
  //z-index: 1;
  box-sizing: border-box;
  display: inline-block;
  background-color: transparent;
  font-weight: 500;
  font-size: var(--font-size);
  width: 100%;
  min-height: 43px;
  appearance: none;
  border-radius: 0;
  border: var(--g5) solid 1px;
  margin: 0px;
  transition: all 50ms ease-out;
  cursor: pointer;

  &:focus {
    border-color: var(--focus-color);
    outline: none;
    @include focus(1px);
  }

  &-sm {
    font-size: var(--font-size-sm);
    padding: var(--margin-sm) var(--margin-lg);
    height: calc(var(--spacer-lg) / 2);
  }

  &--full {
    display: block;
    width: 100%;
  }

  &__input {
    width: 100%;
    height: 41px;
    display: flex;
    align-items: center;
    background-color: var(--g1);

    > span {
      width: 100%;
      padding: 8px 16px;
    }
  }

  &__button {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 43px;
    height: 100%;
    user-select: none;
    background-color: var(--p1);
    border-left: var(--g5) solid 1px;
    border-bottom: transparent solid 1px;
    color: var(--p4);

    > span {
      font-family: var(--font-family-serif);
      font-size: 26px;
    }
  }

  &__inner {
    position: absolute;
    top: 100%;
    left: -1px;
    width: calc(100% + 2px);
    height: 0;
    opacity: 0;

    overflow: hidden;
    background-color: var(--g1);
    border-left: var(--p4) solid 1px;
    border-right: var(--p4) solid 1px;
    border-bottom: var(--p4) solid 1px;
    transition: all var(--transition-time) ease-out;

    > span {
      padding: 10px 16px;
      display: block;
      cursor: pointer;

      &:hover {
        background-color: var(--p1);
      }

      &.active {
        font-weight: 600;
        background-color: var(--p1);
      }
    }

    &.active {
      z-index: 1;
      opacity: 1;
      height: auto;
      max-height: 270px;
      overflow-y: auto;
    }
  }

  &:active,
  &:focus,
  &:hover,
  &.active {
    border-color: var(--p4);

    .c-dropdown__button {
      border-left-color: var(--p4);
    }
  }

  &.active {
    .c-dropdown__button {
      border-bottom-color: var(--p4);
    }
  }
}
</style>
