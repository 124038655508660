<template>
  <div class="c-radiobox">
    <div class="c-radio__list" v-for="item in items" :key="item.value">
      <!-- List radiobox -->
      <div class="form-radiobox" v-if="!item.items">
        <input
          type="radio"
          name="radiobox"
          v-model="currentValue"
          :value="item.value"
          :id="slugify(item.value + '-radiobox')"
          @change="test"
        />
        <label :for="slugify(item.value + '-radiobox')">
          {{ item.key }} <span class="debug-label" v-if="debug">{{ item.value }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import slugify from '@/lib/slugify.js';

export default {
  name: 'Radiobox',

  components: {
  },

  data: function() {
    return {
      currentValue: null
    };
  },

  props: {
    value: {
      type: String
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  watch: {
    value: {
      handler: function() {
        this.currentValue = this.value;
      },
      immediate: true
    },
    currentValue: {
      handler: function(val) {
        this.update();
      },
    }
  },

  computed: {
    debug: function() {
      return this.$store.getters['debug'];
    }
  },

  methods: {
    test: function(e) {
    },
    update: function(value) {
      this.$emit('input', this.currentValue);
    },
    findItem: function(value) {},
    slugify: function(str) {
      return slugify(str);
    }
  }
};

</script>

<style lang="scss">
.c-radiobox {
  cursor: pointer;
  .debug-label {
    display: block;
    font-family: 'Jetbrains Mono', monospace;
    font-size: var(--font-size-xs);
    color: #000;
  }

  .form-radiobox {
    margin:0;

    label {

      width:100%;
      
      padding: 10px 16px;
      padding-left:32px;

      margin:0;

      cursor:pointer;

      &:after {
        top:12px;
        left:var(--margin);
      }
      &:before {
        top:12px;
        left:var(--margin);
      }

      &:hover {
        background-color: var(--p1);
      }
    }
  }
}
</style>
