<template>
  <div :class="['c-map-view', { active: ready === true }]" ref="mapComponent">
    <GmapMap
      :center="{ lat: 10, lng: 10 }"
      :zoom="7"
      map-type-id="terrain"
      :options="mapOptions"
      :style="mapStyle"
      ref="mapRef"
    >
    </GmapMap>
    <!-- <MapPopup v-if="map" :map="map"></MapPopup> -->
    <template v-if="ready">
      <div :class="['c-map-view__screen', { active: showScreen === true }]"></div>
      <MapViewPopup v-if="showMapPopup" @close="showMapPopup = false"></MapViewPopup>
    </template>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';

import { mapStyle, getPins } from './map-style';
import MapPopup from './map-popup';
import store from '@/store';

import MapViewPopup from './map-view-popup';

let component = null;

/**
 * Hack to use self scoped vue methods with google maps marker's click event
 * This function basically passed everything to the vue component's onMarkerClick method
 */
function onMarkerClick(e) {
  let self = this;
  let id = self.get('id');
  store.dispatch('markers/setMarker', id);
  //component.methods.onMarkerClick(e, self);
}

component = {
  components: {
    MapPopup,
    MapViewPopup
  },

  data: function() {
    return {
      width: 100,
      height: 100,
      zoom: 15,
      pins: {},
      renderedMarkers: [],
      circle: null,
      showMapPopup: false
    };
  },

  props: {
    markers: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  watch: {
    loaded: {
      handler: function() {
        if (this.loaded) {
          this.onResize();
        }
      }
    },
    currentMarker: {
      handler: function() {
        if (this.currentMarker) {
          this.setMapPosition();
        }
      }
    },
    ready: function() {
      if (this.ready) {
        this.$nextTick(() => {
          this.onResize();
        });
      }
    },
    filteredUsers: function() {
      if (this.hasNoPhysicalResults) {
        this.showMapPopup = true;
      } else {
        this.showMapPopup = false;
      }
    }
  },

  computed: {
    filteredUsers: function() {
      return this.$store.getters['users/filteredUsers'];
    },
    hasNoPhysicalResults: function() {
      return this.$store.getters['map/hasNoPhysicalResults'];
    },
    showScreen: function() {
      if (this.disableMap || this.showMapPopup) {
        return true;
      } else {
        return false;
      }
    },

    disableMap: function() {
      return this.$store.getters['map/disableMap'];
    },
    ready: function() {
      return this.$store.getters['ready'];
    },
    map: function() {
      return this.$store.getters['map/map'];
    },
    google: function() {
      return gmapApi();
    },
    mapStyle: function() {
      let width = this.width;
      let height = this.height;
      let style = `width:${width}px;height:${height}px;`;

      return style;
    },
    gMapStyle: function() {
      return mapStyle;
    },
    gMapMarkers: function() {
      return this.$store.getters['markers/gMapMarkers'];
    },

    mapOptions: function() {
      let obj = {
        center: { lat: -28.5, lng: 133.75 },
        zoom: 5,
        disableDefaultUI: true,
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        styles: this.gMapStyle
      };
      return obj;
    },
    currentMarker: function() {
      return this.$store.getters['markers/currentMarker'];
    },
    loaded: function() {
      return this.$store.getters['loaded'];
    }
  },

  methods: {
    showMarker: function() {
      return false;
    },
    /**
     * Called by mappopup component
     * Pass a valid marker object
     */
    getMarkerPosition: function(marker) {
      let id = marker.id;
      let renderedMarker = null;
      let renderedMarkers = this.renderedMarkers;

      for (let i = 0; i < renderedMarkers.length; i++) {
        let currentId = renderedMarkers[i].get('id');
        if (currentId === id) {
          renderedMarker = renderedMarkers[i];
          break;
        }
      }

      return renderedMarker;
    },
    setMapPosition: function() {
      let map = this.map;
      let marker = this.currentMarker;
      let zoom = this.zoom;
      let position = marker.position;

      map.setZoom(zoom);
      map.setCenter(position);

      /**
       * we also offset center by 150px so the popup has enough area to render
       * This can be improved in the future
       */
      map.panBy(0, -150);
    },
    async getPositionFromPostcode(postcode) {
      let geocoder = new this.google.maps.Geocoder();
      let address = `${postcode}, Australia`;
      let details = await geocoder.geocode({ address: address });
      return details;
    },
    onResize: function() {
      let rect = this.$refs.mapComponent.getBoundingClientRect();
      this.width = rect.width;
      this.height = rect.height;
    },
    async setup() {
      let map = await this.$refs.mapRef.$mapPromise;

      /**
       * Populates store with all the googley variables
       */
      this.$store.dispatch('map/updateMapVariables', {
        map: map,
        google: gmapApi()
      });

      /**
       * Tells store to generate google map markers(done only once - hidden by default)
       */
      this.$store.dispatch('markers/setMapMarkers');

      // Flipping this var to true so everyone else knows gmaps is ready for business
      this.$store.dispatch('hasLoaded');

      window.addEventListener('resize', this.onResize);
    },
    dissamble: function() {
      window.removeEventListener('resize', this.onResize);
    }
  },

  mounted: function() {
    this.setup();
  },

  beforeDestroy: function() {
    this.dissamble();
  }
};

export default component;
</script>

<style lang="scss">
.c-map-view {
  position: absolute;
  top: var(--header-height);
  left: var(--sidebar-width);
  width: calc(100% - var(--sidebar-width));
  height: 100%;
  //height: calc(100% - var(--header-height) - var(--footer-height));

  overflow: hidden;

  width: 100%;
  height: 100%;
  left: 0;

  pointer-events: none;

  &__screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--transition-time-sm) linear;

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.active {
    left: var(--sidebar-width);
    width: calc(100% - var(--sidebar-width));
    height: calc(100% - var(--header-height));
    pointer-events: all;
  }

  .cluster {
    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 428px) {
    left: 0 !important;
    width: 100% !important;
  }
}
</style>
