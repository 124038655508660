<template>
  <div class="c-input-copy">
    <input class="form-control" type="text" :value="computedText" />
    <button class="btn" @click="onClick">{{ copyText }}</button>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      copyText: 'Copy',
      timeout: false
    };
  },

  props: {
    id: {}
  },

  computed: {
    computedText: function() {
      let id = this.id;

      let params = new URLSearchParams(location.search.substring(1));

      if (id && typeof id === 'string' && id.includes('user')) {
        let user_id = id.split(':')[1];
        params.set('user_id', user_id);
      }

      if (id && typeof id === 'string' && id.includes('marker')) {
        let marker_id = id.split(':')[1];
        params.set('marker_id', marker_id);
      }

      let str = params.toString();

      let url = window.location.href.split('?')[0];

      url = `${url}?${str}`;

      return url;
    }
  },

  methods: {
    onClick: function() {
      this.copy(this.computedText);

      if (this.timeout) {
        return;
      }

      this.copyText = 'Copied!';
      this.timeout = true;

      setTimeout(() => {
        this.copyText = 'Copy';
        this.timeout = false;
      }, 2000);
    },

    copy: function(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
        return;
      }
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
    }
  }
};
</script>

<style lang="scss">
.c-input-copy {
  display: inline-flex;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  justify-content: center;
  margin-bottom: var(--margin);
  border: var(--g5) solid 1px;
  border-top-right-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);

  input {
    flex-grow: 1;
    border: none;
    border-right: var(--g5) solid 1px;
    font-size: var(--font-size-sm);
    font-family: var(--font-family-monospace);
  }

  .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
