<template>
  <div class="c-modal-intro">
    <vue-final-modal
      :name="options.name"
      :value="options.active"
      :ssr="true"
      :lock-scroll="true"
      :hide-overlay="false"
      :click-to-close="true"
      :esc-to-close="false"
      :prevent-click="false"
      :attach="false"
      transition="vfm"
      overlay-transition="vfm"
      :z-index-auto="true"
      :z-index-base="1000"
      :z-index="false"
      :focus-retain="true"
      :focus-trap="false"
      :fit-parent="true"
      :drag="false"
      drag-selector=""
      :keep-changed-style="false"
      :resize="false"
      :resize-directions="['t', 'tr', 'r', 'br', 'b', 'bl', 'l', 'tl']"
      :min-width="0"
      :min-height="0"
      :max-width="Infinity"
      :max-height="Infinity"
      @closed="onClose"
    >
      <div ref="modalContent">
        <div class="c-modal-intro__header">
          <Logo></Logo>
        </div>
        <div class="stage1" v-if="stage === 1">
          <h1>Welcome</h1>
          <p>Welcome to the e-COPE Directory.</p>

          <p>
            How to identify availability of services?
          </p>

          <p>
            Below is a display of the different indicators of waitlist times, health professionals
            and perinatal services The availability status will determine the order results are
            shown to you.
          </p>

          <p>
            You can then click on the Show Results button to reveal broad results, OR you can
            further Refine Search to include other specific requirements (eg. type of service, cost
            structure, language requirements).
          </p>

          <p>You can change your preferences at any time to clear, modify or refine your search.</p>
          <a class="btn btn-primary btn-lg" @click="onNextStage"> Begin Search </a>
        </div>
        <div class="stage2" v-if="stage === 2">
          <h1 class="h2">Begin Search</h1>
          <FormSearch @onSubmit="onNextStage"></FormSearch>
        </div>
        <div class="stage3" v-if="stage === 3">
          <div class="editor">
            <h2 class="search__heading">How to identify availability of services</h2>
            <p>
              Below is a display of the different indicators of waitlist times for health
              professionals and perinatal services.
            </p>
            <p>The availability status will determine the order results are shown to you.</p>
            <ol class="availability-explainer">
              <li>
                <span>
                  <div class="c-avatar">
                    <img
                      src="https://www.cope.org.au//wp-content/uploads/ultimatemember/4711/stream_photo_4a1b6acf_99a863aac0e04f867e3652742b95e7e4d73206ab.jpg"
                    />
                  </div>
                  <h3>
                    <div class="c-status available"></div>
                    Available
                  </h3>
                </span>
                <p>
                  These listings have indicated there is no waitlist time and the service is
                  immediately available to take appointments.
                </p>
              </li>
              <li>
                <span>
                  <div class="c-avatar">
                    <img
                      src="https://www.cope.org.au//wp-content/uploads/ultimatemember/2422/stream_photo_57ff755e_724560bf8564361e22f4850b95a898316a24ee66.jpg"
                    />
                  </div>
                  <h3>
                    <div class="c-status long-wait-times"></div>
                    Long wait times
                  </h3>
                </span>
                <p>
                  These listings have indicated that they currently have longer than usual wait
                  times (anywhere between 2 to 8 weeks).
                </p>
              </li>
              <li>
                <span>
                  <div class="c-avatar">
                    <img
                      src="https://www.cope.org.au//wp-content/uploads/ultimatemember/860/stream_photo_413fa0d2_de4f635668c3b58b151b15d327d65578d4d202d3.jpg"
                    />
                  </div>
                  <h3>
                    <div class="c-status unavailable"></div>
                    Unavailable
                  </h3>
                </span>
                <p>
                  These listings indicated that their service is unavailable and not currently
                  accepting new appointments.
                </p>
              </li>
              <li>
                <span>
                  <div class="c-avatar">
                    <img
                      src="https://www.cope.org.au//wp-content/uploads/ultimatemember/820/stream_photo_38eb4d87_30c914528b717fb035a4b208cdcdb2d22d900553.jpg"
                    />
                  </div>
                  <h3>
                    <div class="c-status inactive"></div>
                    Inactive
                  </h3>
                </span>
                <p>
                  These listings have not updated their availability status recently and are
                  therefore categorised as inactive.
                </p>
              </li>
            </ol>
          </div>
          <hr />
          <div class="inline-form">
            <input
              v-model="form.explainer"
              class=""
              type="checkbox"
              required="required"
              id="dismiss_24explainer"
              tabindex="0"
            />
            <label class="form__label" for="dismiss_24explainer"
              >Don't show this message again for 24 hours.</label
            >
          </div>
          <a class="btn btn-primary btn-lg" @click="onNextStage">Next</a>
        </div>
        <div class="stage3" v-if="stage >= 4">
          <div
            class="
              search__step search__step--3 search__step--disclaimer
              slick-slide slick-current slick-active
            "
            data-slick-index="2"
            aria-hidden="false"
            style=""
            tabindex="0"
          >
            <h2 class="search__heading">Disclaimer</h2>
            <div class="editor">
              <p>
                Information about services offered on this directory is provided by individual
                Health Practitioners and/or their organisations. Any party registered on the e-COPE
                Directory has provided COPE with a declaration that they are competent and/or
                professionally trained in the therapeutic services they have listed. Registration
                with the e-COPE Directory is self-directed and COPE is guided by the National
                Perinatal Mental Health Guideline in approving registered parties.
              </p>
              <p>
                By agreeing, I have read the advice above and understand information about services
                offered on this directory is provided by individual Health Practitioners and/or
                their organisations, and COPE cannot vouch for the services provided.
              </p>
            </div>
            <hr />
            <div class="inline-form">
              <input
                v-model="form.disclaimer"
                class=""
                type="checkbox"
                required="required"
                id="dismiss_24hrs"
                tabindex="0"
              />
              <label class="form__label" for="dismiss_24hrs"
                >Don't show this disclaimer again for 24 hours.</label
              >
            </div>
            <div class="buttons">
              <a class="btn btn-secondary btn-lg" @click="onEnd"> I Agree </a>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import smoothReflow from 'vue-smooth-reflow';
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal';
import Logo from '@/components/global/logo';

import FormSearch from './forms/form-search';

export default {
  mixins: [smoothReflow],

  components: {
    Logo,
    VueFinalModal,
    ModalsContainer,
    FormSearch
  },

  data: function() {
    return {
      options: {
        name: 'modal-intro',
        active: false
      },
      form: {
        explainer: false,
        disclaimer: false
      },
      stage: 1
    };
  },

  watch: {
    stage: function() {
      this.checkStage();
    },
    introTrigger: {
      handler: function() {
        if (this.showIntro) {
          this.options.active = true;
        } else {
          this.options.active = false;
        }
      },
      immediate: true
    }
  },

  computed: {
    introTrigger: function() {
      return this.$store.getters['introTrigger'];
    },
    showIntro: function() {
      return this.$store.getters['showIntro'];
    },
    ready: function() {
      return this.$store.getters['ready'];
    }
  },

  methods: {
    async checkStage() {
      if (this.stage >= 3) {
        let condition = await this.$store.dispatch('checkExplainer');

        if (!condition) {
          return;
        }

        // if condition is true, reset stage to 2, dont show the disclaimer and close the modal and trigger 'close' event
        this.stage = 2;
        this.options.active = false;
      }
      if (this.stage >= 4) {
        let condition = await this.$store.dispatch('checkDisclaimer');

        if (!condition) {
          return;
        }

        // if condition is true, reset stage to 2, dont show the disclaimer and close the modal and trigger 'close' event
        this.stage = 2;
        this.options.active = false;
      }
    },
    onNextStage: function() {
      this.stage += 1;
    },
    onEnd: function() {
      if (this.form.disclaimer) {
        this.$store.dispatch('setDisclaimer');
      }
      if (this.form.explainer) {
        this.$store.dispatch('setExplainer');
      }
      this.options.active = false;
    },
    onClick: function() {
      this.options.active = false;
    },
    onClose: function() {
      this.$store.dispatch('hasReady');
      this.$emit('close');

      // so the changed stage's content change doesn't appear until after the fade out
      setTimeout(() => {
        this.stage = 1;
      }, 150);
    }
  },

  mounted() {
    this.$smoothReflow({
      el: this.$refs.modalContent,
      transition: 'height 500ms ease-in-out, width 500ms ease-in-out'
    });
  }
};
</script>

<style lang="scss">
.c-modal-intro {
  .vfm {
    overflow: scroll;
  }
  .vfm__overlay {
    background-color: transparent !important;
    backdrop-filter: blur(5px);
  }
  .vfm__container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--spacer);
  }
  .vfm__content {
    max-width: 1200px;
    padding: var(--spacer-lg);
    background-color: var(--g1);
    @include modalStyles();
    border-radius: var(--border-radius-lg);
    text-align: center;
  }

  .availability-explainer {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 3rem 0 0;
      padding: 0 1.5rem;
      width: 25%;
    }

    .c-avatar {
      margin: -2.25rem 0 0.75rem;
    }

    h3 .c-status {
      margin-right: 4px;
    }
  }

  //disclaimer text div
  .editor {
    line-height: 1.6;
  }

  strong {
    color: var(--s3);
  }

  p {
    margin-bottom: var(--spacer-sm);
  }

  .btn {
    display: inline-block;
    margin-top: var(--spacer-sm);
  }

  .inline-form {
    display: flex;
    justify-content: center;
    align-items: center;

    label {
      margin: 0;
      margin-left: var(--margin-lg);
    }
  }

  &__header {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--header-height);
    padding: var(--margin-lg) var(--spacer-sm);

    align-items: center;

    background-color: var(--p4);
    justify-content: flex-start;

    svg {
      display: block;
      margin: 0;
      width: auto;
      height: 100%;
    }
  }

  @media screen and (max-width: 428px) {
    .vfm__container {
      align-items: flex-start;
      padding: 0;
      margin-top: var(--header-height);
    }
    .vfm__content {
      padding: var(--spacer) var(--spacer-sm);
    }

    .vfm__overlay {
      z-index: -1;
    }

    .vfm__content {
      width: 100%;
      max-width: 100%;
      border-radius: 0;
    }

    .availability-explainer {
      flex-direction: column;

      li {
        display: flex;
        width: 100%;
        margin: 0;
        align-items: flex-start;
        padding: 0 0.5rem;
        padding-bottom: 0.5rem;

        > span {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 70px;
          min-width: 70px;

          h3 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 0.75rem;
            margin-top: 0.25rem;

            .c-status {
              margin-right: 0;
            }
          }
        }

        .c-avatar {
          margin: 0;
        }

        p {
          padding-left: 1rem;
          text-align: left;
        }
      }
    }
  }
}
</style>
