<template>
  <div class="c-form-filter">
    <!-- <pre v-if="debug" style="background-color: var(--g1)">
      {{ form }}
    </pre> -->
    <form @submit.prevent="onSubmit">
      <div class="c-form-filter__body">
        <Accordion
          :active="true"
          title="Postcode or Suburb"
          description="If the suburb existing in other States, please also specify which State"
        >
          <div>
            <vue-slider
              tooltip="none"
              :contained="true"
              :adsorb="true"
              :min="1"
              :max="50"
              v-model="form.radius"
              v-if="form.radius"
            />
            <div class="radius-filter-text">
              <p>
                Within
                <input v-model="form.radius" class="form-control" type="number" min="1" max="50" />
                km of
              </p>
              <input
                v-model="form.postcode"
                class="form-control"
                type="text"
                minlength="1"
                :required="true"
              />
            </div>
          </div>
        </Accordion>

        <Accordion
          title="Perinatal Stage"
          description="Please indicate current stage/status in perinatal journey."
          :minHeight="true"
        >
          <Dropdown ref="dropdownHelpFor">
            <template v-slot:input>
              <span>{{ helpForTitle }}</span>
            </template>
            <template>
              <span
                v-for="status in statuses"
                :key="status.value"
                :class="[{ active: status.value === form.help_for }]"
                @click="onStatusClick(status.value)"
              >
                {{ status.key }}
              </span>
            </template>
          </Dropdown>
          <Checkbox
            ref="perinatalCheckboxGroup"
            v-if="form.help_for"
            v-model="form.expertise"
            :items="stages[form.help_for]"
            :topLevelToggle="form.help_for !== 'grief_and_loss'"
          ></Checkbox>
        </Accordion>

        <Accordion
          title="Modes of Delivery"
          description="Please indicate your preference for how the service is delivered"
        >
          <CheckboxGroup v-model="form.deliveries" :items="deliveries"> </CheckboxGroup>
        </Accordion>

        <Accordion
          title="Type of Help"
          description="Please indicate the nature of the intervention you are seeking. You can further specify for the type of health professional."
        >
          <Checkbox v-model="form.profession" :items="professions"></Checkbox>
        </Accordion>

        <Accordion title="Cost Structure">
          <CheckboxGroup v-model="form.costs" :items="costs"> </CheckboxGroup>
        </Accordion>

        <!-- <Accordion title="Additional Services" description="Please indicate your preference for how the service is delivered">
        <CheckboxGroup v-model="form.services" :items="services"> </CheckboxGroup>
      </Accordion> -->

        <Accordion title="Additional Requirements">
          <div class="form-checkbox">
            <input
              type="checkbox"
              name="interpreter_service"
              v-model="form.interpreter_service"
              id="interpreter_service"
            />
            <label for="interpreter_service"> Interpreter service </label>
          </div>
          <div class="form-checkbox">
            <input type="checkbox" name="atsi" v-model="form.atsi" id="atsi" />
            <label for="atsi">Aboriginal and Torres Strait Islander services</label>
          </div>
        </Accordion>

        <Accordion title="Languages Offered">
          <CheckboxGroup v-model="form.languages" :items="languages"> </CheckboxGroup>
        </Accordion>
      </div>
      <FormFooter :disclaimer="true" @onBack="onBack"></FormFooter>
    </form>
  </div>
</template>

<script>
import Dropdown from '@/components/global/dropdown';
import Accordion from '@/components/global/accordion';
import CheckboxGroup from '@/components/map/checkbox-group';
import FormFooter from '@/components/global/form-footer';

import Checkbox from '@/components/checkbox';

import {
  statuses,
  stages,
  deliveries,
  professions,
  costs,
  services,
  languages
} from '@/lib/form-data.js';

import slugify from '@/lib/slugify.js';

import VueSlider from 'vue-slider-component';

export default {
  components: {
    Dropdown,
    Accordion,
    CheckboxGroup,
    VueSlider,
    FormFooter,
    Checkbox
  },

  data: function() {
    return {
      statuses: statuses,
      stages: stages,
      deliveries: deliveries,
      professions: professions,
      costs: costs,
      services: services,
      languages: languages,

      // gets populated by the watcher
      form: {}
    };
  },

  watch: {
    filter: {
      handler: function() {
        this.form = this.setupForm(this.filter);
      },
      immediate: true
    }
  },

  computed: {
    helpForTitle: function() {
      if (!this.form.help_for) {
        return '';
      }

      let str = '';

      for (let i = 0; i < this.statuses.length; i++) {
        if (this.statuses[i].value === this.form.help_for) {
          str = this.statuses[i].key;
          break;
        }
      }

      return str;
    },
    debug: function() {
      return this.$store.getters['debug'];
    },
    filter: function() {
      return this.$store.getters['map/filter'];
    }
  },

  methods: {
    onStatusClick: function(value) {
      this.form.help_for = value;
      this.$refs.dropdownHelpFor.onToggle();

      // We do this cuz the perinatal ref won't be available without it
      this.$nextTick(() => {
        this.$refs.perinatalCheckboxGroup.checkAll();
      });
    },
    setupForm: function(filter) {
      let form = JSON.parse(JSON.stringify(filter));

      // form.service_modes_of_delivery = form.service_modes_of_delivery || [];
      // if (form.service_modes_of_delivery.length === 0) {
      //   let services = this.deliveries.map(item => {
      //     return item.value;
      //   });
      //   form.service_modes_of_delivery = services;
      // }

      return form;
    },
    slugify: function(str) {
      return slugify(str);
    },
    async onSubmit() {
      let form = this.form;
      await this.$store.dispatch('map/updateFilter', form);

      this.$emit('submit');
    },
    onBack: function() {
      this.$emit('back');
    }
  }
};
</script>

<style lang="scss">
.c-form-filter {
  height: 100%;

  > form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .c-dropdown {
  }

  .c-checkbox-group {
    margin: var(--spacer-sm) 0;
  }

  &__body {
    padding: var(--margin-lg) var(--spacer);
  }

  &__footer {
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: var(--spacer-sm) var(--spacer);
  }

  &__disclaimer {
    padding: var(--margin-lg) var(--spacer);
    background-color: var(--warning-color);
  }

  .vue-slider {
    margin-top: var(--margin-lg);
    margin-bottom: var(--spacer-sm);
    .vue-slider-process {
      background-color: var(--p3) !important;
    }

    .vue-slider-dot-handle {
      background-color: var(--p3);
      border-color: var(--p3) !important;

      &-focus {
        box-shadow: none;
      }
    }
  }

  .radius-filter-text {
    display: flex;
    align-items: center;
    margin-bottom: var(--margin-lg);

    > p {
      margin-bottom: 0;

      > input {
        margin: 0 var(--margin);
      }
    }

    > input {
      margin-bottom: 0;
      margin-left: var(--margin);
      flex-grow: 1;
    }
  }

  @media screen and (max-width: 428px) {
    &__body {
      padding: var(--margin-lg) var(--spacer-sm);
    }

    &__buttons {
      padding: var(--margin-lg) var(--spacer-sm);
    }
  }
}
</style>
