import http from './http.js';
import Cookies from 'js-cookie';

let api = {
  markers: {
    async get() {
      let markers = [];
      let response = await http.get('markers.json');
      if (response.status === 200 && response.data) {
        markers = JSON.parse(response.data);
      }
      return markers;
    }
  },

  users: {
    async get() {
      let users = [];
      let response = await http.get('users.json');
      if (response.status === 200 && response.data) {
        users = JSON.parse(response.data);
      }
      return users;
    },
    async getRaw() {
      let prodUrl = 'https://cope.org.au/wp-json/directory/v1/users';
      let devUrl = 'http://ext.cope-staging.aws.conads.com.au/wp-json/directory/v1/users';
      let rawUsers = [];
      let request = {
        url: prodUrl,
        params: {
          'x-cope-key': 'b5099227-a1df-4a97-88a0-2a5a485845ec'
        }
      };
      let response = await http(request);
      if (response.status === 200 && response.data) {
        rawUsers = response.data;
      }
      return rawUsers;
    }
  },

  app: {
    async saveSearch(form) {
      let url = 'https://cope.org.au/wp-json/directory/v1/email';

      let response = await http.post(url, form);

      if (response.status === 200 && response.data) {
        return response.data;
      } else {
        return false;
      }
    },
    async setDisclaimer() {
      let options = {
        expires: 1
      };
      Cookies.set('disclaimer', true, options);
    },
    async checkDisclaimer() {
      let condition = Cookies.get('disclaimer');
      if (condition && condition === 'true') {
        return true;
      } else {
        return false;
      }
    },
    async setExplainer() {
      let options = {
        expires: 1
      };
      Cookies.set('explainer', true, options);
    },
    async checkExplainer() {
      let condition = Cookies.get('explainer');
      if (condition && condition === 'true') {
        return true;
      } else {
        return false;
      }
    }
  }
};

export default api;
