import hasOneArrayInsideArray from './has-one-array-inside-array.js';

export default function(users, filter, trackedUser) {
  let condition = false;

  let filterExpertise = filter.expertise || [];
  if (filterExpertise.length === 0) {
    filterExpertise = null;
  }

  let filterCosts = filter.costs || [];
  if (filterCosts.length === 0) {
    filterCosts = null;
  }

  let filterDeliveries = filter.deliveries || [];
  if (filterDeliveries.length === 0) {
    filterDeliveries = null;
  }

  let filterProfessions = filter.profession || [];
  if (filterProfessions.length === 0) {
    filterProfessions = null;
  }

  let filterLanguages = filter.languages || [];
  if (filterLanguages.length === 0) {
    filterLanguages = null;
  }

  let showCount = 0; // just for debug

  /**
   * Filtering through expertises
   */
  if (filterExpertise !== null) {
    for (let i = 0; i < users.length; i++) {
      if (!users[i].show) {
        if (trackedUser && users[i].id === trackedUser.id) {
          const message = `User ${trackedUser.id} partially outed in filter-params because show flag is false, in expertise`;
          console.log('%c' + message, 'color:Orange');
        }

        continue;
      }

      if (hasOneArrayInsideArray(users[i].expertise, filterExpertise)) {
        condition = true;
      }

      // If a General Support value is present, override and show the user at all times
      if (users[i].expertise.includes('General Support')) {
        condition = true;
      }

      if (condition) {
        showCount += 1;

        users[i].show = true;
        users[i].inParam = true;
      } else {
        if (trackedUser && users[i].id === trackedUser.id) {
          const message = `User ${trackedUser.id} outed in filter-params because it doesnt have the right expertise`;
          console.log('%c' + message, 'color:Orange');
          console.log('SEARCH FOR EXPERTISE');
          console.log(filterExpertise);
          console.log('USER EXPERTISE');
          console.log(users[i].expertise);
        }

        users[i].show = false;
      }

      condition = false;
    }
  }

  /**
   * Filtering through deliveries
   */
  if (filterDeliveries !== null) {
    for (let i = 0; i < users.length; i++) {
      if (!users[i].show) {
        if (trackedUser && users[i].id === trackedUser.id) {
          const message = `User ${trackedUser.id} partially outed in filter-params because show flag is false, in deliveries`;
          console.log('%c' + message, 'color:Orange');
        }
        continue;
      }
      // Marker loop
      for (let j = 0; j < users[i].markers.length; j++) {
        if (trackedUser && users[i].id === trackedUser.id) {
          console.log(users[i].markers[j].deliveries, filterDeliveries);
          console.log('---');
        }
        if (users[i].markers[j].deliveries.length === 0) {
          continue;
        }
        
        if (hasOneArrayInsideArray(users[i].markers[j].deliveries, filterDeliveries)) {
          condition = true;
          break;
        }
      }

      if (condition) {
        showCount += 1;

        users[i].show = true;
        users[i].inParam = true;
      } else {
        if (trackedUser && users[i].id === trackedUser.id) {
          const message = `User ${trackedUser.id} outed in filter-params because it doesnt have the right deliveries`;
          console.log('%c' + message, 'color:Orange');
          console.log('SEARCH FOR DELIVERIES');
          console.log(filterDeliveries);
          console.log('USER DELIVERIES(IN MARKERS)');
          console.log(users[i].markers);
        }
        users[i].show = false;
      }

      condition = false;
    }
  }

  /**
   * Filtering through professions
   */
  if (filterProfessions !== null) {
    // If other is set, match all other professions as long as they have at least 1 profession
    if (filterProfessions.includes('Other')) {
      for (let i = 0; i < users.length; i++) {
        if (!users[i].show) {
          if (trackedUser && users[i].id === trackedUser.id) {
            const message = `User ${trackedUser.id} partially outed in filter-params because show flag is false, in professions(other)`;
            console.log('%c' + message, 'color:Orange');
          }

          continue;
        }

        if (users[i].professions.length > 0) {
          condition = true;
        }

        if (condition) {
          showCount += 1;

          users[i].show = true;
          users[i].inParam = true;
        } else {
          if (trackedUser && users[i].id === trackedUser.id) {
            const message = `User ${trackedUser.id} outed in filter-params because it doesnt have any professions`;
            console.log('%c' + message, 'color:Orange');
            console.log('SEARCH FOR PROFESSIONS');
            console.log(filterProfessions);
            console.log('USER PROFESSIONS');
            console.log(users[i].professions);
          }

          users[i].show = false;
        }

        condition = false;
      }
    } else {
      // This is the older logic. Keep this if other is NOT selected
      for (let i = 0; i < users.length; i++) {
        if (!users[i].show) {
          if (trackedUser && users[i].id === trackedUser.id) {
            const message = `User ${trackedUser.id} partially outed in filter-params because show flag is false, in professions`;
            console.log('%c' + message, 'color:Orange');
          }

          continue;
        }
        // Marker loop
        if (hasOneArrayInsideArray(users[i].professions, filterProfessions)) {
          condition = true;
        }

        if (condition) {
          showCount += 1;

          users[i].show = true;
          users[i].inParam = true;
        } else {
          if (trackedUser && users[i].id === trackedUser.id) {
            const message = `User ${trackedUser.id} outed in filter-params because it doesnt have match filter professions`;
            console.log('%c' + message, 'color:Orange');
            console.log('SEARCH FOR PROFESSIONS');
            console.log(filterProfessions);
            console.log('USER PROFESSIONS');
            console.log(users[i].professions);
          }

          users[i].show = false;
        }

        condition = false;
      }
    }
  }

  /**
   * Filtering through costs
   *
   * If no filter is set, don't run it
   */

  if (filterCosts !== null) {
    for (let i = 0; i < users.length; i++) {
      // If user is already filtered and is not meant to be shown, don't show
      if (!users[i].show) {
        if (trackedUser && users[i].id === trackedUser.id) {
          const message = `User ${trackedUser.id} partially outed in filter-params because show flag is false, in costs`;
          console.log('%c' + message, 'color:Orange');
        }

        continue;
      }

      for (let j = 0; j < users[i].markers.length; j++) {
        if (users[i].markers[j].costs.length === 0) {
          continue;
        }
        if (hasOneArrayInsideArray(users[i].markers[j].costs, filterCosts)) {
          condition = true;
        }
      }

      if (condition) {
        showCount += 1;

        users[i].show = true;
        users[i].inParam = true;
      } else {
        if (trackedUser && users[i].id === trackedUser.id) {
          const message = `User ${trackedUser.id} outed in filter-params because it doesnt have match costs`;
          console.log('%c' + message, 'color:Orange');
          console.log('SEARCH FOR COSTS');
          console.log(filterCosts);
          console.log('USER COSTS(IN MARKERS)');
          console.log(users[i].markers);
        }

        users[i].show = false;
      }

      condition = false;
    }
  }

  /**
   * Filtering through interpreter_service
   */
  if (filter.interpreter_service) {
    for (let i = 0; i < users.length; i++) {
      // If user is already filtered and is not meant to be shown, don't show
      if (!users[i].show) {
        if (trackedUser && users[i].id === trackedUser.id) {
          const message = `User ${trackedUser.id} partially outed in filter-params because show flag is false, in interpreter_service`;
          console.log('%c' + message, 'color:Orange');
        }

        continue;
      }

      for (let j = 0; j < users[i].markers.length; j++) {
        if (users[i].markers[j].interpreter_service) {
          condition = true;
          break;
        }
      }

      if (condition) {
        showCount += 1;

        users[i].show = true;
        users[i].inParam = true;
      } else {
        if (trackedUser && users[i].id === trackedUser.id) {
          const message = `User ${trackedUser.id} outed in filter-params because it doesnt have match interpreter_service`;
          console.log('%c' + message, 'color:Orange');
          console.log('SEARCH FOR INTREPRETER');
          console.log(filter.interpreter_service);
          console.log('USER INTERPRETER(IN MARKERS)');
          console.log(users[i].markers);
        }

        users[i].show = false;
      }

      condition = false;
    }
  }

  /**
   * Filtering through atsi
   */
  if (filter.atsi) {
    for (let i = 0; i < users.length; i++) {
      // If user is already filtered and is not meant to be shown, don't show
      if (!users[i].show) {
        if (trackedUser && users[i].id === trackedUser.id) {
          const message = `User ${trackedUser.id} partially outed in filter-params because show flag is false, in atsi`;
          console.log('%c' + message, 'color:Orange');
        }

        continue;
      }

      if (users[i].atsi) {
        condition = true;
      }

      // for (let j = 0; j < users[i].markers.length; j++) {
      //   if (users[i].markers[j].atsi) {
      //     condition = true;
      //     break;
      //   }
      // }

      if (condition) {
        showCount += 1;

        users[i].show = true;
        users[i].inParam = true;
      } else {
        if (trackedUser && users[i].id === trackedUser.id) {
          const message = `User ${trackedUser.id} outed in filter-params because it doesnt have match atsi filter`;
          console.log('%c' + message, 'color:Orange');
          console.log('SEARCH FOR ATSI');
          console.log(filter.atsi);
          console.log('USER ATSI');
          console.log(users[i].atsi);
        }

        users[i].show = false;
      }

      condition = false;
    }
  }

  /**
   * Filtering through languages
   */
  if (filterLanguages !== null) {
    /**
     * Remove English cuz we're in a commonwealth country and english is selected by default.
     * No need to filter by it
     */
    let english = filterLanguages.includes('English');
    let temp = [];
    if (english) {
      for (let i = 0; i < filterLanguages.length; i++) {
        if (filterLanguages[i] !== 'English') {
          temp.push(filterLanguages[i]);
        }
      }
      filterLanguages = temp;
    }

    if (filterLanguages.length > 0) {
      for (let i = 0; i < users.length; i++) {
        // If user is already filtered and is not meant to be shown, don't show
        if (!users[i].show) {
          if (trackedUser && users[i].id === trackedUser.id) {
            const message = `User ${trackedUser.id} partially outed in filter-params because show flag is false, in languages`;
            console.log('%c' + message, 'color:Orange');
          }

          continue;
        }

        /**
         * If English was in the filter, then show all results regarless of other languages as english is implied to be present in the marker.languages array
         */
        if (english) {
          condition = true;
        } else {
          for (let j = 0; j < users[i].markers.length; j++) {
            if (users[i].markers[j].languages.length === 0) {
              continue;
            }
            if (hasOneArrayInsideArray(users[i].markers[j].languages, filterLanguages)) {
              condition = true;
            }
          }
        }

        if (condition) {
          showCount += 1;
          users[i].show = true;
          users[i].inParam = true;
        } else {
          if (trackedUser && users[i].id === trackedUser.id) {
            const message = `User ${trackedUser.id} outed in filter-params because it doesnt have match language filter`;
            console.log('%c' + message, 'color:Orange');
            console.log('SEARCH FOR LANGUAGE');
            console.log(filterLanguages);
            console.log('USER LANGUAGES(IN MARKERS)');
            console.log(users[i].markers);
          }

          users[i].show = false;
        }

        condition = false;
      }
    }
  }

  /**
   * The inParam worked a bit incorrectly
   * So here we make the inParam same as show
   *
   * This means that downstream we can segregrate users via show(inside the components) but still keep inParam intact
   * for further filtering
   */
  for (let i = 0; i < users.length; i++) {
    users[i].inParam = users[i].show;

    // if (users[i].id === 3415) {
    //   console.log(users[i]);
    // }
  }

  return users;
}
