<template>
  <div class="c-checkbox-group" :id="cssId">
    <!-- No 'All Topics' -->
    <template v-if="!topLevelToggle">
      <Checkbox v-model="currentValues" :items="currentItems"></Checkbox>
    </template>

    <!-- Yes 'All topics' -->
    <template v-else>
      <div class="c-checkbox">
        <div class="c-checkbox__list">
          <!-- List checkbox -->

          <div class="c-indeterminate-checkbox">
            <div class="form-checkbox">
              <input
                type="checkbox"
                ref="checkbox"
                name="checkbox"
                :id="slugify(topLevelItem.value)"
                @change="onChange($event)"
              />
              <label :for="slugify(topLevelItem.value)">
                {{ topLevelItem.key }}
              </label>
            </div>
          </div>

          <!-- <IndeterminateCheckbox
            :item="topLevelItem"
            :values="currentValues"
            :topLevel="true"
            @onChange="onIndeterminateCheckboxChange"
          ></IndeterminateCheckbox> -->

          <Checkbox v-model="currentValues" :items="currentItems"></Checkbox>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import slugify from '@/lib/slugify.js';
import Checkbox from './checkbox.vue';
import IndeterminateCheckbox from './indeterminate-checkbox.vue';

function getValues(currentValues, items) {
  for (let i = 0; i < items.length; i++) {
    if (!items[i].items) {
      currentValues.push(items[i].value);
    } else {
      currentValues = currentValues.concat(getValues(currentValues, items[i].items));
    }
  }

  return currentValues;
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default {
  components: {
    Checkbox,
    IndeterminateCheckbox
  },

  data: function() {
    return {
      currentValues: []
    };
  },

  watch: {
    currentValues: {
      handler: function(val) {
        if (this.topLevelToggle) {
          this.updateTopLevelCheckbox();
        }
        this.update();
      },
      deep: true
    }
  },

  props: {
    value: {
      type: Array
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    topLevelToggle: {
      type: Boolean,
      default: false
    },
    cssId: {
      type: String,
      default: ''
    }
  },

  computed: {
    topLevelItem: function() {
      return {
        key: 'All Topics',
        value: 'All Topics',
        items: this.items
      };
    },
    currentItems: function() {
      return this.items;
    },
    topLevelTitle: function() {
      return 'All Topics';
    }
  },

  methods: {
    updateTopLevelCheckbox: function() {
      let allValues = getValues([], this.items);
      allValues = allValues.filter(onlyUnique);
      let currentValues = this.currentValues;

      let ref = this.$refs.checkbox;

      // 30, 30
      if (allValues.length === currentValues.length && currentValues.length !== 0) {
        ref.checked = true;
        ref.indeterminate = false;
        return;
      }

      // 30, 0
      if (allValues.length !== currentValues.length && currentValues.length === 0) {
        ref.checked = false;
        ref.indeterminate = false;
        return;
      }
      // 30, variable
      if (allValues.length !== currentValues.length && currentValues.length !== 0) {
        ref.checked = false;
        ref.indeterminate = true;
        return;
      }
    },
    slugify: function(str) {
      return slugify(str);
    },
    update: function() {
      this.$emit('input', this.currentValues);
    },
    onChange: function(e) {
      let checked = e.target.checked;
      let allValues = getValues([], this.items);
      allValues = allValues.filter(onlyUnique);
      if (checked) {
        this.currentValues = allValues;
      } else {
        this.currentValues = [];
      }
    },
    onIndeterminateCheckboxChange: function(values, checked) {
      let allValues = getValues([], this.items);
      if (checked) {
        this.currentValues = allValues;
      } else {
        this.currentValues = [];
      }
    },
    /**
     * Not used directly by this component but can be used externally
     */
    checkAll: function() {
      let allValues = getValues([], this.items);
      allValues = allValues.filter(onlyUnique);
      this.currentValues = allValues;
    },
    checkNone: function() {
      this.currentValues = [];
    }
  },

  mounted: function() {
    this.currentValues = this.value;
  }
};
</script>

<style lang="scss">
.c-checkbox-group {
  .small {
    font-size: 10px;
  }

  .form-checkbox {
    margin-bottom: 9px;
  }

  .c-checkbox {
    &__list {
      margin-bottom: 9px;
    }

    .c-checkbox {
      padding-left: 12px;
      border-left: var(--g4) solid 4px;
    }
  }

  label {
    cursor: pointer;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 428px) {
    .c-checkbox__list {
      margin-bottom: var(--margin);
    }
  }
}
</style>
