/**
 * 
 * <li class="health-prof health-prof--{{ hp_user.type }} list__item" data-person="person_{{ hp_user.id }}" data-profession="{% for profession in hp_user.professions %}{% if profession == 'Not Listed...' %}Other{% elseif profession != 'Counsellor - Professionally Trained' and profession != 'Counsellor - Peer/someone with similar lived experience' %}{{ profession }}{% endif %}{% if loop.index != loop.length %}, {% endif %}{% endfor %}" data-expertise="{% for expertise in hp_user.expertise %}{% if expertise == 'None/Not Applicable' %}General Support{% else %}{{ expertise }}{% endif %}{% if loop.index != loop.length %}, {% endif %}{% endfor %}" data-type="{% for type in hp_user.type_services_offered %}{{ type }}{% if loop.index != loop.length %}, {% endif %}{% endfor %}" data-atsi="{{ hp_user.atsi_specific_services }}">

                        <div class="health-prof__header">
                            <div class="health-prof__image{% if hp_user.no_image %} no-image{% endif %}">
                                {% if 'ip' in hp_user.type %}
                                    <img src="{{ hp_user.image|resize(200, 200) }}" alt="" loading="lazy">
                                {% else %}
                                    <img src="{{ hp_user.image|letterbox(200, 200, '#ffffff') }}" alt="" loading="lazy">
                                {% endif %}
                            </div>
                            <div>
                                <h3 class="health-prof__name">{{ stripslashes(hp_user.full_name) }}</h3>
                                <span class="health-prof__profession">
                                    {% if 'ip' in hp_user.type %}
                                        {% for profession in hp_user.professions %}
                                            {% if profession == 'Not Listed...' %}{{ hp_user.profession_other }}{% else %}{{ profession }}{% endif %}{% if loop.index != loop.length %}, {% endif %}
                                        {% endfor %}
                                    {% elseif 'ppos' in hp_user.type %}
                                        Perinatal Mental Health Service
                                    {% elseif 'cso' in hp_user.type %}
                                        Community Support Organisation
                                    {% endif %}
                                </span>
                            </div>
                        </div>

                        {% if 'ip' in hp_user.type %}

                            <dl class="health-prof__details">
                            {% if hp_user.company %}
                                <dt>Company/Practice</dt>
                                <dd>{{ hp_user.company }}</dd>
                            {% endif %}
                            {% if hp_user.phone %}
                                <dt>Phone</dt>
                                <dd>{{ hp_user.phone }}</dd>
                            {% endif %}
                            {% if hp_user.email %}
                                <dt>Email</dt>
                                <dd><a href="mailto:{{ hp_user.email }}">{{ hp_user.email }}</a></dd>
                            {% endif %}
                            {% if hp_user.website %}

                                {% if '://' in hp_user.website %}
                                    {% set websiteprefix = '' %}
                                {% else %}
                                    {% set websiteprefix = 'http://' %}
                                {% endif %}

                                <dt>Website</dt>
                                <dd><a href="{{ websiteprefix ~ hp_user.website }}" target="_blank">{{ websiteprefix ~ hp_user.website }}</a></dd>
                            {% endif %}
                            </dl>
                            <dl class="health-prof__details health-prof__details--extra">
                            {% if hp_user.service_summary %}
                                <dt>Service Summary</dt>
                                <dd>{{ hp_user.service_summary }}</dd>
                            {% endif %}
                            {% if hp_user.expertise %}
                                <dt>Areas of Expertise</dt>
                                <dd class="expertise">
                                    {% for skill in hp_user.expertise %}{% if skill == 'None/Not Applicable' %}General Support{% else %}{{ skill }}{% endif %}{% if loop.index != loop.length %}, {% endif %}{% endfor %}{% if hp_user.se_other_specialist_areas_area_1_name %}, {{ hp_user.se_other_specialist_areas_area_1_name }}{% endif %}{% if hp_user.se_other_specialist_areas_area_2_name %}, {{ hp_user.se_other_specialist_areas_area_2_name }}{% endif %}{% if hp_user.se_other_specialist_areas_area_3_name %}, {{ hp_user.se_other_specialist_areas_area_3_name }}{% endif %}{% if hp_user.se_other_specialist_areas_area_4_name %}, {{ hp_user.se_other_specialist_areas_area_4_name }}{% endif %}
                                </dd>
                            {% endif %}
                            {% if hp_user.other_service_information %}
                                <dt>Other details</dt>
                                <dd>{{ hp_user.other_service_information }}</dd>
                            {% endif %}
                            {% if hp_user.aphra %}
                                <dt>APHRA Number</dt>
                                <dd>{{ hp_user.aphra }}</dd>
                            {% endif %}
                            </dl>

                        {% elseif 'ppos' in hp_user.type or 'cso' in hp_user.type %}

                            <dl class="health-prof__details">
                            {% if hp_user.phone %}
                                {% if 'cso' in hp_user.type %}
                                <dt>Point of Contact</dt>
                                {% else %}
                                <dt>Phone</dt>
                                {% endif %}
                                <dd>{{ hp_user.phone }}</dd>
                            {% endif %}
                            {% if hp_user.email %}
                                <dt>Email</dt>
                                <dd><a href="mailto:{{ hp_user.email }}">{{ hp_user.email }}</a></dd>
                            {% endif %}
                            {% if hp_user.website %}

                                {% if '://' in hp_user.website %}
                                    {% set websiteprefix = '' %}
                                {% else %}
                                    {% set websiteprefix = 'http://' %}
                                {% endif %}

                                <dt>Website</dt>
                                <dd><a href="{{ websiteprefix ~ hp_user.website }}" target="_blank">{{ websiteprefix ~ hp_user.website }}</a></dd>
                            {% endif %}
                            </dl>
                            <dl class="health-prof__details health-prof__details--extra">
                            {% if hp_user.service_summary %}
                                <dt>Service Summary</dt>
                                <dd>{{ hp_user.service_summary }}</dd>
                            {% endif %}
                            {% if hp_user.professions %}
                                <dt>Professions</dt>
                                <dd>
                                    {% for profession in hp_user.professions %}
                                        {% if profession == 'Not Listed...' %}{{ hp_user.profession_other }}{% else %}{{ profession }}{% endif %}{% if loop.index != loop.length %}, {% endif %}
                                    {% endfor %}
                                </dd>
                            {% endif %}
                            {% if hp_user.expertise %}
                                {% if hp_user.type == 'cso' %}
                                <dt>Areas of Support</dt>
                                {% else %}
                                <dt>Areas of Expertise</dt>
                                {% endif %}
                                <dd>
                                    {% for skill in hp_user.expertise %}{% if skill == 'None/Not Applicable' %}General Support{% else %}{{ skill }}{% endif %}{% if loop.index != loop.length %}, {% endif %}{% endfor %}{% if hp_user.se_other_specialist_areas_area_1_name %}, {{ hp_user.se_other_specialist_areas_area_1_name }}{% endif %}{% if hp_user.se_other_specialist_areas_area_2_name %}, {{ hp_user.se_other_specialist_areas_area_2_name }}{% endif %}{% if hp_user.se_other_specialist_areas_area_3_name %}, {{ hp_user.se_other_specialist_areas_area_3_name }}{% endif %}{% if hp_user.se_other_specialist_areas_area_4_name %}, {{ hp_user.se_other_specialist_areas_area_4_name }}{% endif %}
                                </dd>
                            {% endif %}
                            {% if hp_user.other_service_information %}
                                <dt>Other details</dt>
                                <dd>{{ hp_user.other_service_information }}</dd>
                            {% endif %}
                            </dl>
                        {% endif %}
                        <dl class="health-prof__details health-prof__details--locations">
                            <dt>Locations</dt>
                            <dd></dd>
                        </dl>
                    </li> 
 */

import { UsersIcon } from 'vue-feather-icons';

export default function(user) {
  if (!user.id) {
    return null;
  }
  if (!user.type) {
    return null;
  }
  user.id = user.id;
  user.type = user.type;
  user.full_name = user.full_name;
  user.avatar = user.image || '';
  user.company = user.company || '';
  user.phone = user.phone || '';
  user.email = user.email || '';
  user.website = user.website || '';

  user.atsi = false;

  if (user.atsi_specific_services && user.atsi_specific_services === 'Yes') {
    user.atsi = true;
  }

  if (user.website) {
    if (!user.website.includes('://')) {
      user.website = `http://${user.website}`;
    }
  }

  user.service_summary = user.service_summary || '';
  user.aphra = user.aphra || '';
  user.professions = user.professions || [];

  if (user.profession_other) {
    user.professions.push(user.profession_other);
  }

  if (user.professions.includes('Not Listed...')) {
    let i = user.professions.indexOf('Not Listed...');
    user.professions.splice(i, 1);
  }

  if (user.type === 'cso') {
    user.professions.push('Community Support');
  }

  user.category = user.type_services_offered || [];

  if (typeof user.expertise === 'object') {
    let temp = [];
    for (const prop in user.expertise) {
      if (user.expertise.hasOwnProperty(prop)) {
        temp.push(user.expertise[prop]);
      }
    }

    /**
     * Here, we are checking if the term below is present,
     * and if yes, remove it and add 'Miscarriage' and 'Stillbirth' instead of it
     */
    let excludeTerm = 'Grief and Loss (Due to Miscarriage/Stillbirth)';

    if (temp.includes(excludeTerm)) {
      // If term is present, remove it first
      const index = temp.indexOf(excludeTerm);
      if (index > -1) {
        temp.splice(index, 1);
      }

      // Then add the 2 new terms
      temp.push('Miscarriage');
      temp.push('Stillbirth');
    }
    user.expertise = temp;
  } else {
    user.expertise = user.expertise || [];
  }

  user.expertise = user.expertise.map(exp => {
    if (exp === 'None/Not Applicable') {
      exp = 'General Support';
    }

    return exp;
  });

  let count = 4;
  let expertise_other = [];
  for (let i = 1; i <= count; i++) {
    if (user[`se_other_specialist_areas_area_${i}_name`]) {
      expertise_other.push(user[`se_other_specialist_areas_area_${i}_name`]);
    }
  }
  user.expertise_other = expertise_other;

  user.other_service_information = user.other_service_information || '';

  user.service_summary = user.service_summary || '';

  // Fix professions
  //   for (let i = 0; i < user.professions.length; i++) {
  //     let temp = user.professions[i];
  //     temp = temp.toLowerCase();

  //     if (temp.includes('psychiatrist')) {
  //       console.log(user.professions[i]);

  //       if (!user.professions.includes('psychiatrist')) {
  //         user.professions.push('psychiatrist');
  //       }
  //       break;
  //     }
  //   }

  return user;
}
