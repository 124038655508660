import schemaMarker from './schema-marker.js';
import schemaUser from './schema-user.js';

import cleanMarker from './clean-marker.js';

import cleanUser from './clean-user.js';

import { destructurer, objectDestructurer } from './debug-utils.js';

/**
 * Turns raw user data retrieved from wordpress, to valid users and markers objects
 *
 */
export default function(payload) {
  let markers = [];
  let users = [];

  let marker = null;
  let user = null;
  //console.log(objectDestructurer(payload));

  //console.log(JSON.stringify(payload.slice(0, 10)));

  let count = 30;

  for (let i = 0; i < payload.length; i++) {
    marker = null;
    user = null;
    /**
     * Setup marker
     */
    // if (payload[i].location_0_site_name) {
    //   marker = schemaMarker(cleanMarker(payload[i], 'location_0_'));

    //   if (marker) {
    //     markers.push(marker);
    //   }
    // }

    for (let j = 0; j <= count; j++) {
      if (payload[i][`location_${j}_address_full`]) {
        marker = schemaMarker(
          cleanMarker(JSON.parse(JSON.stringify(payload[i])), `location_${j}_`)
        );
        if (marker) {
          markers.push(marker);
        }
      } else if (payload[i][`location_${j}_site_name`]) {
        marker = schemaMarker(
          cleanMarker(JSON.parse(JSON.stringify(payload[i])), `location_${j}_`)
        );
        if (marker) {
          markers.push(marker);
        }
      }

      marker = null;
    }

    /**
     * Setup user
     */
    let temp = cleanUser(JSON.parse(JSON.stringify(payload[i])));
    if (temp) {
      user = schemaUser(temp);
    } else {
      continue;
    }
    if (user) {
      users.push(user);
    }
  }

  for (let i = 0; i < users.length; i++) {
    let expertise = users[i].expertise || [];

    // Replace None/Not Applicable with General Support
    expertise.map(exp => {
      if (exp === 'None/Not Applicable') {
        exp = 'General Support';
      }

      return exp;
    });

    users[i].expertise = expertise;
  }

  let temp = JSON.parse(JSON.stringify(users));

  // markers.map(marker => {
  //   if (marker.user === 4337) {
  //     console.log(marker);
  //   }
  // });

  temp = temp.map(user => {
    if (user.full_name.includes('Start Talking')) {
      //console.log(user);
    }
    if (
      user.full_name.includes('PANDA - Perinatal Anxiety & Depression Australia National Helpline')
    ) {
      //console.log(user);
    }

    // if(user.id === 4337) {
    //   console.log(user);
    // }
    //
    // Merge users
    // for (let i = 0; i < user.professions.length; i++) {
    //   let temp = user.professions[i];
    //   temp = temp.toLowerCase();

    //   if (temp.includes('psychiatrist')) {
    //     console.log(user.professions[i]);
    //     console.log(user);
    //     break;
    //   }
    // }
    // if (user.professions.includes('Psychiatrist')) {
    //   console.log(user);
    // }
    return user;
  });

  //console.log(objectDestructurer(users));

  console.log('v1.2');

  return {
    markers,
    users
  };
}
