<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 448">
    <path
      fill="currentColor"
      d="M496,72H288V48a16,16,0,0,0-16-16H240a16,16,0,0,0-16,16V72H16A16,16,0,0,0,0,88v16a16,16,0,0,0,16,16H224v24a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V120H496a16,16,0,0,0,16-16V88A16,16,0,0,0,496,72Zm0,320H160V368a16,16,0,0,0-16-16H112a16,16,0,0,0-16,16v24H16A16,16,0,0,0,0,408v16a16,16,0,0,0,16,16H96v24a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V440H496a16,16,0,0,0,16-16V408A16,16,0,0,0,496,392Zm0-160H416V208a16,16,0,0,0-16-16H368a16,16,0,0,0-16,16v24H16A16,16,0,0,0,0,248v16a16,16,0,0,0,16,16H352v24a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V280h80a16,16,0,0,0,16-16V248A16,16,0,0,0,496,232Z"
      transform="translate(0 -32)"
    />
  </svg>
</template>

<script>
export default {};
</script>
