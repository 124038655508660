<template>
  <div class="c-form-search">
    <form @submit.prevent="onSubmit">
      <div class="c-form-search__row">
        <div class="form-group">
          <label>
            Postcode or Suburb
            <Tooltip
              description="If a suburb also exists in other State, please also specify which State."
            ></Tooltip>
          </label>
          <input
            v-model="form.postcode"
            class="form-control"
            type="text"
            minlength="1"
            placeholder="e.g. 3065, Fitzroy…"
            :required="true"
          />
        </div>
        <div class="form-group">
          <label>
            Current (Perinatal) status
            <Tooltip
              description="Please indicate current stage/status in perinatal journey."
            ></Tooltip>
          </label>
          <Dropdown ref="dropdownHelpFor">
            <template v-slot:input>
              <span>{{ helpForTitle }}</span>
            </template>
            <template>
              <span
                v-for="status in statuses"
                :key="status.value"
                :class="[{ active: status.value === form.help_for }]"
                @click="onStatusClick(status.value)"
              >
                {{ status.key }}
              </span>
            </template>
          </Dropdown>
        </div>
        <div class="form-group">
          <label>
            Seeking Help for
            <Tooltip description="Tailor your search to a more specific topic or topics"></Tooltip>
          </label>
          <Dropdown ref="dropdownTopics">
            <template v-slot:input>
              <span>{{ expertiseTitle }}</span>
            </template>
            <template>
              <div class="dropdown-ignore">
                <Checkbox
                  ref="perinatalCheckboxGroup"
                  v-if="form.help_for"
                  v-model="form.expertise"
                  :items="stages[form.help_for]"
                  :topLevelToggle="form.help_for !== 'grief_and_loss'"
                ></Checkbox>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="form-group">
          <label>
            Consultation type
            <Tooltip
              description="Select in-person (face-to-face) or in-person and virtual consultation"
            ></Tooltip>
          </label>
          <Dropdown ref="dropdownConsultationType">
            <template v-slot:input>
              <span>{{ consultationTypeTitle }}</span>
            </template>
            <template>
              <span
                v-for="status in consultationTypes"
                :key="status.value"
                :class="[{ active: status.value === form.consultationType }]"
                @click="onConsultationClick(status.value)"
              >
                {{ status.key }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
      <div class="hide-this">
        <Checkbox
          ref="professionCheckboxGroup"
          v-if="form.profession"
          v-model="form.profession"
          :items="professions"
        ></Checkbox>
      </div>
      <div class="c-form-search__row">
        <button type="submit" :disabled="disabled" class="btn btn-secondary btn-lg">
          View Results
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Dropdown from '@/components/global/dropdown';
import Accordion from '@/components/global/accordion';
import CheckboxGroup from '@/components/map/checkbox-group';

import {
  statuses,
  stages,
  deliveries,
  professions,
  costs,
  services,
  languages
} from '@/lib/form-data.js';

import slugify from '@/lib/slugify.js';

import VueSlider from 'vue-slider-component';

import Tooltip from '@/components/global/tooltip';

import Checkbox from '@/components/checkbox';

import Radiobox from '@/components/radiobox';

export default {
  components: {
    Dropdown,
    Accordion,
    CheckboxGroup,
    VueSlider,
    Tooltip,
    Checkbox,
    Radiobox
  },

  data: function() {
    return {
      statuses: statuses,
      stages: stages,
      deliveries: deliveries,
      professions: professions,
      costs: costs,
      services: services,
      languages: languages,

      consultationTypes: [
        {
          value: 'f2f-online',
          key: 'In-person & virtual service'
        },
        {
          value: 'f2f',
          key: 'In-person service'
        },
        {
          value: 'online',
          key: 'Virtual service'
        }
      ],

      // gets populated by the watcher
      form: {}
    };
  },

  watch: {
    filter: {
      handler: function() {
        this.form = this.setupForm(this.filter);
      },
      immediate: true
    },
    'form.help_for': function() {
      let help_for = this.form.help_for;

      if (!help_for) {
        return;
      }

      let temp = [];

      let stage = this.stages[help_for];

      stage.map(item => {
        if (item.items) {
          item.items.map(subItem => {
            temp.push(subItem.value);
          });
        } else {
          temp.push(item.value);
        }
      });

      this.form.expertise = temp;
    }
  },

  computed: {
    disabled: function() {
      if (
        this.form.postcode &&
        this.form.help_for &&
        this.form.expertise &&
        this.form.expertise.length > 0 &&
        this.form.consultationType
      ) {
        return false;
      }
      return true;
    },
    consultationTypeTitle: function() {
      let type = this.form.consultationType;
      if (!type) {
        return 'Select consultation type';
      }

      let str = '';

      for (let i = 0; i < this.consultationTypes.length; i++) {
        if (this.consultationTypes[i].value === type) {
          str = this.consultationTypes[i].key;
          break;
        }
      }

      return str;
    },
    helpForTitle: function() {
      // if (!this.form.postcode) {
      //   return 'Provide a location first';
      // }
      if (!this.form.help_for) {
        return 'Select status';
      }

      let str = 'Select status';

      for (let i = 0; i < this.statuses.length; i++) {
        if (this.statuses[i].value === this.form.help_for) {
          str = this.statuses[i].key;
          break;
        }
      }

      return str;
    },
    expertiseTitle: function() {
      let str = '0 out of 0 topics selected';

      let help_for = this.form.help_for;

      if (!help_for) {
        return str;
      }

      let count = 0;

      let stage = this.stages[help_for];

      stage.map(item => {
        if (item.items) {
          item.items.map(subItem => {
            count += 1;
          });
        } else {
          count += 1;
        }
      });

      let currentCount = this.form.expertise.length;

      str = `${currentCount} out of ${count} topics selected`;

      return str;
    },
    filter: function() {
      let filter = this.$store.getters['map/filter'];

      return filter;
    }
  },

  methods: {
    onStatusClick: function(value) {
      this.form.help_for = value;
      this.$refs.dropdownHelpFor.onToggle();

      // We do this cuz the perinatal ref won't be available without it
      this.$nextTick(() => {
        this.$refs.perinatalCheckboxGroup.checkAll();
      });
    },

    onConsultationClick: function(value) {
      this.$set(this.form, 'consultationType', value);
      //this.form.consultationType = value;
      this.$refs.dropdownConsultationType.onToggle();
    },

    /**
     * Setting default form fields in here.
     * We're setting a few fields true by default
     */
    setupForm: function(filter) {
      let form = JSON.parse(JSON.stringify(filter));

      // let temp = [];

      // this.deliveries.map((item) => {
      //   temp.push(item.value);
      // });

      // form.deliveries = temp;
      return form;
    },
    onSubmit: function(e) {
      let form = JSON.parse(JSON.stringify(this.form));

      form.consultationType = form.consultationType || '';

      if (form.consultationType === 'f2f') {
        form.deliveries = [
          'Face-to-face (individual)',
          'Face-to-face (group)',
          'Home visiting service',
          'Mother and baby (in-patient)'
        ];

        // Change sorting order here if only this is selected. Check that by checking the length of the consultationType
      }
      if (form.consultationType === 'f2f-online') {
        form.deliveries = [
          'Face-to-face (individual)',
          'Face-to-face (group)',
          'Telehealth',
          'Telephone Counselling',
          'Online mental health support',
          'Online mental health treatment',
          'Online Social Media (e.g. Facebook)',
          'SMS',
          'Home visiting service',
          'Mother and baby (in-patient)'
        ];
      }
      if (form.consultationType === 'online') {
        form.deliveries = [
          'Telehealth',
          'Online mental health support',
          'Online mental health treatment',
          'Online Social Media (e.g. Facebook)',
          'Telephone Counselling',
          'SMS'
        ];
      }
      form.languages.push('English');
      form.type = '';
      this.$store.dispatch('map/setFilter', form);
      this.$emit('onSubmit');
    }
  },

  mounted: function() {
    this.$nextTick(() => {
      this.$refs.professionCheckboxGroup.checkAll();
    });
  }
};
</script>

<style lang="scss">
.c-form-search {
  &__row {
    display: flex;
    justify-content: center;
    margin-bottom: var(--margin-lg);

    .form-group {
      min-width: 265px;
      flex: 1 1 0px;
      text-align: left;
      margin: 0 var(--margin-lg);

      input {
        width: 100%;
      }
    }
  }

  .hide-this {
    display: none;
  }

  .c-dropdown {
    &__input > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .c-checkbox-group {
    padding: var(--margin);
  }

  @media screen and (max-width: 920px) {
    &__row {
      display: block;
      width: 100%;

      .form-group {
        margin-bottom: var(--margin-lg);

        input {
          margin-bottom: 0;
        }
      }
    }

    input {
      width: 100%;
    }
  }
}
</style>
