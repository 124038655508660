export default function(users, filter, trackedUser) {
  let filterType = filter.type || '';

  /**
   * Filtering through types
   *
   * This is the only filter type that ignores previously set user.show flag
   *
   * Note that the OG behavious is bugged but we've kept it in-place for historic reasons.
   * To fix the bug, preserver user.show flag from previous filters
   */
  if (filterType) {
    for (let i = 0; i < users.length; i++) {
      if (!users[i].show) {
        if (trackedUser && users[i].id === trackedUser.id) {
          const message = `User ${trackedUser.id} outed in filter-type because show flag was false`;
          console.log('%c' + message, 'color:Orange');
        }

        continue;
      }
      if (users[i].type === filterType) {
        users[i].inType = true;
        users[i].show = true;
      } else {
        if (trackedUser && users[i].id === trackedUser.id) {
          const message = `User ${trackedUser.id} outed in filter-type because it is doesnt match the type filter(cso, etc)`;
          console.log('%c' + message, 'color:Orange');
        }

        users[i].show = false;
      }
    }
  }

  return users;
}
