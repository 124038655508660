/**
 * Filters by telehealth
 *
 * This is a unique filter and should be run at the end of all filters
 *
 * Ruleset : If telehealth flag on a marker is true, make that marker and
 * all related markers's google map marker's visible property to true
 *
 * See business rules 3. inside readme.md
 * @param {array} users
 *
 * return array
 */
export default function(users, trackedUser) {
  let distance = 0;

  let visibleCondition = false;

  let count = 0;

  /**
   * This loop loops though all filtered users and checks for the telehealth flag inside each user's markers
   *
   * This loop and the loop within it are linear. So 500 users and 1000 markers  with a slight built-in optimization which results in 1500 max total iterations
   */
  for (let i = 0; i < users.length; i++) {
    /**
     * If the user doesn't have any markers attached, break out of the loop
     * Should not happen as users have at least 1 marker attached
     */
    if (!users[i].markers || users[1].markers.length === 0) {
      if (trackedUser && users[i].id === trackedUser.id) {
        const message = `User ${trackedUser.id} outed in filter-telehealth because it doesnt have markers`;
        console.log('%c' + message, 'color:Orange');
      }

      continue;
    }

    for (let j = 0; j < users[i].markers.length; j++) {
      if (users[i].markers[j].telehealth) {
        count++;
        break;
      }
    }

    /**
     * If the user already has a true show flag BUT out of the search radius, process them, otherwise OUT them immediately
     */

    if (users[i].inParam && !users[i].inRadius) {
    } else {
      continue;
    }
    // if (users[i].show) {
    //   continue;
    // }

    visibleCondition = false;

    //console.log(users[i].inRadius);
    // if (!users[i].inRadius) {
    //   continue;
    // }

    /**
     * This is the inner loop that loop through user's markers and checks
     * whether the telehealth flag is true
     *
     * If yes, it breaks out of the loop and sets visibleCondition to true
     */
    for (let j = 0; j < users[i].markers.length; j++) {
      if (users[i].markers[j].telehealth) {
        visibleCondition = true;
        break;
      }
    }

    // fuck it, just make these two visible
    // if (users[i].email === 'info@panda.org.au') {
    //   visibleCondition = true;
    // }
    // if (users[i].email === 'admin@forwhenhelpline.org.au') {
    //   visibleCondition = true;
    // }
    // if (users[i].email === 'admin@littlesparklers.org') {
    //   visibleCondition = true;
    // }
    // if(users[i].email === 'emily.kawalilak3@health.nsw.gov.au') {
    //   visibleCondition = true;
    // }

    if (visibleCondition) {
      users[i].telehealth = true;

      // users[i].markers.map(marker => {
      //   marker.mapMarker.setVisible(true);
      // });
    } else {
    }
  }

  //console.log(count);

  return users;
}
