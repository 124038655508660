<template>
  <div class="c-list-divider">
    <p>
      {{ content }}
    </p>
    <a v-if="!showServices" class="btn btn-secondary" @click="$emit('onShowServices')">
      Show Virtual Services
    </a>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 0,
    },
    showServices: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    content: function () {
      let str = `${this.count} listings fall outside of your search radius, but are able to offer virtual services for your search criteria.`;
      if (this.showServices) {
        str = `Showing ${this.count} virtual services`;
      }
      return str;
    },
  },
};
</script>

<style lang="scss">
.c-list-divider {
  padding: 1.5rem 2.25rem;
  font-size: 0.9375rem;
  line-height: 1.3125rem;
  font-weight: 400;
  background-color: #fcdce2;
  box-shadow: inset 0 3px 7px rgb(0 0 0 / 13%);
  color: #5c2946;
  content: '';
  display: block;
  text-align: center;
}
</style>
