import Vue from 'vue';
import Vuex from 'vuex';

import map from './map.js';
import markers from './markers.js';
import users from './users.js';

// import analytics from './analytics.js';
import api from '../lib/api.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    init: false,
    loaded: false,
    ready: false,
    env: 'development',
    debug: false,

    showIntro: false,
    introTrigger: false,

    showList: false,
    collapseFilterView: false,
    filterOverlayForm: '',

    windowWidth: 428,
    resizeTrigger: true,

    saveProfileId: null,

    // set a user id here to track their filters. Watch for console.log s afterwards
    trackedUser: null
  },

  getters: {
    init: function(state) {
      return state.init;
    },
    loaded: function(state) {
      return state.loaded;
    },
    ready: function(state) {
      return state.ready;
    },
    env: function(state) {
      return state.env;
    },
    debug: function(state) {
      return state.debug;
    },

    showIntro: function(state) {
      return state.showIntro;
    },
    introTrigger: function(state) {
      return state.introTrigger;
    },
    showList: function(state) {
      return state.showList;
    },
    filterViewCollapse: function(state) {
      return state.collapseFilterView;
    },
    filterOverlayForm: function(state) {
      return state.filterOverlayForm;
    },

    windowWidth: function(state) {
      return state.windowWidth;
    },
    resizeTrigger: function(state) {
      return state.resizeTrigger;
    },
    isMobile: function(state) {
      if (state.windowWidth >= 428) {
        return false;
      } else {
        return true;
      }
    },
    saveProfileId: function(state) {
      return state.saveProfileId;
    },
    trackedUser: function(state) {
      return state.trackedUser;
    }
  },

  mutations: {
    HAS_INIT: function(state) {
      state.init = true;
    },
    HAS_LOADED: function(state) {
      state.loaded = true;
    },
    SET_DEBUG: function(state, condition) {
      state.debug = condition;
    },
    SET_INTRO: function(state, condition) {
      state.showIntro = condition;
    },
    TRIGGER_INTRO: function(state) {
      state.introTrigger = !state.introTrigger;
    },
    SET_READY: function(state, condition) {
      state.ready = condition;
    },
    TOGGLE_LIST: function(state) {
      state.showList = !state.showList;
    },
    SET_LIST: function(state, condition) {
      state.showList = condition;
    },
    HIDE_FILTER_OVERLAY: function(state) {
      state.showFilterOverlay = false;
    },
    SET_FILTER_VIEW_COLLAPSE: function(state, condition) {
      state.collapseFilterView = condition;
    },
    SET_FILTER_OVERLAY_FORM: function(state, formName) {
      state.filterOverlayForm = formName;
    },
    SET_WINDOW_WIDTH: function(state, width) {
      state.windowWidth = width;
    },
    TRIGGER_RESIZE: function(state) {
      state.resizeTrigger = !state.resizeTrigger;
    }
  },

  actions: {
    /**
     * Order matters here btw, don't change it willy nilly
     */
    async init(store) {
      store.state.env = process.env.NODE_ENV || 'development';
      if (store.state.env !== 'production') {
        //store.commit('SET_DEBUG', true);
      }

      await store.dispatch('map/init');

      await store.dispatch('markers/init');

      await store.dispatch('users/init');

      // await store.dispatch('analytics/init');

      store.dispatch('setWindowWidth', window.innerWidth);

      store.commit('HAS_INIT');
    },

    async submitSaveSearchForm(store, form) {
      // store.dispatch('analytics/onSaveSearch', form);
      return await api.app.saveSearch(form);
    },

    hasLoaded: function(store) {
      store.commit('HAS_LOADED');
    },

    hasReady: function(store) {
      if (!store.getters['ready']) {
        store.commit('SET_READY', true);
      }
    },

    async checkDisclaimer(store) {
      return await api.app.checkDisclaimer();
    },

    async setDisclaimer(store) {
      await api.app.setDisclaimer();
    },

    async setExplainer(store) {
      await api.app.setExplainer();
    },

    async checkExplainer(store) {
      return await api.app.checkExplainer();
    },

    toggleIntro: function(store, condition) {
      store.commit('SET_INTRO', condition);
      store.commit('TRIGGER_INTRO');
    },

    toggleList: function(store, condition) {
      if (typeof condition !== 'boolean') {
        store.commit('TOGGLE_LIST');
      } else {
        store.commit('SET_LIST', condition);
      }
    },

    setFilterViewCollapse: function(store, condition) {
      store.commit('SET_FILTER_VIEW_COLLAPSE', condition);
    },

    setFilterOverlayForm: function(store, formName) {
      store.commit('SET_FILTER_OVERLAY_FORM', formName);
    },

    setWindowWidth: function(store, width) {
      store.commit('SET_WINDOW_WIDTH', width);
      store.commit('TRIGGER_RESIZE');
    },

    setSaveProfileId: function(store, id) {
      store.state.saveProfileId = id;
    }
  },

  modules: {
    map,
    markers,
    users
  }
});
