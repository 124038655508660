<template>
  <div class="c-home">
    <Map></Map>
    <ModalTerms></ModalTerms>
    <SiteFooter></SiteFooter>
  </div>
</template>

<script>
// @ is an alias to /src
import ModalTerms from '@/components/map/modal-terms';
import Map from '@/components/map';
import SiteFooter from '@/components/global/site-footer';

export default {
  components: {
    ModalTerms,
    Map,
    SiteFooter,
  },
};
</script>

<style lang="scss">
.c-home {
  position: relative;
}
</style>
