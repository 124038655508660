import axios from 'axios';

// const http = axios.create({
//   baseURL: 'https://some-domain.com/api/',
//   timeout: 1000,
//   headers: {'X-Custom-Header': 'foobar'}
// });

const http = axios.create({});

export default http;
