export default function(data) {
  let user = {
    /**
     * Int
     *
     * Mysql based id, should be unique
     */
    id: data.id,

    /**
     * String
     *
     * type
     */
    type: data.type,

    /**
     * Array
     *
     * Category - think of it as a higher level of type. Is filtereble.
     *
     */
    category: data.category || [],

    /**
     * String
     *
     * Title, eg : Mr, Mrs, etc
     */
    title: data.title || '',

    /**
     * String
     *
     * first name of the user
     */
    first_name: data.first_name || '',

    /**
     * String
     *
     * Last name of the user
     */
    last_name: data.last_name || '',

    /**
     * String
     *
     * Full name, eg: Dr Dragana Kesic
     */
    full_name: data.full_name,

    /**
     * String
     *
     * Url of the avatar without the base url
     */
    avatar: data.avatar || '',

    /**
     * String
     *
     * If present, indicates that the listing is disabled for some reason, doctor is sick or on vacation
     */
    availability: data.availability || 'inactive',

    /**
     * Company name
     */
    company: data.company || '',

    /**
     * Valid australian phone number without the country code
     */
    phone: data.phone || '',

    /**
     * Valid email address
     */
    email: data.email || '',

    /**
     * Valid website url starting with https:// or http://
     */
    website: data.website || '',

    /**
     * Boolean
     *
     * Does this marker has ATSI specific service?
     */
    atsi: data.atsi || false,

    /**
     * String
     *
     * Readable text of what services are offered by this user.
     * Should be around 100 - 500 characters
     */
    service_summary: data.service_summary || '',

    /**
     * String
     *
     * The aphra number or a blank string
     */
    aphra: data.aphra || '',

    /**
     * Array
     *
     * Array of professions, eh: []
     */
    professions: data.professions || [],

    /**
     * Array
     *
     * Array of expertise this user provides.
     * If empty, General Support will be assumed to be in this list
     */
    expertise: data.expertise || [],

    /**
     * Array
     * Non filterable array of expertise
     */
    expertise_other: data.expertise_other || [],

    /**
     * Readable text of any other service information that can be provided
     * Should be around 100 - 500 characters
     */
    other_service_information: data.other_service_information || '',

    /**
     * String
     * Readable text of the service summary - slight variation on the other_service_information
     */
    service_summary: data.service_summary || ''
  };

  // Only for testing
  // let availabilities = ['available', 'long-wait-times', 'unavailable', 'inactive'];

  // var a = availabilities[Math.floor(Math.random() * availabilities.length)];

  // user.availability = a;

  return user;
}
