export const statuses = [
  {
    key: 'Planning a Family',
    value: 'planning_a_family',
  },
  {
    key: 'Expecting a Baby and Birth',
    value: 'expecting_a_baby_and_birth',
  },
  {
    key: 'New Parents',
    value: 'new_parents',
  },
  {
    key: 'Grief & Loss',
    value: 'grief_and_loss',
  },
];

export const stages = {
  planning_a_family: [
    {
      key: 'Infertility Support',
      value: 'Counselling Surrounding Infertility',
    },
    {
      key: 'Mental Health (all)',
      value: 'Mental Health (all)',
      items: [
        {
          key: 'Antenatal Anxiety',
          value: 'Antenatal Anxiety',
        },
        {
          key: 'Antenatal Depression',
          value: 'Antenatal Depression',
        },
        {
          key: 'Postnatal Anxiety',
          value: 'Postnatal Anxiety',
        },
        {
          key: 'Postnatal Depression',
          value: 'Postnatal Depression',
        },
        {
          key: 'Psychosis',
          value: 'Postpartum Psychosis',
        },
        {
          key: 'Schizophrenia',
          value: 'Schizophrenia',
        },
        {
          key: 'Bipolar Disorder',
          value: 'Managing Bipolar Disorder in the Perinatal Period',
        },
        {
          key: 'Borderline Personality Disorder',
          value: 'Managing Borderline Personality Disorder in the Perinatal Period',
        },
        {
          key: 'Eating Disorder',
          value: 'Eating Disorder',
        },
      ],
    },
    {
      key: 'Birth Trauma History',
      value: 'Birth Trauma',
    },
    {
      key: 'Perinatal Loss History',
      value: 'Perinatal Loss',
    },
    {
      key: 'Fear of Birth',
      value: 'Fear of Birth',
    },
    {
      key: 'Past Trauma/Abuse',
      value: 'Past Trauma or Abuse',
    },
    {
      key: 'Same-Sex Parenting',
      value: 'Same Sex Parenting',
    },
    {
      key: 'D&A Issues',
      value: 'Managing Drug and Alcohol Misuse in the Perinatal Period',
    },
    {
      key: 'Mens/Fathers/Paternal Mental Health',
      value: 'Men/Fathers/Paternal Mental Health',
    },
    {
      key: 'Relationship Issues',
      value: 'Perinatal Couple Treatment/Therapy',
    },
    {
      key: 'Family Violence',
      value: 'Family Violence',
    },
    {
      key: 'Loneliness/Isolation',
      value: 'Loneliness/Isolation',
    },
  ],
  expecting_a_baby_and_birth: [
    {
      key: 'Mental Health (all)',
      value: 'Mental Health (all)',
      items: [
        {
          key: 'Antenatal Anxiety',
          value: 'Antenatal Anxiety',
        },
        {
          key: 'Antenatal Depression',
          value: 'Antenatal Depression',
        },
        {
          key: 'Psychosis',
          value: 'Postpartum Psychosis',
        },
        {
          key: 'Schizophrenia',
          value: 'Schizophrenia',
        },
        {
          key: 'Bipolar Disorder',
          value: 'Managing Bipolar Disorder in the Perinatal Period',
        },
        {
          key: 'Borderline Personality Disorder',
          value: 'Managing Borderline Personality Disorder in the Perinatal Period',
        },
        {
          key: 'Eating Disorder',
          value: 'Eating Disorder',
        },
      ],
    },
    {
      key: 'Birth Trauma History',
      value: 'Birth Trauma',
    },
    {
      key: 'Miscarriage',
      value: 'Miscarriage',
    },
    {
      key: 'Stillbirth',
      value: 'Stillbirth',
    },
    {
      key: 'Fear of Birth',
      value: 'Fear of Birth',
    },
    {
      key: 'Bonding and Attachment',
      value: 'Bonding and Attachment',
    },
    {
      key: 'Past Trauma or Abuse',
      value: 'Past Trauma or Abuse',
    },
    {
      key: 'Gender Disappointment',
      value: 'Gender Disappointment',
    },
    {
      key: 'Same-Sex Parenting',
      value: 'Same Sex Parenting',
    },
    {
      key: 'D&A Issues',
      value: 'Managing Drug and Alcohol Misuse in the Perinatal Period',
    },
    {
      key: 'Mens/Fathers/Paternal Mental Health',
      value: 'Men/Fathers/Paternal Mental Health',
    },
    {
      key: 'Relationship Issues',
      value: 'Perinatal Couple Treatment/Therapy',
    },
    {
      key: 'Congenital Anomalies',
      value: 'Congenital Anomalies',
    },
    {
      key: 'Family Violence',
      value: 'Family Violence',
    },
    {
      key: 'Loneliness/Isolation',
      value: 'Loneliness/Isolation',
    },

    {
      key: 'Pregnancy decision making',
      value: 'Pregnancy Decision Making',
    },
    {
      key: 'Hyperemesis Gravidarum (HG)',
      value: 'Hyperemesis Gravidarum (HG)',
    },
    {
      key: 'Health Complications',
      value: 'Health Complications',
    },
    {
      key: 'Terminations',
      value: 'Support Around Termination',
    },
    {
      key: 'Multiple birth',
      value: 'Multiple Birth',
    },
    {
      key: 'Parenting Support',
      value: 'Parenting Support',
    },
    {
      key: 'Young Parents/Teen Pregnancies',
      value: 'Young Parents/Teen Pregnancies',
    },
  ],
  new_parents: [
    {
      key: 'Mental Health (all)',
      value: 'Mental Health (all)',
      items: [
        {
          key: 'Postnatal Anxiety',
          value: 'Postnatal Anxiety',
        },
        {
          key: 'Postnatal Depression',
          value: 'Postnatal Depression',
        },
        {
          key: 'Postpartum Psychosis',
          value: 'Postpartum Psychosis',
        },
        {
          key: 'Schizophrenia',
          value: 'Schizophrenia',
        },
        {
          key: 'Bipolar Disorder',
          value: 'Managing Bipolar Disorder in the Perinatal Period',
        },
        {
          key: 'Borderline Personality Disorder',
          value: 'Managing Borderline Personality Disorder in the Perinatal Period',
        },
        {
          key: 'Eating Disorder',
          value: 'Eating Disorder',
        },
      ],
    },
    {
      key: 'Adjustment Difficulties',
      value: 'Adjustment Disorder',
    },
    {
      key: 'Birth Trauma History',
      value: 'Birth Trauma',
    },
    {
      key: 'Miscarriage',
      value: 'Miscarriage',
    },
    {
      key: 'Stillbirth',
      value: 'Stillbirth',
    },
    {
      key: 'Bonding and Attachment',
      value: 'Bonding and Attachment',
    },
    {
      key: 'Past Trauma/Abuse',
      value: 'Past Trauma or Abuse',
    },
    {
      key: 'Gender Disappointment',
      value: 'Gender Disappointment',
    },
    {
      key: 'Same-Sex Parenting',
      value: 'Same Sex Parenting',
    },
    {
      key: 'D&A Issues',
      value: 'Managing Drug and Alcohol Misuse in the Perinatal Period',
    },
    {
      key: 'Mens/Fathers/Paternal Mental Health',
      value: 'Men/Fathers/Paternal Mental Health',
    },
    {
      key: 'Relationship Issues',
      value: 'Perinatal Couple Treatment/Therapy',
    },
    {
      key: 'Infants with Special Needs',
      value: 'Infants with Special Needs',
    },
    {
      key: 'NICU/Premature Birth Distress',
      value: 'NICU/Premature Birth Distress',
    },
    {
      key: 'Family Violence',
      value: 'Family Violence',
    },
    {
      key: 'Infant Mental Health',
      value: 'Infant Mental Health',
    },
    {
      key: 'Loneliness/Isolation',
      value: 'Loneliness/Isolation',
    },

    {
      key: 'Breast-feeding/feeding issues',
      value: 'Breast-Feeding/Feeding Issues',
    },
    {
      key: 'Multiple birth',
      value: 'Multiple Birth',
    },
    {
      key: 'Sleep and settling',
      value: 'Sleep and Settling',
    },
    {
      key: 'Parental Burnout',
      value: 'Parental Burnout',
    },
    {
      key: 'Parenting Support',
      value: 'Parenting Support',
    },
    {
      key: 'Young Parents',
      value: 'Young Parents',
    },
  ],
  grief_and_loss: [
    {
      key: 'Miscarriage',
      value: 'Miscarriage',
    },
    {
      key: 'Stillbirth',
      value: 'Stillbirth',
    },
    {
      key: 'Infant death',
      value: 'Infant Death',
    },
    {
      key: 'Termination',
      value: 'Support Around Termination',
    },
    {
      key: 'Absence of mother',
      value: 'Maternal Grief and Loss',
    },
    {
      key: 'Unable to have a baby',
      value: 'Involuntary Childlessness',
    },
    {
      key: 'Perinatal Loss History',
      value: 'Perinatal Loss',
    },
  ],
};

export const deliveries = [
  {
    key: 'Face-to-face (individual)',
    value: 'Face-to-face (individual)',
  },
  {
    key: 'Face-to-face (group)',
    value: 'Face-to-face (group)',
  },
  {
    key: 'Video Conferencing (Telehealth & Telepsychiatry)',
    value: 'Telehealth',
  },
  {
    key: 'Telephone Support',
    value: 'Telephone Counselling',
  },
  {
    key: 'Online (Web) Support',
    value: 'Online mental health support',
  },
  {
    key: 'Online (Web) Treatment',
    value: 'Online mental health treatment',
  },
  {
    key: 'Online Social Media (e.g. Facebook)',
    value: 'Online Social Media (e.g. Facebook)',
  },
  {
    key: 'SMS Support',
    value: 'SMS',
  },
  {
    key: 'Home Visiting Service',
    value: 'Home visiting service',
  },
  {
    key: 'Mother and Baby Hospital',
    value: 'Mother and baby (in-patient)',
  },
];

export const professions = [
  {
    key: 'Mental Health Professional',
    value: 'Mental Health Professional',
    items: [
      {
        key: 'Registered Psychologist',
        value: 'Registered Psychologist',
      },
      {
        key: 'Clinical Psychologist',
        value: 'Clinical Psychologist',
      },
      {
        key: 'Social Worker',
        value: 'Social Worker',
      },
      {
        key: 'General Practitioner',
        value: 'General Practitioner',
      },
      {
        key: 'Occupational Therapist',
        value: 'Occupational Therapist',
      },
      {
        key: 'Mental Health Nurse',
        value: 'Mental Health Nurse',
      },
      {
        key: 'Specialist Psychiatrist',
        value: 'Specialist Psychiatrist',
      },
      // {
      //   key: 'Psychiatrist',
      //   value: 'Psychiatrist'
      // },
      {
        key: 'Aboriginal Mental Health Worker',
        value: 'Aboriginal Mental Health Worker',
      },
      {
        key: 'Health Psychologist',
        value: 'Health Psychologist',
      },
      {
        key: 'Accredited Mental Health Social Worker',
        value: 'Accredited Mental Health Social Worker',
      },
      {
        key: 'Other',
        value: 'Other',
      },
    ],
  },
  {
    key: 'Counselling',
    value: 'Counselling',
    items: [
      {
        key: 'Professionally registered Counsellors',
        value: 'Counsellor - Professionally trained counsellor',
      },
      {
        key: 'Peer Support/Lived Experience Counsellors',
        value: 'Counsellor - Peer/someone with similar lived experience',
      },
    ],
  },
  {
    key: 'Community Support',
    value: 'Community Support',
  },
];

export const costs = [
  {
    key: 'Free Service',
    value: 'Free service',
  },
  {
    key: 'Bulk Billing (Medicare)',
    value: 'Bulk-bill service',
  },
  {
    key: 'Bulk Billing (Health care card holder)',
    value: 'Bulk-bill healthcare cardholder',
  },
  {
    key: 'Private with Health Insurance',
    value: 'Private Health fund rebate available',
  },
];

export const services = [
  {
    key: 'Interpreter service',
    value: 'interpreter_service',
  },
  {
    key: 'Aboriginal and Torres Strait Islander services',
    value: 'atsi',
  },
];

export const languages = [
  {
    key: 'English',
    value: 'English',
  },
  {
    key: 'Afrikaans',
    value: 'Afrikaans',
  },
  {
    key: 'Arabic',
    value: 'Arabic',
  },
  {
    key: 'Bulgarian',
    value: 'Bulgarian',
  },
  {
    key: 'Chinese (Cantonese)',
    value: 'Chinese (Cantonese)',
  },
  {
    key: 'Chinese (Mandarin)',
    value: 'Chinese (Mandarin)',
  },
  {
    key: 'French',
    value: 'French',
  },
  {
    key: 'German',
    value: 'German',
  },
  {
    key: 'Hindi',
    value: 'Hindi',
  },
  {
    key: 'Hungarian',
    value: 'Hungarian',
  },
  {
    key: 'Italian',
    value: 'Italian',
  },
  {
    key: 'Russian',
    value: 'Russian',
  },
  {
    key: 'Sinhala',
    value: 'Sinhala',
  },
  {
    key: 'Spanish',
    value: 'Spanish',
  },
  {
    key: 'Telugu',
    value: 'Telugu',
  },
  {
    key: 'Urdu',
    value: 'Urdu',
  },
  {
    key: 'Vietnamese',
    value: 'Vietnamese',
  },
];
