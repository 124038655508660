<template>
  <div class="c-map-view-popup__wrapper">
    <div class="c-map-view-popup">
      <div class="c-map-view-popup__header">
        <p @click="onClose">
          <span>
            Close
          </span>
          <x-icon size="1.5x"></x-icon>
        </p>
      </div>
      <div class="c-map-view-popup__body">
        <!-- <article class="on-desktop">
          <h2>
            No Physical Results
          </h2>
          <p>
            Your search criteria provided no physical locations within your search radius, however
            there are some results outside of the radius that can provide virtual services to you.
          </p>
          <p>Please view the listing results on the left for further information.</p>
        </article>
        <article class="on-mobile">
          <h2>
            Virtual Services Only
          </h2>
          <p>
            As there are no in-person services within your area, you can increase your search radius
            (via the refine button), or consider the listed virtual services (under ‘Show List’)
            that can be accessed from any location.
          </p>
        </article> -->
        <article>
          <h2>
            Virtual Services Only
          </h2>
          <a class="btn btn-secondary" @click="onShowServices">
            Click here for virtual services
          </a>
          <br />
          <br />
          <p>
            Alternatively you can increase the search radius or change search filters with the
            Refine Further button (top left of screen).
          </p>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { XIcon } from 'vue-feather-icons';
export default {
  components: {
    XIcon
  },

  methods: {
    onShowServices: function() {
      this.$store.dispatch('map/showServiceList');
    },
    onClose: function() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">
.c-map-view-popup {
  width: 350px;
  margin: 0 auto;
  background-color: var(--g1);

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__header {
    display: flex;
    align-items: center;
    height: var(--spacer);
    background-color: var(--p1);
    color: var(--p4);
    padding: 0 var(--margin);

    p {
      display: inline-flex;
      align-items: center;
      margin: 0;
      margin-left: auto;
      line-height: 1;
      cursor: pointer;
    }
  }

  &__body {
    padding: 36px 24px;
  }

  .on-mobile {
    display: none;
  }

  article {
    text-align: center;
  }

  @media screen and (max-width: 428px) {
    margin-bottom: var(--spacer);

    &__wrapper {
      align-items: flex-end;
    }

    .on-mobile {
      display: block;
    }
    .on-desktop {
      display: none;
    }
  }
}
</style>
