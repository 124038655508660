export default function(filter) {
  filter.radius = filter.radius || 10;
  filter.postcode = filter.postcode || 3000;

  let typesEnum = ['ip', 'ppos', 'cso'];

  if (!typesEnum.includes(filter.type)) {
    filter.type = '';
  } else {
  }

  filter.radius = parseInt(filter.radius);
  if (filter.radius > 50) {
    filter.radius = 50;
  }
  // filter.postcode = parseInt(filter.postcode);
  // if (Number.isNaN(filter.postcode)) {
  //   filter.postcode = 3000; // default to melbourne if postcode is incorrect
  // }

  filter.help_for = filter.help_for || '';

  if (typeof filter.interpreter_service === 'string') {
    filter.interpreter_service = filter.interpreter_service === 'true' || false;
  }

  if (typeof filter.atsi === 'string') {
    filter.atsi = filter.atsi === 'true' || false;
  }

  // Old style of encoding array
  if (typeof filter.expertise === 'string') {
    if (filter.expertise.includes('|')) {
      let temp = filter.expertise.split('|');
      filter.expertise = temp;
    } else {
      filter.expertise = [filter.expertise];
    }
  }

  filter.expertise = filter.expertise || [];

  if (filter.modes_of_delivery && typeof filter.modes_of_delivery === 'string') {
    if (filter.modes_of_delivery.includes('|')) {
      let temp = filter.modes_of_delivery.split('|');
      filter.deliveries = temp;
    } else {
      filter.deliveries = [filter.modes_of_delivery];
    }
  }

  if (typeof filter.deliveries === 'string') {
    filter.deliveries = [filter.deliveries];
  } else if (typeof filter.deliveries === 'object') {
    filter.deliveries = filter.deliveries;
  } else {
    filter.deliveries = [];
  }

  filter.profession = filter.profession || [];

  // Old style of encoding array
  if (typeof filter.profession === 'string') {
    if (filter.profession.includes('|')) {
      let temp = filter.profession.split('|');
      filter.profession = temp;
    } else {
      filter.profession = [filter.profession];
    }
  } else if (typeof filter.profession === 'object') {
    filter.profession = filter.profession;
  } else {
    filter.profession = [];
  }

  filter.costs = filter.costs || [];

  filter.services = filter.services || [];

  if (typeof filter.languages === 'string') {
    filter.languages = [filter.languages];
  } else if (typeof filter.languages === 'object') {
    filter.languages = filter.languages;
  } else {
    filter.languages = [];
  }

  filter.languages = filter.languages.filter(onlyUnique);

  // Hook to expand grief and loss filter
  if (filter.expertise.includes('Grief and Loss (Due to Miscarriage/Stillbirth), Perinatal Loss')) {
    let i = filter.expertise.indexOf(
      'Grief and Loss (Due to Miscarriage/Stillbirth), Perinatal Loss'
    );
    filter.expertise.splice(i, 1);
    filter.expertise.push('Grief and Loss (Due to Miscarriage/Stillbirth)');
    filter.expertise.push('Perinatal Loss');
  }

  // user is equal to user id
  if (filter.user_id) {
  } else {
    delete filter.user_id;
  }

  if (filter.marker_id) {
  } else {
    delete filter.marker_id;
  }

  return filter;
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
