<template>
  <div class="c-header">
    <a href="https://cope.org.au" target="_blank">
      <Logo></Logo>
    </a>
  </div>
</template>

<script>
import Logo from '@/components/global/logo';
export default {
  components: {
    Logo
  }
};
</script>

<style lang="scss">
.c-header {
  position: absolute;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  padding: var(--spacer-sm) var(--spacer-sm);

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--p4);

  svg {
    display: block;
    margin: 0 auto;
    width: auto;
    height: 56px;
  }
  @media screen and (max-width: 428px) {
    justify-content: flex-start;

    a {
      display: block;
    }

    svg {
      margin: 0;
      height: 40px;
    }
  }
}
</style>
