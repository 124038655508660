<template>
  <div :class="['c-filter-view-overlay', { active: active === true }]">
    <PopupHeader title="Search Criteria" @close="onClose"></PopupHeader>
    <component
      v-if="filterOverlayForm"
      :is="filterOverlayForm"
      @submit="onClose"
      @back="onClose"
    ></component>
  </div>
</template>

<script>
import PopupHeader from '@/components/global/popup-header';

import FormHelpFor from './forms/form-help-for';
import FormRadius from './forms/form-radius';
import FormPostcode from './forms/form-postcode';
import FormDeliveries from './forms/form-deliveries';
import FormProfession from './forms/form-profession';
import FormSubProfession from './forms/form-sub-profession';

export default {
  components: {
    PopupHeader,
    FormHelpFor,
    FormRadius,
    FormPostcode,
    FormDeliveries,
    FormProfession,
    FormSubProfession
  },

  computed: {
    filterOverlayForm: function() {
      return this.$store.getters['filterOverlayForm'];
    },
    active: function() {
      if (this.filterOverlayForm) {
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {
    onClose: function() {
      this.$store.dispatch('setFilterOverlayForm', null);
    }
  }
};
</script>

<style lang="scss">
.c-filter-view-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--g1);
  overflow-y: auto;
  transform: translateX(-100%);
  opacity: 0;
  pointer-events: none;
  transition: all var(--transition-time-lg) cubic-bezier(0.215, 0.61, 0.355, 1);
  display: flex;
  flex-direction: column;

  &.active {
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
  }
}
</style>
