<template>
  <div class="c-form-postcode">
    <form @submit.prevent="onSubmit">
      <div class="c-form-filter__body">
        <h4>Postcode</h4>
        <div class="c-form-filter__body__inner">
          <input
            v-model="form.postcode"
            class="form-control"
            type="text"
            minlength="1"
            :required="true"
          />
        </div>
      </div>
      <FormFooter @onBack="onBack"></FormFooter>
    </form>
  </div>
</template>

<script>
import Dropdown from '@/components/global/dropdown';
import Accordion from '@/components/global/accordion';
import CheckboxGroup from '@/components/map/checkbox-group';
import FormFooter from '@/components/global/form-footer';

import {
  statuses,
  stages,
  deliveries,
  professions,
  costs,
  services,
  languages
} from '@/lib/form-data.js';

import slugify from '@/lib/slugify.js';

import VueSlider from 'vue-slider-component';

export default {
  components: {
    Dropdown,
    Accordion,
    CheckboxGroup,
    VueSlider,
    FormFooter
  },

  data: function() {
    return {
      statuses: statuses,
      stages: stages,
      deliveries: deliveries,
      professions: professions,
      costs: costs,
      services: services,
      languages: languages,

      // gets populated by the watcher
      form: {}
    };
  },

  watch: {
    filter: {
      handler: function() {
        this.form = this.setupForm(this.filter);
      },
      immediate: true
    }
  },

  computed: {
    debug: function() {
      return this.$store.getters['debug'];
    },
    filter: function() {
      return this.$store.getters['map/filter'];
    }
  },

  methods: {
    onStatusClick: function(value) {
      this.form.help_for = value;
      this.$refs.dropdownHelpFor.onToggle();
    },
    setupForm: function(filter) {
      let form = JSON.parse(JSON.stringify(filter));

      return form;
    },
    slugify: function(str) {
      return slugify(str);
    },
    async onSubmit() {
      let form = this.form;
      await this.$store.dispatch('map/updateFilter', form);

      this.$emit('submit');
    },
    onBack: function() {
      this.$emit('back');
    }
  }
};
</script>

<style lang="scss">
.c-form-filter {
  height: 100%;

  > form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .c-dropdown {
    margin-bottom: var(--margin);
  }

  &__body {
    padding: var(--margin-lg) var(--spacer);

    &__inner {
      display: flex;
      align-items: center;

      > p {
        margin-bottom: 0;
      }
    }
  }

  &__footer {
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: var(--spacer-sm) var(--spacer);
  }

  &__disclaimer {
    padding: var(--margin-lg) var(--spacer);
    background-color: var(--warning-color);
  }

  .vue-slider {
    flex-grow: 1;
    .vue-slider-process {
      background-color: var(--p3) !important;
    }

    .vue-slider-dot-handle {
      background-color: var(--p3);
      border-color: var(--p3) !important;

      &-focus {
        box-shadow: none;
      }
    }
  }

  @media screen and (max-width: 428px) {
    &__body {
      padding: var(--margin-lg) var(--spacer-sm);
    }

    &__buttons {
      padding: var(--margin-lg) var(--spacer-sm);
    }
  }
}
</style>
