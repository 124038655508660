<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 496">
    <path
      d="M483.52,28.49,431.35,80.65A247.19,247.19,0,0,0,256,8C119.34,8,7.9,119.53,8,256.18,8.1,393.07,119.1,504,256,504a247.1,247.1,0,0,0,166.18-63.91,12,12,0,0,0,.48-17.43l-19.74-19.74a12,12,0,0,0-16.5-.55,196,196,0,1,1,8.19-285l-54.12,54.12A12,12,0,0,0,349,192H492a12,12,0,0,0,12-12V37A12,12,0,0,0,483.52,28.49Z"
      transform="translate(-8 -8)"
    />
  </svg>
</template>

<script>
export default {};
</script>
