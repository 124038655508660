<template>
  <div :class="['c-copy-status', { inline: forceInline === true }]">
    <strong>
      {{ title }}
    </strong>
    <p>
      <Status :status="status"></Status>
      <span>{{ readableStatus }}</span>
    </p>
  </div>
</template>

<script>
import { CopyIcon } from 'vue-feather-icons';
import Status from '@/components/global/status';

export default {
  components: {
    Status,
    CopyIcon
  },

  props: {
    title: {
      type: String,
      default: '',
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    forceInline: {
      type: Boolean,
      default: false
    },
    status: {}
  },

  computed: {
    readableStatus: function() {
      let status = this.status;
      let str = ``;

      if (status === 'inactive') {
        str = `Inactive`;
      }

      if (status === 'unavailable') {
        str = `Unavailable`;
      }

      if (status === 'long-wait-times') {
        str = `Long wait times`;
      }

      if (status === 'available') {
        str = `Available`;
      }

      return str;
    }
  },

  methods: {
    onClick: function() {}
  }
};
</script>

<style lang="scss">
.c-copy-status {
  padding: var(--margin-sm) 0;

  .svg {
    stroke-width: 3px;
  }

  p {
    margin: 0;
    display: inline-block;
  }

  .c-status,
  span:last-child {
    display: inline-block;
    margin-left: var(--margin);
  }

  &.inline {
    strong {
      display: inline;
    }
    p {
      display: inline;
    }
  }
}
</style>
