<template>
  <div class="c-indeterminate-checkbox">
    <div class="form-checkbox" v-if="item">
      <input
        type="checkbox"
        ref="checkbox"
        name="checkbox"
        :id="slugify(item.value + id)"
        @change="onChange($event, item)"
      />
      <label :for="slugify(item.value + id)">
        {{ item.key }}
      </label>
    </div>
  </div>
</template>

<script>
import slugify from '@/lib/slugify.js';
export default {
  data: function() {
    return {
      id: ''
    };
  },

  props: {
    values: {
      type: Array
    },
    item: {
      type: Object,
      default: function() {
        return null;
      }
    },
    topLevel: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    values: {
      handler: function() {
        this.checkCheckbox();
      }
    }
  },

  computed: {
    isPartOfArray: function() {
      let values = this.values;
      let currentValues = this.currentValues;
      let isPartOfArray = false;

      for (let i = 0; i < values.length; i++) {
        if (currentValues.includes(values[i])) {
          isPartOfArray = true;
          break;
        }
      }

      return isPartOfArray;
    },
    currentValues: function() {
      let items = this.item.items;
      let values = this.values;

      let result = [];

      let currentValues = [];
      for (let i = 0; i < this.item.items.length; i++) {
        currentValues.push(this.item.items[i].value);
      }

      for (let i = 0; i < currentValues.length; i++) {
        if (values.includes(currentValues[i])) {
          result.push(currentValues[i]);
        }
      }

      return result;
    }
  },

  methods: {
    onChange: function(e, item) {
      let checked = e.target.checked;

      if (this.topLevel) {
        this.$emit('onChange', [], checked);
        return;
      }

      let temp = [];
      for (let i = 0; i < item.items.length; i++) {
        temp.push(item.items[i].value);
      }

      this.$emit('onChange', temp, checked);

      // let temp = [];
      // for (let i = 0; i < item.items.length; i++) {
      //   temp.push(item.items[i].value);
      // }
      // for (let i = 0; i < temp.length; i++) {
      //   if (currentValues.includes(temp[i])) {
      //     let j = currentValues.indexOf(temp[i]);
      //     currentValues.splice(j, 1);
      //   }
      // }
      // this.currentValues = currentValues;
    },
    checkCheckbox: function() {
      let values = this.values;

      let currentSelectedValues = this.currentValues;

      if (values.length === 0) {
        this.$refs.checkbox.checked = false;
        this.$refs.checkbox.indeterminate = false;
        return;
      }
      let currentValues = [];
      for (let i = 0; i < this.item.items.length; i++) {
        currentValues.push(this.item.items[i].value);
      }

      let isPartOfArray = this.isPartOfArray;

      if (!isPartOfArray) {
        if (currentSelectedValues.length === 0) {
          this.$refs.checkbox.checked = false;
          this.$refs.checkbox.indeterminate = false;
        }

        return;
      }

      // Check if all current values are inside checkbox

      if (currentSelectedValues.length === currentValues.length) {
        this.$refs.checkbox.checked = true;
        this.$refs.checkbox.indeterminate = false;
      } else {
        this.$refs.checkbox.checked = false;
        this.$refs.checkbox.indeterminate = true;
      }

      // If literally no value is selected, turn off the checkbox
      if (currentSelectedValues.length === 0) {
        this.$refs.checkbox.checked = false;
        this.$refs.checkbox.indeterminate = true;
      }
    },
    slugify: function(str) {
      return slugify(str);
    }
  },

  created: function() {
    this.id = this._uid;
  }
};
</script>

<style lang="scss">
.c-indeterminate-checkbox {
}
</style>
