<template>
  <div class="c-copy-website">
    <strong>
      {{ title }}
    </strong>
    <p>
      <a target="_blank " :href="normalizedWebsite" @click="$emit('click', 'website')">
        {{ shortWebsite }}</a
      >
    </p>
  </div>
</template>

<script>
import { CopyIcon } from 'vue-feather-icons';

export default {
  components: {
    CopyIcon
  },

  props: {
    website: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Website:'
    }
  },

  computed: {
    shortWebsite: function() {
      let website = this.website;

      website = website.replace('http://', '');
      website = website.replace('https://', '');

      return website;
    },
    normalizedWebsite: function() {
      let website = this.website;

      if (website.includes('%20')) {
        website.replace('%20', '');
      }

      if (!website.includes('http://') && !website.includes('https://')) {
        website = `https://${website}`;
      }

      return website;
    }
  },

  methods: {
    onClick: function() {}
  }
};
</script>

<style lang="scss">
.c-copy-website {
  padding: var(--margin-sm) 0;

  .svg {
    stroke-width: 3px;
  }

  p {
    margin: 0;
    display: inline-block;
  }

  a:last-child {
    display: inline-block;
    margin-left: var(--margin);
  }
}
</style>
