<template>
  <span
    class="c-tooltip"
    v-if="description"
    v-tooltip="{
      content: description,
      show: false
    }"
  >
    ?
  </span>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss">
.c-tooltip {
  // Actual tooltip popup's style is inside styles/common/_components.scss
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: var(--margin);
  font-size: var(--font-size-xs);
  font-weight: 700;
  line-height: 1.75;
  text-align: center;
  border-radius: 50%;
  background-color: var(--p1);

  @media screen and (max-width: 428px) {
    width: 16px;
    height: 16px;
    line-height: 1.5;
  }
}
</style>
