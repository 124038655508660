<template>
  <div class="c-form-save-search">
    <form @submit.prevent="onSubmit">
      <div>
        <label> Name </label>
        <input
          type="text"
          min="1"
          max="50"
          class="form-control form-control--full"
          v-model="form.name"
          placeholder="Your name"
        />
      </div>
      <div>
        <label> Email Address </label>
        <input
          type="email"
          min="1"
          max="50"
          class="form-control form-control--full"
          v-model="form.email"
          placeholder="abc@xyz.com.au"
        />
      </div>

      <div class="c-form-save-search__footer">
        <button :disabled="processing" class="btn btn-primary btn-lg">
          <span v-if="processing" class="spinner"></span>Save Your Search Results
        </button>

        <button type="button" class="btn btn-lg" @click="onBack">Cancel</button>
      </div>

      <small class="c-form-save-search__error" v-if="errorMessage">{{ errorMessage }}</small>

      <h3 class="c-form-save-search__message" v-if="message">
        {{ message }}
      </h3>
    </form>
  </div>
</template>

<script>
import slugify from '@/lib/slugify.js';

export default {
  components: {},

  data: function() {
    return {
      processing: false,
      message: ``,
      errorMessage: ``,
      // gets populated by the watcher
      form: {
        name: '',
        email: ''
      }
    };
  },

  watch: {},

  computed: {},

  props: {
    id: {}
  },

  methods: {
    setupForm: function(filter) {
      let form = JSON.parse(JSON.stringify(filter));

      // form.service_modes_of_delivery = form.service_modes_of_delivery || [];
      // if (form.service_modes_of_delivery.length === 0) {
      //   let services = this.deliveries.map(item => {
      //     return item.value;
      //   });
      //   form.service_modes_of_delivery = services;
      // }

      return form;
    },
    slugify: function(str) {
      return slugify(str);
    },
    async onSubmit() {
      this.processing = true;
      let form = JSON.parse(JSON.stringify(this.form));

      if (!form.email) {
        this.errorMessage = 'A valid email address is required';
        this.processing = false;
        return;
      }

      if (!form.email.includes('@')) {
        this.errorMessage = 'A valid email address is required';
        this.processing = false;
        return;
      }

      form.link = document.location.href;

      let id = this.id;

      let params = new URLSearchParams(location.search.substring(1));

      if (id && typeof id === 'string' && id.includes('user')) {
        let user_id = id.split(':')[1];
        params.set('user_id', user_id);
      }

      if (id && typeof id === 'string' && id.includes('marker')) {
        let marker_id = id.split(':')[1];
        params.set('marker_id', marker_id);
      }

      let str = params.toString();

      let url = window.location.href.split('?')[0];

      url = `${url}?${str}`;

      form.link = url;

      this.errorMessage = ``;

      let res = await this.$store.dispatch('submitSaveSearchForm', form);

      this.processing = false;

      this.showMessage();

      this.reset();

      this.$emit('submit');
    },
    showMessage: function() {
      this.message = `Your search has been sent to your inbox. Check your emails for a shareable search link.`;
      setTimeout(() => {
        this.message = ``;
      }, 4000);
    },
    reset: function() {
      this.form = {
        name: '',
        email: ''
      };
    },
    onBack: function() {
      this.$emit('back');
    }
  }
};
</script>

<style lang="scss">
.c-form-save-search {
  max-width: 350px;
  margin: 0 auto;
  text-align: left;
  .form-control {
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  .btn {
    margin-bottom: var(--margin-lg);
  }

  &__message {
    padding-top: var(--spacer-sm);
    text-align: center;
  }

  &__error {
    color: #de4122;
  }
}
</style>
