/**
 * Note that it returns an object
 */
export default function(users, circle, google, trackedUser) {
  let center = circle.getCenter();
  let circleRadius = circle.getRadius();
  let distance = 0;

  let visibleCondition = false;
  let isService = false;

  let physicalResultsCount = 0;

  /**
   * Firstly, compute distance for each marker which has a lat/lng(use isService flag for this)
   * And attach distance to the marker
   */
  for (let i = 0; i < users.length; i++) {
    for (let j = 0; j < users[i].markers.length; j++) {
      if (users[i].markers[j].isService) {
        if (trackedUser && users[i].id === trackedUser.id) {
          const message = `User ${trackedUser.id} partially outed in filter-radius because of isService flag`;
          console.log('%c' + message, 'color:Orange');
        }
        continue;
      }

      let markerCenter = new google.maps.LatLng(users[i].markers[j].position);
      distance = google.maps.geometry.spherical.computeDistanceBetween(markerCenter, center);

      if (typeof distance !== 'number' && !distance) {
        //console.log(users[i]);

        if (trackedUser && users[i].id === trackedUser.id) {
          const message = `User ${trackedUser.id} partially outed in filter-radius because couldn't get distance`;
          console.log('%c' + message, 'color:Orange');
        }

        continue;
      }
      users[i].markers[j].distance = distance;
    }
  }

  /**
   * Then use this loop to filter through all markers
   *
   * This loop and the loop within it are linear. So 500 users and 1000 markers results in 1500 total iterations
   */
  for (let i = 0; i < users.length; i++) {
    /**
     * If the user doesn't have any markers attached, break out of the loop
     * Should not happen as users have at least 1 marker attached
     */
    if (!users[i].markers || users[1].markers.length === 0) {
      if (trackedUser && users[i].id === trackedUser.id) {
        const message = `User ${trackedUser.id} outed in filter-radius because no markers found`;
        console.log('%c' + message, 'color:Orange');
      }

      continue;
    }

    /**
     * If the user already has a false show flag, break out of the loop
     */
    if (!users[i].show) {
      if (trackedUser && users[i].id === trackedUser.id) {
        const message = `User ${trackedUser.id} outed in filter-radius previous show flag was false`;
        console.log('%c' + message, 'color:Orange');
      }

      continue;
    }

    visibleCondition = false;
    isService = false;

    /**
     * This is the inner loop that loop through user's markers
     */
    for (let j = 0; j < users[i].markers.length; j++) {
      // If its a service marker, ignore that user because they don't have a marker to show
      if (users[i].markers[j].isService) {
        isService = true;

        break;
      }

      if (users[i].markers[j].distance <= circleRadius) {
        visibleCondition = true;
      } else {
        // if (users[i].id === 10977) {
        //   console.log(users[i]);
        // }
      }
    }

    /**
     * We're checking if one of user's marker is inside the circle, and if yes, then make all their other markers -
     * regardless of whether they are in the cicle or now - visible.
     *
     * Sounds odd eh - refer to business rules inside readme.md
     */
    if (visibleCondition) {
      // If they didn't make the cut, only update the inradius
      if (!users[i].show) {
        users[i].inRadius = true;
        // Otherwise make it work like it does usually
      } else {
        physicalResultsCount += 1;
        users[i].show = true;
        users[i].inRadius = true;
      }
    } else {
      if (trackedUser && users[i].id === trackedUser.id) {
        const message = `User ${trackedUser.id} outed in filter-radius`;
        console.log('%c' + message, 'color:Orange');
      }
      users[i].show = false;
    }
  }

  for (let i = 0; i < users.length; i++) {
    users[i].maxDistance = getMaxDistance(users[i].markers);
    users[i].minDistance = getMinDistance(users[i].markers);
  }

  return {
    users: users,
    physicalResultsCount: physicalResultsCount
  };
}

function getMaxDistance(markers) {
  let max = 0;

  for (let i = 0; i < markers.length; i++) {
    if (markers[i].distance && markers[i].distance > max) {
      max = markers[i].distance;
    }
  }

  return max;
}

function getMinDistance(markers) {
  let min = 999999;

  for (let i = 0; i < markers.length; i++) {
    if (markers[i].distance && markers[i].distance < min) {
      min = markers[i].distance;
    }
  }

  return min;
}
