<template>
  <div class="c-debug-bar">
    <div class="c-debug-bar__item">
      Users: <span class="positive">{{ usersSummary.positive }}</span
      ><span class="negative">{{ usersSummary.negative }}</span>
    </div>
    <div class="c-debug-bar__item">
      Telehealth users who are no show: <span class="negative">{{ usersSummary.telehealth }}</span>
    </div>
    <div class="c-debug-bar__item">
      Visible Map Markers: <span class="positive">{{ gMapMarkers.length }}</span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    users: function () {
      return this.$store.getters['users/filteredUsers'];
    },
    gMapMarkers: function () {
      return this.$store.getters['markers/gMapMarkers'];
    },
    usersSummary: function () {
      let users = this.users;
      let positive = 0;
      let negative = 0;
      let telehealth = 0;
      users.map((user) => {
        if (user.show) {
          positive += 1;
        } else {
          negative += 1;
        }

        if (!user.show && user.telehealth) {
          telehealth += 1;
        }
      });

      return {
        positive,
        negative,
        telehealth,
      };
    },
  },
};
</script>

<style lang="scss">
.c-debug-bar {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  background-color: var(--g1);
  border-top: var(--g5) solid 1px;
  font-size: var(--font-size-xs);
  font-family: 'JetBrains Mono', monospace;
  line-height: 1;

  &__item {
    padding: var(--margin-lg);
    border-right: var(--g5) solid 1px;
  }

  .positive,
  .negative {
    margin: 0 var(--margin);
    border-radius: 10px;
    font-weight: 700;
    padding: var(--margin-sm) var(--margin-lg);
  }

  .positive {
    padding: var(--margin-sm) var(--margin-lg);
    background-color: #7ce694;
  }

  .negative {
    padding: var(--margin-sm) var(--margin-lg);
    background-color: var(--warning-color);
  }
}
</style>
