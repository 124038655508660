<template>
  <div :class="['c-copy-email', { inline: forceInline === true }]">
    <strong>
      {{ title }}
    </strong>
    <p>
      <span>{{ value }}</span>
    </p>
  </div>
</template>

<script>
import { CopyIcon } from 'vue-feather-icons';

export default {
  components: {
    CopyIcon
  },

  props: {
    title: {
      type: String,
      default: '',
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    forceInline: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick: function() {}
  }
};
</script>

<style lang="scss">
.c-copy-email {
  padding: var(--margin-sm) 0;

  .svg {
    stroke-width: 3px;
  }

  p {
    margin: 0;
    display: inline-block;
  }

  a:last-child {
    display: inline-block;
    margin-left: var(--margin);
  }

  &.inline {
    strong {
      display: inline;
    }
    p {
      display: inline;
    }
  }
}
</style>
