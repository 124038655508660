<template>
  <div class="c-checkbox">
    <div class="c-checkbox__list" v-for="item in items" :key="item.value">
      <!-- List checkbox -->
      <div class="form-checkbox" v-if="!item.items">
        <input
          type="checkbox"
          name="checkbox"
          v-model="currentValues"
          :value="item.value"
          :id="slugify(item.value + '-checkbox' + id)"
          @change="test"
        />
        <label :for="slugify(item.value + '-checkbox' + id)">
          {{ item.key }} <span class="debug-label" v-if="debug">{{ item.value }}</span>
        </label>
      </div>

      <!-- toggle checkbox -->
      <!-- <div class="form-checkbox" v-if="item.items">
        <input
          type="checkbox"
          name="checkbox"
          :value="item.value"
          :id="slugify(item.value)"
          :ref="slugify(item.value)"
          @change="onIndeterminateCheckboxChange($event, item)"
        />
        <label :for="slugify(item.value)">
          {{ item.key }}
        </label>
      </div> -->

      <IndeterminateCheckbox
        v-if="item.items"
        :item="item"
        :values="currentValues"
        @onChange="onIndeterminateCheckboxChange"
      ></IndeterminateCheckbox>

      <Checkbox v-if="item.items" v-model="currentValues" :items="item.items"></Checkbox>
      <!-- <code v-if="item.items">
        {{ currentValues.length }}
      </code> -->
    </div>
  </div>
</template>

<script>
import slugify from '@/lib/slugify.js';

import Checkbox from './checkbox';
import IndeterminateCheckbox from './indeterminate-checkbox.vue';

export default {
  name: 'Checkbox',

  components: {
    Checkbox,
    IndeterminateCheckbox
  },

  data: function() {
    return {
      id: '',
      currentValues: []
    };
  },

  props: {
    value: {
      type: Array
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  watch: {
    value: {
      handler: function() {
        this.currentValues = this.value;
      },
      immediate: true
    },
    currentValues: {
      handler: function(val) {
        this.update();
      },
      deep: true
    }
  },

  computed: {
    debug: function() {
      return this.$store.getters['debug'];
    }
  },

  methods: {
    test: function(e) {},
    update: function(value) {
      this.$emit('input', this.currentValues);
    },
    findItem: function(value) {},
    onInput: function(e, item) {
      let ref = this.$refs[this.slugify(item.value)][0];

      let values = [];

      for (let i = 0; i < item.items.length; i++) {
        values.push(item.items[i].value);
      }

      if (e.length > 0 && !values.includes(e[0])) {
        return;
      }

      let actualLength = item.items.length;

      let currentLength = e.length;

      if (actualLength !== currentLength && currentLength !== 0) {
        ref.indeterminate = true;
      } else {
        ref.indeterminate = false;

        if (currentLength === 0) {
          ref.checked = false;
        } else {
          ref.checked = true;
        }
      }
    },
    onIndeterminateCheckboxChange: function(values, checked) {
      if (checked) {
        let temp = this.currentValues;

        temp = temp.concat(values);

        this.currentValues = temp.filter(onlyUnique);
      } else {
        let temp = this.currentValues;
        for (let i = 0; i < values.length; i++) {
          if (temp.includes(values[i])) {
            let j = temp.indexOf(values[i]);
            temp.splice(j, 1);
          }
        }
        this.currentValues = temp;
      }
    },
    slugify: function(str) {
      return slugify(str);
    }
  },

  mounted: function() {
    this.id = this._uid;
  }
};

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
</script>

<style lang="scss">
.c-checkbox {
  .debug-label {
    display: block;
    font-family: 'Jetbrains Mono', monospace;
    font-size: var(--font-size-xs);
    color: #000;
  }
}
</style>
