import objectId from './objectid';

import { destructurer } from './debug-utils.js';

function normalizeModesOfDelivery(modes) {
  for (let i = 0; i < modes.length; i++) {
    if (modes[i] === 'Face-to face (individual)') {
      modes[i] = 'Face-to-face (individual)';
    }
    if (modes[i] === 'Face-to face (group)') {
      modes[i] = 'Face-to-face (group)';
    }
    if (modes[i] === 'Telehealth (e.g. consulting via Skype)') {
      modes[i] = 'Telehealth';
    }
    if (modes[i] === 'Telehealth (e.g. consulting via Skype) (e.g. consulting via Skype)') {
      modes[i] = 'Telehealth (e.g. consulting via Skype)';
    }
  }
  return modes;
}

function normalizeCostStructure(costs) {
  for (let i = 0; i < costs.length; i++) {
    if (costs[i] === 'Bulk bill service') {
      costs[i] = 'Bulk-bill service';
    }
    if (costs[i] === 'Bulk-bill service available') {
      costs[i] = 'Bulk-bill service';
    }

    if (costs[i] === 'Bulk bill healthcare cardholder') {
      costs[i] = 'Bulk-bill healthcare cardholder';
    }
    if (costs[i] === 'Free Service') {
      costs[i] = 'Free service';
    }
    if (costs[i] === 'Interpreter service available') {
      costs[i] = 'Interpreter service';
    }
    if (costs[i] === 'Private Health fund rebate') {
      costs[i] = 'Private Health Insurance rebate';
    }
  }

  return costs;
}

function setFilterableCosts(cost_structure, debug = false) {
  let filterable = [
    'Free service',
    'Bulk-bill service',
    'Bulk-bill healthcare cardholder',
    'Private Health fund rebate available'
  ];
  let costs = [];

  for (let i = 0; i < cost_structure.length; i++) {
    if (!filterable.includes(cost_structure[i])) {
      if (debug) {
        console.log('BREAK', cost_structure[i]);
      }
      continue;
    }

    if (!costs.includes(cost_structure[i])) {
      costs.push(cost_structure[i]);
    }
  }

  return costs;
}

function setFilterableDeliveries(modes_of_delivery, debug = false) {
  let filterable = [
    'Face-to-face (individual)',
    'Face-to-face (group)',
    'Telehealth',
    'Telephone Counselling',
    'Online mental health support',
    'Online mental health treatment',
    'SMS',
    'Home visiting service',
    'Mother and baby (in-patient)',
    'Other'
  ];
  let deliveries = [];

  for (let i = 0; i < modes_of_delivery.length; i++) {
    if (!filterable.includes(modes_of_delivery[i])) {
      if (debug) {
        console.log('BREAK', modes_of_delivery[i]);
      }
      continue;
    }

    if (!deliveries.includes(modes_of_delivery[i])) {
      deliveries.push(modes_of_delivery[i]);
    }
  }
  return deliveries;
}

function hasTelehealth(deliveries) {
  if (deliveries.includes('Telehealth')) {
    return true;
  } else {
    return false;
  }
}

function normalizeLanguages(languages) {
  return languages;
}

export default function(markers) {
  for (let i = 0; i < markers.length; i++) {
    /**
     * Making sure the user key is always a int and setting a unique id on it
     */
    markers[i].user = parseInt(markers[i].id);
    markers[i].id = objectId();

    markers[i].interpreter_service = false;

    if (markers[i].cost_structure.includes('Interpreter service')) {
      markers[i].interpreter_service = true;
    }

    /**
     * Normalize cost_structure and set a costs key on the marker
     * This costs key is used for the actual filtering
     */
    if (markers[i].cost_structure && markers[i].cost_structure.length > 0) {
      markers[i].cost_structure = normalizeCostStructure(markers[i].cost_structure);
    }
    markers[i].costs = setFilterableCosts(markers[i].cost_structure);

    /**
     * Normalize cost_structure and set a costs key on the marker
     * This costs key is used for the actual filtering
     */
    if (markers[i].modes_of_delivery && markers[i].modes_of_delivery.length > 0) {
      markers[i].modes_of_delivery = normalizeModesOfDelivery(markers[i].modes_of_delivery);
    }
    markers[i].deliveries = setFilterableDeliveries(markers[i].modes_of_delivery);

    /**
     * Sets telehealth flag based on whether telehealh is part of deliveries or not
     */
    markers[i].telehealth = hasTelehealth(markers[i].deliveries);

    markers[i].languages = markers[i].other_languages;
  }

  //console.log(destructurer(markers, ['other_languages']));

  return markers;
}
