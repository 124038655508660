<template>
  <a href="#" class="c-link" @click="$emit('onClick')"
    >{{ computedText }}<component v-if="icon" :is="icon"></component
  ></a>
</template>

<script>
import { Edit2Icon } from 'vue-feather-icons';
export default {
  components: {
    Edit2Icon
  },

  props: {
    text: {
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },

  computed: {
    computedText: function() {
      return this.text;
    }
  }
};
</script>

<style lang="scss">
.c-link {
  margin: 0 var(--margin-sm);
  display: inline-block;
  justify-content: center;
  align-items: center;
  font-weight: 600;

  border-bottom: 0;
  font-weight: 600;
  max-width: 66.666%;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;

  svg {
    margin-left:4px;
    width: var(--font-size-xs);
    height: var(--font-size-xs);
  }
}
</style>
